import '../../settings.scss';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
//  <--HOOKS-->  //
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
//  <--COMPONENTS--> //
import SubNavBar from '../../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../../components/header/header';
import PropertyUnitTableComponent from './property-unit-table';
import ButtonBody from '../../../../components/button/button';
//  <--INTERFACE--> //
import { lang } from '../../../langs';
import {
    PropertyFormCreateInterface,
    PropertyFormValues
} from '../../../../interface';
//  <--SERVICES--> //
import { ApiPropertyId } from '../../../../config/service';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';

const PropertyUnit = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;

    const {
        [defaultLang as keyof typeof lang]: {
            pageProperty: { title },
            pagePropertyUnit: {
                propertyUnitTitle,
                adminTitle,
                newTitle,
                createTitle
            }
        }
    } = lang;
    const { id } = useParams();
    const { GetData } = useCallApi();
    const [entityId, setEntityId] = useState<string>('');
    const [entity, setEntity] = useState<PropertyFormCreateInterface>();

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        if (id) {
            setEntityId(id);
        } else {
            setEntity(PropertyFormValues);
        }
    }, [id]);

    /**
     * @description CallDataEdit function, call the api to get the data of the entity and set the data in state
     */
    const CallDataEdit = async () => {
        const response = await GetData(
            ApiPropertyId(entityId),
            HTTP_METHODS.GET
        );
        const { data } = response;
        setEntity(data);
    };

    /**
     * @description this effect check that entityId is not empty and if it changes, if not empty, call the function to CallDataEdit
     */
    useEffect(() => {
        if (entityId !== '') {
            CallDataEdit();
        }
    }, [entityId]);

    return (
        <>
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/propertyIconB.svg'
                kind='settings'
                option='Properties'
            ></SubNavBar>
            <div className='settings'>
                <Header
                    title={{ name: title, link: '/panel/properties' }}
                    icon='PropertyIconB.svg'
                    subtitle={{
                        name: id ? entity?.name ?? createTitle : '',
                        link: `/panel/properties/edit/${id}`
                    }}
                    subsubtitle={{ name: propertyUnitTitle }}
                />
                <div className='settings__grid-container'>
                    <div className='settings__grid-container__item'>
                        <div className='settings__grid-container__item__title title'>
                            {adminTitle}
                        </div>
                        <PropertyUnitTableComponent />
                        <div className='settings__grid-container__item__content'>
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={newTitle}
                                path={`/panel/properties/${id}/units/create`}
                            ></ButtonBody>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PropertyUnit;
