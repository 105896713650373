import { FormBuilderFields } from '../../../../../../components/formBuilder/form-builder.interface';

export const FormCreateRequirement: FormBuilderFields[] = [
    {
        label: 'Nombre',
        fieldNameId: 'name',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el nombre',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 200,
                state: true,
                message: 'Máximo 200 caracteres'
            }
        }
    },
    {
        label: 'Periodicidad (días)',
        fieldNameId: 'periodicityDays',
        fieldType: 'number',
        fieldPlaceholder: 'Ingrese el tipo',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Tipo de Alarma',
        fieldNameId: 'alarmType',
        fieldType: 'checkbox',
        elements: [
            { id: '3', name: 'WSP' },
            { id: '2', name: 'SMS' },
            { id: '1', name: 'APP' },
            { id: '0', name: 'Mail' }
        ],
        fieldPlaceholder: 'Seleccione el tipo de alarma',
        isFocusInClean: true,
        validations: {
            type: 'number',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Material',
        fieldNameId: 'material',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione el material',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Unidad de medida',
        fieldNameId: 'unitMeasurement',
        fieldType: 'select',
        elements: [
            { id: 'cont_20_l', name: 'Contendor 20l' },
            { id: 'cont_120_l', name: 'Contendor 120l' },
            { id: 'cont_240_l', name: 'Contendor 240l' },
            { id: 'cont_360_l', name: 'Contendor 360l' },
            { id: 'cont_770_l', name: 'Contendor 770l' },
            { id: 'cont_1100_l', name: 'Contendor 1100l' },
            { id: 'g', name: 'Gramos' },
            { id: 'kg', name: 'Kilogramos' }
        ],
        fieldPlaceholder: 'Seleccione la unidad de medida',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Densidad',
        fieldNameId: 'density',
        fieldType: 'number',
        fieldPlaceholder: 'Ingrese la densidad',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Observación',
        fieldNameId: 'obs',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese una observación',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: false,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 200,
                state: true,
                message: 'Máximo 200 caracteres'
            }
        }
    },
    {
        label: 'Inmueble',
        fieldNameId: 'property',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione un inmueble',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: false,
                message: 'Este campo es requerido'
            }
        },
        editable: false
    },
    {
        label: 'Unidad',
        fieldNameId: 'propertyUnit',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione una unidad',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: false,
                message: 'Este campo es requerido'
            }
        },
        editable: true
    },
    {
        label: 'Gestor',
        fieldNameId: 'gestor',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione un gestor',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: false,
                message: 'Este campo es requerido'
            }
        },
        editable: true
    }
];
