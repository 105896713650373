export interface AppSectionInterface {
    id: string;
    name: string;
    code: string;
    path: string;
    isAside: boolean;
    isHeader: boolean;
    isIcon: boolean;
    icon: string;
    order: number;
    state: number;
    createdAt: string;
    updatedAt: string;
}

export interface AppSectionLogInterface {
    appSection: AppSectionInterface;
    path: string;
}

export interface AppSectionsDifference {
    asocciated: AppSectionInterface[];
    unassociated: AppSectionInterface[];
}

export interface AppSectionFormCreateInterface {
    name: string;
    code: string;
    path: string;
    isAside: boolean;
    isHeader: boolean;
    isIcon: boolean;
    icon: string;
    order: number;
}

export const AppSectionFormValues: AppSectionFormCreateInterface = {
    name: '',
    code: '',
    path: '',
    isAside: false,
    isHeader: false,
    isIcon: false,
    icon: ' ',
    order: 0
};
