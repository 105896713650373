import { PropertyFormCreateInterface } from './property.interface';
import { RoleInterface } from './role.interface';

export interface UserDataResponseInterface {
    id: string;
    name: string;
    lastName: string;
    rut: string;
    email: string;
    phone: string;
    roles: RoleInterface[];
    properties?: PropertyFormCreateInterface[];
    clientData?: any[];
}

export interface UserAuthResponseInterface {
    token: string;
    userData: UserDataResponseInterface;
}

export interface UserFomLoginInterface {
    email: string;
    password: string;
    inviteId?: string;
}

export const LoginFormValues: UserFomLoginInterface = {
    email: '',
    password: ''
};
