import { useRef } from 'react';
import { Alert as AlertElement } from './alert';
import { lang } from './langs';

interface AlertInterface {
    current: any;
}

interface OkInterface {
    func: any;
    param?: string;
}

interface Props {
    title: string;
    message: string;
    okButtonText: string;
    okButtonController: OkInterface;
    cancelButtonText: string;
}

const ConfigAlert: Props = {
    title: '',
    message: '',
    okButtonText: '',
    okButtonController: { func: () => {} },
    cancelButtonText: ''
};

interface DefaultProps {
    defaultLang?: string;
}

export function useAlert(
    { defaultLang }: DefaultProps = { defaultLang: 'es' }
) {
    const {
        [defaultLang as keyof typeof lang]: {
            titles: { delette, update, create, closeSession },
            okButtons: {
                okButtonAccept,
                okButtonOk,
                okButtonYes,
                okButtonSave,
                okButtonModify,
                okButtonCreate
            },
            cancelButtons: {
                cancelButtonsCancel,
                cancelButtonsNo,
                cancelButtonsClose,
                cancelButtonsExit,
                cancelButtonsCloseSession
            }
        }
    } = lang;
    const Titles = {
        Delete: delette,
        Update: update,
        Create: create,
        CloseSession: closeSession
    } as const;

    const OkButtons = {
        Accept: okButtonAccept,
        Ok: okButtonOk,
        Yes: okButtonYes,
        Save: okButtonSave,
        Modify: okButtonModify,
        Create: okButtonCreate
    };

    const CancelButtons = {
        Cancel: cancelButtonsCancel,
        No: cancelButtonsNo,
        Close: cancelButtonsClose,
        Exit: cancelButtonsExit,
        CloseSession: cancelButtonsCloseSession
    };

    const Buttons = {
        Ok: OkButtons,
        Cancel: CancelButtons
    };

    const Defaults = {
        titles: Titles,
        buttons: Buttons
    };

    const alertManagerRef: AlertInterface = useRef(null);

    const setConfigAlert = config => {
        if (alertManagerRef) {
            alertManagerRef.current.changeAlert(config);
        }
    };

    return {
        AlertElement,
        alertManagerRef,
        ConfigAlert,
        setConfigAlert,
        Defaults
    };
}
