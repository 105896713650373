import './wastecard.scss';
import { lang } from '../../../langs';
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';

interface Props {
    logo: string;
    name: string;
    kg: string;
    percentage: string;
    color: string;
    period: number;
}

const WasteCard = ({ logo, name, kg, percentage, color, period }: Props) => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            dataCenterComponents: {
                recolectionCard: {
                    wasteCard: { pastMonth, totalTxt }
                }
            }
        }
    } = lang;
    const percentageFinal = (parseFloat(percentage) * 100).toFixed(5);
    const percentageFinalNumber = parseFloat(percentageFinal);

    return (
        <div className='wastecard'>
            <div className='wastecard__header'></div>
            <div className='wastecard__body'>
                <img
                    className='wastecard__body__logo'
                    src={logo}
                    style={{ fill: color }}
                    onError={e => {
                        const target = e.target as HTMLImageElement;
                        target.src = '/svg-icons/material-icons/general.svg';
                    }}
                />
                <div className='wastecard__body__past-month'>
                    {/* <div className='wastecard__body__past-month__text'>
                        +5% {pastMonth}
                    </div> */}
                </div>
            </div>
            <div className='wastecard__content'>
                <div className='wastecard__content__name subsubtitle'>
                    {name}
                </div>
                <div className='wastecard__content__kg bigtitle'>
                    {parseInt(kg).toLocaleString('es-CL', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    })}{' '}
                    kg
                </div>
                <a className='wastecard__content__ver subsubtitle'>
                    {period === 0 && percentageFinalNumber > 0 && '+'}
                    {percentageFinalNumber.toLocaleString('es-CL', {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 2
                    })}
                    % {period === 0 ? pastMonth : totalTxt}
                </a>
            </div>
        </div>
    );
};

export default WasteCard;
