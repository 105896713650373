import { useEffect, useState } from 'react';
//  <--HOOKS--> //
import { useToast } from '../../../../config/hooks/useToast';
import { useAlert } from '../../../../config/hooks/useAlert';
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
//  <--COMPONENTS--> //
import TableComponent from '../../../../components/table';
import { FontTable } from '../../../../components/table/data/columns_table';
//  <--SERVICES--> //
import { ApiFont } from '../../../../config/service';
//  <--INTERFACE--> //
import { FontInterface } from '../../../../interface';
import { constantsFontTable } from '../constants';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { PropertyStoreInterface } from '../../../../store/slices/property/property.interface';
import { PropertyUnitStoreInterface } from '../../../../store/slices/property-unit/property-unit.interface';
import { useAppSelector } from '../../../../store/hooks';

interface Props {
    refresh?: boolean;
}

const FontTableComponent = ({ refresh }: Props) => {
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const { AlertElement, alertManagerRef } = useAlert({ defaultLang });
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const { keysFilter } = constantsFontTable;
    const [dataList, setDataList] = useState<FontInterface[]>([]);
    const { selected }: PropertyStoreInterface = useAppSelector(
        state => state.property
    );
    const { selectedUnits }: PropertyUnitStoreInterface = useAppSelector(
        state => state.propertyUnit
    );

    /**
     * @description
     * @function CallData is a function that returns a promise with the COMPANY list
     */
    const CallData = async () => {
        const ids = selected.map(item => {
            return item.id;
        });
        const idsUnits = selectedUnits.map(item => {
            return item.id;
        });
        const response = await GetData(ApiFont, HTTP_METHODS.GET);
        const { data, status, message } = response;
        if (ids.length + idsUnits.length > 0) {
            // Filtrar los elementos de data que tengan property.id igual a algún elemento de ids
            const filteredDataProp = data.filter(item =>
                ids.includes(item.property?.id)
            );
            const filteredDataUnits = data.filter(item =>
                idsUnits.includes(item.propertyUnit?.id)
            );
            const filteredData = filteredDataProp.concat(filteredDataUnits);
            setDataList(
                [...filteredData].sort((a, b) => a.name.localeCompare(b.name))
            );
        } else {
            // Si ids.length es 0, simplemente ordenar data por nombre y asignarlo a setDataList
            setDataList([...data].sort((a, b) => a.name.localeCompare(b.name)));
        }
        if (!status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * Function that is executed when the component is mounted, calls the function that returns the COMPANY list
     */
    useEffect(() => {
        CallData();
    }, [refresh, selected]);

    return (
        <>
            <ToastElement ref={toastManagerRef} />
            <AlertElement ref={alertManagerRef} />
            <TableComponent
                data={dataList}
                headers={FontTable}
                showFilters={true}
                keysFilter={keysFilter}
                showPagination={true}
            />
            {LoadingData && <LoaderElement />}
        </>
    );
};

export default FontTableComponent;
