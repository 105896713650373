import { ClientBusinessInterface } from './client-business.interface';

export interface ClientInterface {
    id: string;
    name: string;
    address: string;
    rut: string;
    businessName: string;
    businessLine: string;
    image: string;
    phone: string;
    properties: ClientPropertyInterface[];
    units: ClientBusinessInterface[];
    state: number;
    createdAt: string;
    updatedAt: string;
}

interface ClientPropertyInterface {
    active: string;
    address: string;
    contact_email: string;
    contact_name: string;
    contact_phone: string;
    created_at: string;
    cs_code: string;
    id: string;
    image: string;
    name: string;
    pa_code: string;
    procedures: string;
    rut: string;
    state: string;
    units: [];
    updated_at: string;
}

interface CategoryInterface {
    id: string;
    name: string;
    description: string;
    state: number;
    createdAt: string;
    updatedAt: string;
}

export interface ClientFormCreateInterface {
    name: string;
    address: string;
    rut: string;
    phone: string;
    businessName: string;
    image: string;
    clientCategory: CategoryInterface | null;
}

export const ClientFormValues: ClientFormCreateInterface = {
    name: '',
    address: '',
    rut: '',
    phone: '',
    businessName: '',
    image: '',
    clientCategory: null
};
