import { useRef, useState } from 'react';
//  import CheckIcon from '@material-ui/icons/Check';
//  <--COMPONENTS--> //
import FormBuilder from '../../../components/formBuilder/formBuilder';
import ProfileSummary from '../my-profile';
//  <--REDUX--> //
import { useAppSelector } from '../../../store/hooks';

import { FormChangePass } from '../form';
import { lang } from '../../langs';
import { UserChangePasswordFormValues } from '../../../interface';
import { useCallApi } from '../../../config/hooks/useCallApi';
import { useToast } from '../../../config/hooks/useToast';
import { HTTP_METHODS } from '../../../config/hooks/useCallApi/constants';
//  <--SERVICES--> //
import { ApiUsersResetPass } from '../../../config/service';
import { useToastHook } from '../../../store/slices/toast/toast.hook';

const ChangePass = () => {
    const { ToastConfig } = useToastHook();
    const { GetData } = useCallApi();
    const { Colors, goPreviousPage } = useToast();
    const { userData, preferences } = useAppSelector(state => state.users);
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            pageMyProfile: {
                changePass: {
                    title,
                    errorNewPasswords,
                    errorMessage,
                    successMessage
                }
            }
        }
    } = lang;
    const fieldRef = useRef(null);
    const [successCleanForm, setSuccessCleanForm] = useState<boolean>(true);

    /**
     * @description
     * @function OnSubmit is a function that is called when the form is submitted
     * @param values is a object that contains the data of the form
     */
    const OnSubmit = async values => {
        try {
            setSuccessCleanForm(false);
            if (values.newPassword !== values.newPasswordConfirm) {
                ToastConfig({
                    message: errorNewPasswords,
                    color: Colors.Warning
                });
                return;
            }
            values.user = userData.id;
            const response = await GetData(
                ApiUsersResetPass,
                HTTP_METHODS.POST,
                values
            );
            const { data, status } = response;
            if (status) {
                setSuccessCleanForm(true);
                ToastConfig({
                    message: successMessage,
                    color: Colors.Success
                });
                goPreviousPage();
            } else {
                ToastConfig({ message: data.message, color: Colors.Error });
            }
        } catch (err) {
            ToastConfig({ message: errorMessage, color: Colors.Success });
        }
    };

    /**
     * @description
     * @function FocusOnInput is a function that is called when the form's cancel button is clicked
     * @param ref is a reference to the form
     */
    const FocusOnInput = (ref: any) => {
        ref.current.focus();
    };

    /**
     * @description
     * @constant FormButtons is a object that contains the buttons of the form
     */
    const FormButtons = {
        ok: {
            controller: OnSubmit
        },
        cancel: {
            controller: FocusOnInput
        }
    };

    return (
        <>
            <div className='profile-body'>
                <ProfileSummary />
                <div
                    id='edit-grid-container'
                    className='profile-body__edit-grid-container'
                >
                    <div className='profile-body__edit-grid-container__item'>
                        <img
                            className='profile-body__edit-grid-container__item__img'
                            src='/svg-icons/SettingsIconG.svg'
                        />
                        <div className='profile-body__edit-grid-container__item__title subtitle'>
                            {title}
                        </div>
                        <div className='profile-body__edit-grid-container__item__edit-info-container'>
                            <FormBuilder
                                initialValues={UserChangePasswordFormValues}
                                buttons={FormButtons}
                                lang={defaultLang}
                                formStructure={FormChangePass}
                                focusOn={fieldRef}
                                onSuccessCleanForm={successCleanForm}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChangePass;
