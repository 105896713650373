import { useEffect, useState } from 'react';
//  <--HOOKS--> //
import { useToast } from '../../../../config/hooks/useToast';
import { useAlert } from '../../../../config/hooks/useAlert';
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
//  <--COMPONENTS--> //
import TableComponent from '../../../../components/table';
import { MaterialKindTable } from '../../../../components/table/data/columns_table';
import MKD from '../detail-material-kind';
import DetailsIcon from '@material-ui/icons/Notes';
//  <--SERVICES--> //
import {
    ApiMaterialKind,
    ApiMaterialKindIdMaterials
} from '../../../../config/service';
//  <--INTERFACE--> //
import { MaterialKindInterface } from '../../../../interface';
import { constantsTable } from '../constants';
import { lang } from '../../../langs';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';

interface Props {
    refresh?: boolean;
}

const MaterialKindTableComponent = ({ refresh }: Props) => {
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const { AlertElement, alertManagerRef } = useAlert({ defaultLang });
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        [defaultLang as keyof typeof lang]: {
            pageMaterialKind: { detailsTitle }
        }
    } = lang;

    const { keysFilter } = constantsTable;
    const [dataList, setDataList] = useState<MaterialKindInterface[]>([]);
    const [selectedMaterialKind, setSelectedMaterialKind] =
        useState<MaterialKindInterface>();
    const [showDetails, setShowDetails] = useState<boolean>(false);

    /**
     * @description
     * @function CallData is a function that returns a promise with the MaterialKind list
     */
    const CallData = async () => {
        const response = await GetData(ApiMaterialKind, HTTP_METHODS.GET);
        const { data, status, message } = response;
        setDataList([...data].sort((a, b) => a.name.localeCompare(b.name)));
        if (!status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * Function that is executed when the component is mounted, calls the function that returns the MaterialKind list
     */
    useEffect(() => {
        CallData();
    }, [refresh]);

    /**
     * @description function that calls the material data selected
     * @function CallMaterialsData is a function that calls the material data selected
     * @param id is a string that contains the id of the material to be consulted
     */
    const CallMaterialsData = async (id: string) => {
        const response = await GetData(
            ApiMaterialKindIdMaterials(id),
            HTTP_METHODS.GET
        );
        const { data, status } = response;
        setSelectedMaterialKind(data);
        if (!status) {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description function that is executed when the detail button is pressed
     * @function Detail is a function that opens the detail modal and calls the function that returns the MATKI data
     * @param id is a string that contains the id of the MATKI to be consulted
     */
    const Detail = async (id: string) => {
        setShowDetails(true);
        CallMaterialsData(id);
    };

    /**
     * @description function that is executed when saves the material data related to the MATKI
     * @function SuccessSaveMaterial is a function that saves the material data related to the MATKI
     * @param status is a boolean that indicates if the operation was successful
     */
    const SuccessSaveMaterial = (status: boolean) => {
        if (status && selectedMaterialKind) {
            CallMaterialsData(selectedMaterialKind[0].id);
        }
    };

    return (
        <>
            <ToastElement ref={toastManagerRef} />
            <AlertElement ref={alertManagerRef} />
            <TableComponent
                data={dataList}
                headers={MaterialKindTable}
                showFilters={true}
                keysFilter={keysFilter}
                showPagination={true}
                addsActions={[
                    {
                        action: Detail,
                        title: 'Ver materiales',
                        icon: <DetailsIcon />
                    }
                ]}
            />
            {LoadingData && <LoaderElement />}
            {showDetails && (
                <MKD
                    showDetails={showDetails}
                    setShowDetails={setShowDetails}
                    detailsTitle={detailsTitle}
                    selectedMaterialKind={selectedMaterialKind}
                    SuccessSaveMaterial={SuccessSaveMaterial}
                />
            )}
        </>
    );
};

export default MaterialKindTableComponent;
