import { FormBuilderFields } from '../../../../../components/formBuilder/form-builder.interface';

export const FormCreateTruck: FormBuilderFields[] = [
    {
        label: 'Gestor',
        fieldNameId: 'gestor',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Ingrese la clase de material',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Marca',
        fieldNameId: 'brand',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese la marca',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: false,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Patente',
        fieldNameId: 'patent',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese la patente',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Rut',
        fieldNameId: 'rut',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el rut',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Capacidad',
        fieldNameId: 'capacity',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese la Capacidad',
        validations: {
            type: 'string',
            required: {
                state: false,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Descripción',
        fieldNameId: 'description',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese la descripción',
        validations: {
            type: 'string',
            required: {
                state: false,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    }
];
