import './bzeroindexcard.scss';
import { useEffect, useState } from 'react';
import { lang } from '../../langs';
import { formatBZeroIndexData } from '../../../config/utils/IdbFormatData';
import { useCallApi } from '../../../config/hooks/useCallApi';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../store/hooks';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface Props {
    data: any;
}

const BZeroIndexCard = ({ data }: Props) => {
    const { LoadingData, LoaderElement } = useCallApi();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            dataCenterComponents: {
                bzeroIndexCard: {
                    title,
                    subtitle,
                    noContentMessage,
                    detailMessage
                }
            }
        }
    } = lang;

    Chart.register(ArcElement);

    const [index, setIndex] = useState(0);
    const [dataChart, setDataChart] = useState({
        datasets: [
            {
                data: [index, 100 - index],
                backgroundColor: ['red', 'blue'],
                display: true,
                borderColor: '#fff',
                borderWidth: 8
            }
        ]
    });

    // Primer useEffect para formatear los datos y actualizar dataFormated
    useEffect(() => {
        const dataFormated = formatBZeroIndexData(data);
        let finalTotal = 0;
        let finalTotalTrash = 0;

        // Recorremos el arreglo y sumamos los valores
        dataFormated.forEach(item => {
            finalTotal += parseInt(item.total);
            finalTotalTrash += parseInt(item.totalTrash);
        });

        // Calculamos finalBzeroindex dividiendo finalTotal entre finalTotalTrash
        const finalBzeroindex = (finalTotalTrash / finalTotal) * 100;
        const finalBzeroindexRounded = parseFloat(finalBzeroindex.toFixed(2));

        // Creamos el objeto con los atributos finales
        const finalObj = {
            finalTotal,
            finalTotalTrash,
            finalBzeroindexRounded
        };
        setIndex(finalObj.finalBzeroindexRounded);
    }, [data]);

    useEffect(() => {
        let color;
        if (index >= 0 && index <= 50) {
            color = ['#03dac5', '#fff'];
        } else if (index > 50 && index <= 75) {
            color = ['#FDDF4A', '#fff'];
        } else if (index > 75) {
            color = ['#CE3E50', '#fff'];
        }
        const chartObj = {
            datasets: [
                {
                    data: [index, 100 - index],
                    backgroundColor: color,
                    display: true,
                    borderColor: '#fff',
                    borderWidth: 8
                }
            ]
        };
        setDataChart(chartObj);
    }, [index]);

    return (
        <div className='bzeroindex-card'>
            <div className='bzeroindex-card__titulo-card title'>{title}</div>
            <div className='bzeroindex-card__subtitulo-card subtitle'>
                {subtitle}
                <Tooltip
                    title={
                        <span className='main_menu__grid-container__tooltip'>
                            {detailMessage}
                        </span>
                    }
                    color='#fff'
                >
                    <InfoCircleOutlined
                        className='bzeroindex-card__subtitulo-card__icon'
                        rev={''}
                    />
                </Tooltip>
            </div>
            <div className='bzeroindex-card__content'>
                {/* {dataChart ? (
                 */}
                {index ? (
                    <div className='bzeroindex-card__content__chart'>
                        <Doughnut
                            data={dataChart}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false
                                    },
                                    tooltip: {
                                        enabled: true,
                                        backgroundColor: 'rgb(255, 255, 255)',
                                        titleColor: 'rgb(162 162 163)', // Color del texto del título del tooltip
                                        bodyColor: 'rgb(162 162 163)', // Color del texto del cuerpo del tooltip
                                        footerColor: 'rgb(162 162 163)',
                                        padding: 6,
                                        boxPadding: 3,
                                        usePointStyle: true
                                    }
                                },
                                rotation: -90,
                                circumference: 180,
                                cutout: '75%',
                                maintainAspectRatio: true,
                                responsive: true
                            }}
                        />
                        <div className='bzeroindex-card__content__chart__number bigtitle'>
                            {index.toLocaleString('es-CL', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 1
                            })}
                            %
                        </div>
                        <div className='bzeroindex-card__content__chart__inf subsubtitle'>
                            0%
                        </div>
                        <div className='bzeroindex-card__content__chart__sup subsubtitle'>
                            100%
                        </div>
                    </div>
                ) : (
                    <div className='bzeroindex-card__content__message subtitle'>
                        {noContentMessage}
                    </div>
                )}
            </div>
            {LoadingData && <LoaderElement />}
        </div>
    );
};

export default BZeroIndexCard;
