import DetailsComponent from '../../../components/details/details';
import { RoleInterface } from '../../../interface';
import { useEffect, useState } from 'react';
import { Container } from '../../../components/styles';
/* import './roleAccess.scss'; */

interface Props {
    showDetails: boolean;
    setShowDetails: any;
    rolesTitle: string;
    roles: RoleInterface[];
    SuccessSave?: any;
}

const UserRoles = ({
    showDetails,
    setShowDetails,
    rolesTitle,
    roles
}: Props) => {
    const [rolesList, setRolesList] = useState<RoleInterface[] | undefined>([]);

    useEffect(() => {
        if (roles) {
            setRolesList(roles);
        }
    }, [roles]);

    /* const AddOrRemove = (id: string, action: string) => {
        SuccessSave(id, action);
    }; */

    return (
        <DetailsComponent
            showSection={showDetails}
            closeSection={setShowDetails}
            title={rolesTitle}
        >
            <Container>
                <h6 className='role_access_title'>{rolesTitle}</h6>
                {rolesList?.map((role: RoleInterface) => {
                    return (
                        <ol key={role.id}>
                            <li>
                                <div className='ms-2 me-auto'>
                                    <div className='ctItems'>
                                        <span className='lg-name'>
                                            {role.id}
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ol>
                    );
                })}
            </Container>
        </DetailsComponent>
    );
};

export default UserRoles;
