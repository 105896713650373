import './index.scss';

interface Props{
    onInstall: ()=> void,
    promptToInstall: ()=> void,
}

const InstalationComponent = ({onInstall,promptToInstall}:Props) => {
    return (
        <div className='instalation'>
            <div onClick={onInstall} className="instalation__wrapper">
                <button onClick={onInstall} className="instalation__wrapper__close">Cerrar</button>
                <span className="instalation__wrapper__text">Deseas instalar pryma?</span>
                <button className="instalation__wrapper__install" onClick={promptToInstall}>Instalar</button>
            </div>
        </div>
    )
}

export default InstalationComponent;