import '../components/components.scss';
import { lang } from '../../../../langs';
import { UserStoreInterface } from '../../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../../store/hooks';

interface Props {
    data: any;
    formattedLabels: any;
    name: string[];
    month: string;
}

const FinalDisposal = ({ name, month }: Props) => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            dataCenterComponents: {
                monthlyClosureCard: {
                    certificatesTxts: {
                        rut,
                        address,
                        web,
                        buildingTxt,
                        footerTxt,
                        finalDisposal: { title, text1a, text1b, tableTitle }
                    }
                }
            }
        }
    } = lang;

    return (
        <div className='certificate'>
            <div className='certificate__header'>
                <div className='certificate__header__title'>{title}</div>
                <div className='certificate__header__name'>
                    {buildingTxt} {name} / {month}{' '}
                </div>
            </div>
            <div className='certificate__section'>
                <h2>{rut}</h2>
                <h2>{address}</h2>
                <h2>{web}</h2>
            </div>
            <div className='certificate__section'>
                <p>
                    {' '}
                    {text1a} {month} {text1b}{' '}
                </p>
            </div>
            <div className='certificate__section'>
                <h1> {tableTitle} </h1>
                {/* <MaterialsSummary /> */}
            </div>
            <div className='certificate__signatures'>
                <p className='certificate__signatures__text'>BZero Admin</p>
                <p className='certificate__signatures__text'>
                    {buildingTxt} {name}
                </p>
            </div>
            <div className='certificate__footer'>
                <p className='certificate__footer__text'>{footerTxt}</p>
            </div>
            <div className='certificate__section'>
                <img
                    className='certificate__footer-img'
                    src='/certificates/footer.jpg'
                />
            </div>
        </div>
    );
};

export default FinalDisposal;
