import { Spin } from 'antd';
import './loading.scss';

const Loading = () => {
    return (
        <div className='loading-wrapper'>
            <Spin tip='Loading' size='large'>
                <div className='content' />
            </Spin>
        </div>
    );
};

export default Loading;
