import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import FormBuilder from '../../../../components/formBuilder/formBuilder';
//  <--HOOKS--> //
import { useToast } from '../../../../config/hooks/useToast';
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
//  <--INTERFACE--> //
import {
    MaterialFormCreateInterface,
    MaterialFormValues,
    MaterialKindInterface
} from '../../../../interface';
//  <--SERVICES--> //
import { ApiMaterial, ApiMaterialId } from '../../../../config/service';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';
//  <--OTHERS--> //
import { FormCreateMaterial, FormCreateMaterialFromKind } from './form';

interface Props {
    materialKind?: MaterialKindInterface;
    onSuccessSave?: (status: boolean) => void;
    materialKindList?: MaterialKindInterface[];
    detailPage?: boolean;
}

export const MaterialCreateComponent = ({
    materialKind,
    onSuccessSave,
    materialKindList,
    detailPage
}: Props) => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const navigate = useNavigate();
    const defaultLang: string = preferences.lang;
    const fieldRef = useRef(null);
    const { id } = useParams();
    const [entityId, setEntityId] = useState<string>('');
    const [entity, setEntity] = useState<MaterialFormCreateInterface>();
    const [readyForm, setReadyForm] = useState<boolean>(false);

    useEffect(() => {
        if (materialKindList && materialKindList?.length > 0) {
            FormCreateMaterial[0].elements = materialKindList;
        }
    }, [materialKindList]);

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        setReadyForm(false);
        if (id) {
            setEntityId(id);
        } else {
            setEntity(MaterialFormValues);
            setReadyForm(false);
            setTimeout(() => {
                setReadyForm(true);
            }, 50);
        }
    }, [id]);

    const rgbToHex = rgb => {
        const regex = /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/;
        const matches = rgb.match(regex);
        if (matches) {
            const r = parseInt(matches[1]);
            const g = parseInt(matches[2]);
            const b = parseInt(matches[3]);
            return `#${((r << 16) | (g << 8) | b)
                .toString(16)
                .padStart(6, '0')}`;
        }
        return rgb;
    };

    const hexToRgb = (hex: string): string => {
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, (_m, r, g, b) => {
            return r.concat(r, g, g, b, b);
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if (result) {
            const r: number = parseInt(result[1], 16);
            const g: number = parseInt(result[2], 16);
            const b: number = parseInt(result[3], 16);
            return `rgb(${r}, ${g}, ${b})`;
        }
        return hex;
    };

    /**
     * @description CallDataEdit function, call the api to get the data of the entity and set the data in state
     */
    const CallDataEdit = async () => {
        const response = await GetData(
            ApiMaterialId(entityId),
            HTTP_METHODS.GET
        );
        const { data, status } = response;
        const newData = {
            ...data,
            kind: data.kind.id,
            color: rgbToHex(data.color)
        };
        setEntity(newData);
        setReadyForm(true);
        if (!status) {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description this effect check that entityId is not empty and if it changes, if not empty, call the function to CallDataEdit
     */
    useEffect(() => {
        if (entityId !== '') {
            CallDataEdit();
        }
    }, [entityId]);

    /**
     * @description
     * @function OnSubmit is a function that is called when the form is submitted
     * @param values is a object that contains the data of the form
     */
    const OnSubmit = async (values: MaterialFormCreateInterface) => {
        if (materialKind) {
            values.kind = materialKind[0].id;
        }
        values.color = hexToRgb(values.color);
        const response = await GetData(ApiMaterial, HTTP_METHODS.POST, values);
        const { message, status } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
            setReadyForm(false);
            CallDataEdit();
            onSuccessSave?.(true);
            navigate('/panel/materials');
        } else {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * @function FocusOnInput is a function that is called when the form's cancel button is clicked
     * @param ref is a reference to the form
     */
    const FocusOnInput = (ref: any) => {
        ref.current.focus();
    };

    /**
     * @description
     * @constant FormButtons is a object that contains the buttons of the form
     */
    const FormButtons = {
        ok: {
            controller: OnSubmit
        },
        cancel: {
            controller: FocusOnInput
        }
    };

    return (
        <>
            <ToastElement ref={toastManagerRef} />
            {readyForm && (
                <FormBuilder
                    initialValues={entity?.name ? entity : MaterialFormValues}
                    buttons={FormButtons}
                    lang={defaultLang}
                    formStructure={
                        materialKind
                            ? FormCreateMaterialFromKind
                            : FormCreateMaterial
                    }
                    focusOn={fieldRef}
                    config={{
                        noClearButton: !!entity?.name,
                        editButton: !!entity?.name && detailPage
                    }}
                />
            )}
            {LoadingData && <LoaderElement />}
        </>
    );
};

export default MaterialCreateComponent;
