/* eslint-disable @typescript-eslint/no-explicit-any */
interface DetailValidations {
    state: boolean;
    message: string;
}

interface DetailValidationsNumber {
    length: number;
    state: boolean;
    message: string;
}

export interface Validations {
    type: 'string' | 'number' | 'boolean';
    required: DetailValidations;
    min?: DetailValidationsNumber;
    max?: DetailValidationsNumber;
    email?: DetailValidations;
}

export interface FormBuilderFields {
    label: string;
    fieldNameId: string;
    fieldType: string;
    show?: boolean;
    deffaultValues?: boolean;
    elements?: any[];
    fieldPlaceholder?: string;
    disabled?: boolean;
    isFocusInClean?: boolean;
    required?: boolean;
    autoComplete?: string;
    depends?: string;
    dependsValues?: string[];
    dependsShow?: boolean;
    validations: Validations;
    name?: string;
    editable?: boolean;
}

interface ButtonDetail {
    class?: string;
    text?: string;
    controller: any;
}

interface ButtonInterface {
    buttonsWrapperClass?: string;
    cancel?: ButtonDetail;
    edit?: ButtonDetail;
    ok: ButtonDetail;
}

interface Config {
    noLabels?: boolean;
    noClearButton?: boolean;
    editButton?: boolean;
}

export interface FormPropsInterface {
    initialValues: Object;
    lang?: string;
    formStructure: FormBuilderFields[];
    focusOn?: any;
    styles?: Object;
    config?: Config;
    buttons: ButtonInterface;
    onSuccessCleanForm?: boolean;
    onSelect?: any;
}

export const deffaulltButtons: ButtonInterface = {
    buttonsWrapperClass: 'app-d-flex__center childs_uniform',
    ok: {
        class: 'bzero_btn_primary',
        text: 'Guardar',
        controller: () => {}
    },
    cancel: {
        class: 'bzero_btn_danger',
        text: 'Cancelar',
        controller: () => {}
    },
    edit: {
        class: 'bzero_btn_danger',
        text: 'Editar',
        controller: () => {}
    }
};
