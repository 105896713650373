import '../ranking.scss';
import { Doughnut } from 'react-chartjs-2';

interface Props {
    podio: any;
    data: any;
    pdf?: boolean;
}

const RankingList = ({ podio, data, pdf }: Props) => {
    return (
        <div className='ranking__list' style={{ height: pdf ? 'auto' : '25%' }}>
            <div className='ranking__list__title title'>
                Ranking inmuebles/unidades seleccionadas
            </div>
            <div
                className='ranking__list__content'
                style={{ padding: pdf ? '0' : '3%' }}
            >
                {!pdf &&
                    (podio.length > 0 ? (
                        <div className='ranking__list__content__podium'>
                            {podio?.map((item, index) => (
                                <div
                                    className='ranking__list__content__podium__item'
                                    key={index}
                                >
                                    <div className='ranking__list__content__podium__item__header'>
                                        <img
                                            className='ranking__list__content__podium__item__header__logo'
                                            src={`/svg-icons/${
                                                index === 0
                                                    ? 'SecondPlaceIcon.svg'
                                                    : index === 1
                                                    ? 'FirstPlaceIcon.svg'
                                                    : 'ThirdPlaceIcon.svg'
                                            }`}
                                        />
                                        <div className='ranking__list__content__podium__item__header__content'>
                                            <div className='ranking__list__content__podium__item__header__content__name subtitle'>
                                                {item.propertyName ??
                                                    item.propertyUnitName}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ranking__list__content__podium__item__chart'>
                                        <Doughnut
                                            data={{
                                                datasets: [
                                                    {
                                                        data: [
                                                            item?.BZeroIndexTrim,
                                                            100 -
                                                                item?.BZeroIndexTrim
                                                        ],
                                                        backgroundColor:
                                                            item.BZeroIndexTrim <=
                                                            50
                                                                ? [
                                                                      '#03dac5',
                                                                      '#fff'
                                                                  ]
                                                                : item.BZeroIndexTrim >
                                                                  75
                                                                ? [
                                                                      '#CE3E50',
                                                                      '#fff'
                                                                  ]
                                                                : [
                                                                      '#FDDF4A',
                                                                      '#fff'
                                                                  ],
                                                        borderColor: '#fff',
                                                        borderWidth: 8
                                                    }
                                                ]
                                            }}
                                            options={{
                                                plugins: {
                                                    legend: {
                                                        display: false
                                                    },
                                                    tooltip: {
                                                        enabled: true,
                                                        backgroundColor:
                                                            'rgb(255, 255, 255)',
                                                        titleColor:
                                                            'rgb(162 162 163)', // Color del texto del título del tooltip
                                                        bodyColor:
                                                            'rgb(162 162 163)', // Color del texto del cuerpo del tooltip
                                                        footerColor:
                                                            'rgb(162 162 163)',
                                                        padding: 6,
                                                        boxPadding: 3,
                                                        usePointStyle: true
                                                    }
                                                },
                                                rotation: -90,
                                                circumference: 180,
                                                cutout: '75%',
                                                maintainAspectRatio: true,
                                                responsive: true
                                            }}
                                        />
                                        <div className='bzeroindex-card__content__chart__number bigtitle'>
                                            {item?.BZeroIndexTrim.toLocaleString(
                                                'es-CL',
                                                {
                                                    minimumFractionDigits: 1,
                                                    maximumFractionDigits: 1
                                                }
                                            )}
                                            %
                                        </div>
                                        <div className='bzeroindex-card__content__chart__inf subsubtitle'>
                                            0%
                                        </div>
                                        <div className='bzeroindex-card__content__chart__sup subsubtitle'>
                                            100%
                                        </div>
                                    </div>
                                    <div className='ranking__list__content__podium__item__detail'>
                                        <div
                                            className='ranking__myproperty__content__data__item subtitle'
                                            style={{
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <img
                                                className='ranking__list__content__podium__item__header__logo'
                                                src='/svg-icons/Recyclable.svg'
                                            />
                                            <div className='ranking__myproperty__content__data__item__q'>
                                                {item?.RecyclableTrim.toLocaleString(
                                                    'es-CL',
                                                    {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0
                                                    }
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className='ranking__myproperty__content__data__item subtitle'
                                            style={{
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <img
                                                className='ranking__list__content__podium__item__header__logo'
                                                src='/svg-icons/material-icons/orgánicos.svg'
                                            />
                                            <div className='ranking__myproperty__content__data__item__q'>
                                                {item?.OrganicTrim.toLocaleString(
                                                    'es-CL',
                                                    {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0
                                                    }
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className='ranking__myproperty__content__data__item subtitle'
                                            style={{
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <img
                                                className='ranking__list__content__podium__item__header__logo'
                                                src='/svg-icons/material-icons/Basura.svg'
                                            />
                                            <div className='ranking__myproperty__content__data__item__q'>
                                                {item.TrashTrim.toLocaleString(
                                                    'es-CL',
                                                    {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className='ranking__list__content__message subtitle'>
                            No hay suficientes inmuebles/unidades que participen
                            del ranking.
                        </div>
                    ))}
                {!pdf ? (
                    <div>
                        {data.length > 3 && (
                            <div className='ranking__list__content__table subtitle'>
                                {data
                                    .slice(3, Math.min(10, data.length))
                                    .map((data, index) => (
                                        <div
                                            key={
                                                data.property ??
                                                data.propertyUnit
                                            }
                                            className='ranking__list__content__table__element'
                                        >
                                            <div className='ranking__list__content__table__element__number subtitle'>
                                                {`#${
                                                    !pdf
                                                        ? parseInt(index) + 4
                                                        : index
                                                }`}
                                            </div>
                                            <div className='ranking__myproperty__content__data subtitle'>
                                                <div className='ranking__myproperty__content__data__item'>
                                                    {data.propertyName ??
                                                        data.propertyUnitName}
                                                </div>
                                                <div className='ranking__myproperty__content__data__item'>
                                                    <img
                                                        className='ranking-card__content-container__content__list-container__element__info__img'
                                                        src={`/svg-icons/${
                                                            data.BZeroIndexTrim <
                                                            50
                                                                ? 'DotIcon.svg'
                                                                : data.BZeroIndexTrim <
                                                                  75
                                                                ? 'DotIconYellow.svg'
                                                                : 'DotIconRed.svg'
                                                        }`}
                                                    />
                                                    Índice Bzero:
                                                    <div className='ranking__myproperty__content__data__item__q'>
                                                        {data.BZeroIndexTrim.toLocaleString(
                                                            'es-CL',
                                                            {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 1
                                                            }
                                                        )}
                                                        %
                                                    </div>
                                                </div>
                                                <div className='ranking__myproperty__content__data__item'>
                                                    <img
                                                        className='ranking__list__content__podium__item__header__logo'
                                                        src='/svg-icons/Recyclable.svg'
                                                    />
                                                    [kg] Reciclables:
                                                    <div className='ranking__myproperty__content__data__item__q'>
                                                        {data.RecyclableTrim.toLocaleString(
                                                            'es-CL',
                                                            {
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 0
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='ranking__myproperty__content__data__item'>
                                                    <img
                                                        className='ranking__list__content__podium__item__header__logo'
                                                        src='/svg-icons/material-icons/orgánicos.svg'
                                                    />
                                                    [kg] Orgánicos:
                                                    <div className='ranking__myproperty__content__data__item__q'>
                                                        {data.OrganicTrim.toLocaleString(
                                                            'es-CL',
                                                            {
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 0
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='ranking__myproperty__content__data__item'>
                                                    <img
                                                        className='ranking__list__content__podium__item__header__logo'
                                                        src='/svg-icons/material-icons/Basura.svg'
                                                    />
                                                    [kg] Basura:
                                                    <div className='ranking__myproperty__content__data__item__q'>
                                                        {data.TrashTrim.toLocaleString(
                                                            'es-CL',
                                                            {
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 0
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>
                ) : (
                    <>
                        {data.map((data, index) => (
                            <div
                                key={data.property ?? data.propertyUnit}
                                className='ranking__punished__content__list'
                            >
                                <div className='ranking__list__content__table__element__number subtitle'>
                                    {`#${!pdf ? parseInt(index) + 4 : index}`}
                                </div>
                                <div className='ranking__myproperty__content__data subtitle'>
                                    <div className='ranking__myproperty__content__data__item'>
                                        {data.propertyName ??
                                            data.propertyUnitName}
                                    </div>
                                    <div className='ranking__myproperty__content__data__item'>
                                        <img
                                            className='ranking-card__content-container__content__list-container__element__info__img'
                                            src={`/svg-icons/${
                                                data.BZeroIndexTrim < 50
                                                    ? 'DotIcon.svg'
                                                    : data.BZeroIndexTrim < 75
                                                    ? 'DotIconYellow.svg'
                                                    : 'DotIconRed.svg'
                                            }`}
                                        />
                                        Índice Bzero:
                                        <div className='ranking__myproperty__content__data__item__q'>
                                            {data.BZeroIndexTrim.toLocaleString(
                                                'es-CL',
                                                {
                                                    minimumFractionDigits: 1,
                                                    maximumFractionDigits: 1
                                                }
                                            )}
                                            %
                                        </div>
                                    </div>
                                    <div className='ranking__myproperty__content__data__item'>
                                        <img
                                            className='ranking__list__content__podium__item__header__logo'
                                            src='/svg-icons/Recyclable.svg'
                                        />
                                        [kg] Reciclables:
                                        <div className='ranking__myproperty__content__data__item__q'>
                                            {data.RecyclableTrim.toLocaleString(
                                                'es-CL',
                                                {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                }
                                            )}
                                        </div>
                                    </div>
                                    <div className='ranking__myproperty__content__data__item'>
                                        <img
                                            className='ranking__list__content__podium__item__header__logo'
                                            src='/svg-icons/material-icons/orgánicos.svg'
                                        />
                                        [kg] Orgánicos:
                                        <div className='ranking__myproperty__content__data__item__q'>
                                            {data.OrganicTrim.toLocaleString(
                                                'es-CL',
                                                {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                }
                                            )}
                                        </div>
                                    </div>
                                    <div className='ranking__myproperty__content__data__item'>
                                        <img
                                            className='ranking__list__content__podium__item__header__logo'
                                            src='/svg-icons/material-icons/Basura.svg'
                                        />
                                        [kg] Basura:
                                        <div className='ranking__myproperty__content__data__item__q'>
                                            {data.TrashTrim.toLocaleString(
                                                'es-CL',
                                                {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </div>
    );
};

export default RankingList;
