import './pageTitle.scss';

interface Props {
    title: string;
    icon?: string;
}

const PageTitle = ({ title, icon }: Props) => {
    return (
        <div className='page-title'>
            <span className='page-title__logo'>
                <img
                    className='page-title__logo__imgLogo'
                    src={`/svg-icons/${icon}`}
                />
            </span>
            <span className='page-title__text'>{title}</span>
        </div>
    );
};

export default PageTitle;
