import { ERRORS, REST_ERRORS } from './ErrorList';
import { TypeWithKey } from './TypeWithKey';

export const GetValidationError = (error: any) => {
    const codeMatcher: TypeWithKey<string> = ERRORS;
    return codeMatcher[error];
};

export const GetValidationErrorCode = (error: number) => {
    return REST_ERRORS[error].esMessage;
};
