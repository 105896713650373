import '../../settings.scss';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
//  <--COMPONENTS--> //
import Header from '../../../../components/header/header';
import SubNavBar from '../../../../components/layout/main/subnavbar/subnavbar';
import InvitesTableComponent from '../components/invites-table';
import CreateEditComponent from './create-component';
import ButtonBody from '../../../../components/button/button';
//  <--HOOKS--> //
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
import { useToast } from '../../../../config/hooks/useToast';
//  <--REDUX--> //
import { useAppSelector } from '../../../../store/hooks';
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
//  <--INTERFACE--> //
import {
    PropertyInterface,
    RoleInterface,
    GestorInterface,
    ClientInterface
} from '../../../../interface';
//  <--SERVICES--> //
import {
    ApiPropertyByUserRole,
    ApiUserRoleByRole,
    ApiGestorUserGestors,
    ApiClient
} from '../../../../config/service';
//  <--OTHERS--> //
import { lang } from '../../../langs';
import '../../../../scss/global/global.scss';

const CreateComponent = () => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences, defaultProfile, userData }: UserStoreInterface =
        useAppSelector(state => state.users);
    const { id } = useParams();
    const { roles } = userData;
    const selectedRole = roles[defaultProfile];
    const defaultLang: string = preferences.lang;
    const {
        [defaultLang as keyof typeof lang]: {
            pageInvites: {
                createTitle,
                title,
                subtitle,
                adminTitle,
                detailTitle
            }
        }
    } = lang;
    const [dataList, setDataList] = useState<RoleInterface[]>([]);
    const [dataList2, setDataList2] = useState<PropertyInterface[]>([]);
    const [dataList3, setDataList3] = useState<GestorInterface[]>([]);
    const [dataList4, setDataList4] = useState<ClientInterface[]>([]);

    /**
     * @description
     * @function CallData is a function that returns a promise with the Company list
     */
    const CallData = async () => {
        const response = await GetData(
            ApiUserRoleByRole(selectedRole.id),
            HTTP_METHODS.GET
        );
        const { data, status } = response;
        setDataList([...data].sort((a, b) => a.name.localeCompare(b.name)));

        const response2 = await GetData(
            ApiPropertyByUserRole(userData.id),
            HTTP_METHODS.GET,
            {},
            [selectedRole.id]
        );
        if(!response2.status){
            setDataList2([])
        }else{
            setDataList2(
                [...response2.data.properties].sort((a, b) =>
                    a.name.localeCompare(b.name)
                )
            );
        }
        

        const response3 = await GetData(
            ApiGestorUserGestors(userData.id),
            HTTP_METHODS.GET
        );
        setDataList3(
            [...response3.data].sort((a, b) => a.name.localeCompare(b.name))
        );

        const response4 = await GetData(ApiClient, HTTP_METHODS.GET);
        setDataList4(
            [...response4.data].sort((a, b) => a.name.localeCompare(b.name))
        );

        if (!status) {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * Function that is executed when the component is mounted, calls the function that returns the Company and UserRole list
     */
    useEffect(() => {
        CallData();
    }, []);

    return (
        <>
            <ToastElement ref={toastManagerRef} />
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/usersIcon.svg'
                kind='settings'
                option='User'
            ></SubNavBar>
            <div className='settings'>
                <Header
                    title={{ name: title, link: '/panel/user' }}
                    icon='UsersIconB.svg'
                    subtitle={{ name: subtitle }}
                />
                <div className='settings__grid-container'>
                    <div className='settings__grid-container__item'>
                        <div className='settings__grid-container__item__title subtitle'>
                            {adminTitle}
                        </div>
                        <InvitesTableComponent />
                        <div className='settings__grid-container__item__content'>
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={createTitle}
                                path='/panel/invitations/create'
                            ></ButtonBody>
                        </div>
                    </div>
                    <div className='settings__grid-container__item_right'>
                        <div className='settings__grid-container__item_right__title subtitle'>
                            <div>
                                {id ? (
                                    <div>{detailTitle}</div>
                                ) : (
                                    <div>{createTitle}</div>
                                )}
                            </div>
                            {/* Detalle de Clase / Editar / Crear */}
                        </div>
                        <div className='settings__grid-container__item_right__content'>
                            <CreateEditComponent
                                dataList={dataList}
                                dataList2={dataList2}
                                dataList3={dataList3}
                                dataList4={dataList4}
                            />
                            {LoadingData && <LoaderElement />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateComponent;
