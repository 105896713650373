import { useEffect, useState /* , useEffect */ } from 'react';
import './leftsection.scss';
import { ConfigProvider, Switch, Tooltip, Checkbox } from 'antd';
import { useToast } from '../../../../config/hooks/useToast';
import useWindowDimensions from '../../../../config/hooks/useWindowDimentions';
//  <--REDUX--> //
import { setSelected } from '../../../../store/slices/property';
import { setSelectedUnits } from '../../../../store/slices/property-unit';
import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { PropertyStoreInterface } from '../../../../store/slices/property/property.interface';
import { ClientStoreInterface } from '../../../../store/slices/client/client.interface';
import {
    PropertyInterface,
    PropertyUnitInterface
} from '../../../../interface';
import LogoComponent from '../../../logo/logo';
import { Link } from 'react-router-dom';
import { lang } from '../../../langs';
import {
    EyeOutlined,
    CaretDownOutlined,
    CaretUpOutlined
} from '@ant-design/icons';

interface Props {
    onClose?: any;
}

const LeftSection = ({ onClose }: Props) => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            layout: {
                main: {
                    leftsection: {
                        welcomeMsg,
                        listTitle,
                        warningMessage,
                        andTxt,
                        propertiesTxt
                    }
                }
            }
        }
    } = lang;
    const dispatch = useAppDispatch();
    const { userData }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { propertyData }: PropertyStoreInterface = useAppSelector(
        state => state.property
    );
    const { clientData }: ClientStoreInterface = useAppSelector(
        state => state.client
    );
    const [filteredProperties, setFilteredProperties] = useState<
        PropertyInterface[] | any[]
    >([]);
    const [searchInput, setSearchInput] = useState<string>('');
    const [selectedProperties, setSelectedProperties] = useState<
        PropertyInterface[]
    >([]);
    const [selectedPropertyUnits, setSelectedPropertyUnits] = useState<
        PropertyUnitInterface[]
    >([]);
    const [treeData, setTreeData] = useState<any>();
    /* const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true); */
    const { width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [showReadOnlys, setShowReadOnlys] = useState<boolean>(false);
    /* const [items, setItems] = useState<any[]>([]); */
    const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
    const [units, setUnits] = useState<any[]>([]);
    const [readOnlySelected, setReadOnlySelected] = useState<boolean>(false);

    useEffect(() => {
        setIsMobile(width <= 768);
    }, [width]);

    useEffect(() => {
        if (propertyData.length > 0) {
            const updatedProperties = [...propertyData].map(property => ({
                ...property,
                propertyUnits: property?.propertyUnits
                    ?.slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
            }));

            const uniqueProperties = Array.from(
                new Set(updatedProperties.map(obj => JSON.stringify(obj)))
            ).map(str => JSON.parse(str) as PropertyInterface);
            const units: any[] = clientData.flatMap(client => client.units);
            setUnits(units);
            const mergedProperties = [...uniqueProperties, ...units];
            setFilteredProperties(
                [...mergedProperties].sort((a, b) =>
                    a.name.localeCompare(b.name)
                )
            );
        }
    }, [propertyData]);

    useEffect(() => {
        const tree = filteredProperties.map(property => ({
            title: property.name,
            key: property.id,
            children: property.propertyUnits
                ?.filter(unit => unit.state === '1' || unit.state === 1)
                .map(unit => ({
                    title: unit.name,
                    key: unit.id
                })),
            state: property.active,
            showChildren: property.active === 'ReadOnly'
        }));
        setTreeData(tree);
    }, [filteredProperties]);

    useEffect(() => {
        dispatch(setSelected(selectedProperties));
    }, [selectedProperties]);

    useEffect(() => {
        dispatch(setSelectedUnits(selectedPropertyUnits));
    }, [selectedPropertyUnits]);

    /* const selectAll = () => {
        if(propertyData.length === checkedKeys.length) {
            setCheckedKeys([]);
            setSelectedProperties([]);
        } else {
            setSelectedProperties(propertyData);
            setCheckedKeys(propertyData.map(property => property.id));
        }
    } */

    const onCheck = element => {
        const key = element.key;
        const index = checkedKeys.indexOf(key);
        let updatedCheckedKeys;
        if (index !== -1) {
            updatedCheckedKeys = checkedKeys.filter(k => k !== key);
        } else {
            if (element.state === 'ReadOnly' || readOnlySelected) {
                updatedCheckedKeys = [key];
                setReadOnlySelected(!readOnlySelected);
            } else {
                updatedCheckedKeys = checkedKeys.concat(key);
            }
        }
        setCheckedKeys(updatedCheckedKeys);
        const properties = propertyData.filter(property =>
            updatedCheckedKeys.includes(property.id)
        );
        const propertyUnits = propertyData.flatMap(property =>
            (property?.propertyUnits || []).filter(unit =>
                updatedCheckedKeys.includes(unit.id)
            )
        );
        const propertyUnits2 = units.filter(unit =>
            updatedCheckedKeys.includes(unit.id)
        );
        const mergedPropertyUnits = propertyUnits.concat(propertyUnits2);
        setSelectedProperties(properties);
        setSelectedPropertyUnits(mergedPropertyUnits);

        if (properties.length + propertyUnits.length > 1) {
            const property = properties[0]?.name;
            const propertyUnit = propertyUnits[0]?.name;
            const others = properties.length - 1 + (propertyUnits.length - 1);
            let toastMessage;
            if (others > 0) {
                toastMessage = `${warningMessage} ${
                    property ?? propertyUnit
                } ${andTxt} +${others} ${propertiesTxt}`;
            } else {
                toastMessage = `${warningMessage} ${property ?? propertyUnit}`;
            }
            ConfigToast.text = toastMessage;
            ConfigToast.backColor = Colors.Warning;
            setConfigToast(ConfigToast);
        }
    };

    const handleChange = e => {
        e.preventDefault();
        const value = e.target.value;
        setSearchInput(value);
        if (value.length >= 1) {
            const filtered = propertyData.filter(property => {
                return property.name
                    .toLowerCase()
                    .includes(value.toLowerCase());
            });
            setFilteredProperties(
                [...filtered].sort((a, b) => a.name.localeCompare(b.name))
            );
        } else {
            setFilteredProperties(
                [...propertyData].sort((a, b) => a.name.localeCompare(b.name))
            );
        }
    };

    const CloseLeftSection = () => {
        onClose();
    };

    return (
        <>
            {' '}
            <ToastElement ref={toastManagerRef} />
            <div className='left-header'>
                <Link to='/panel' className='left-header__logo'>
                    <img
                        className='left-header__logo__img'
                        src={`${
                            userData?.clientData &&
                            userData.clientData.length > 0 &&
                            userData?.clientData[0].client?.image
                                ? userData.clientData[0].client.image
                                : '/logos/BZERO_LOGO.svg'
                        }`}
                    />
                </Link>
            </div>
            <div className='misinmuebles'>
                <div className='misinmuebles__user'>
                    <span className='misinmuebles__user__name subtitle'>
                        {welcomeMsg} {userData?.name} {userData?.lastName}
                    </span>
                    <span className='misinmuebles__user__role subtitle'>
                        {userData?.roles[0].name}
                    </span>
                </div>
                <div className='misinmuebles__titleContainer'>
                    <p className='misinmuebles__titleContainer__title title'>
                        {' '}
                        {listTitle}
                    </p>
                    {userData.roles.some(role =>
                        role.name.includes('General')
                    ) && (
                        <div className='misinmuebles__titleContainer__switch'>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Switch: {
                                            colorPrimary: '#a2a2a3',
                                            colorTextTertiary: '#a2a2a3',
                                            colorText: '#C4C3C5',
                                            colorTextQuaternary: '#C4C3C5',
                                            colorPrimaryHover: '#a2a2a3'
                                        }
                                    }
                                }}
                            >
                                <Tooltip
                                    title={
                                        <span className='main_menu__grid-container__tooltip'>
                                            Haz click para mostrar/ocultar
                                            aquellos de solo visualización
                                        </span>
                                    }
                                    color='#fff'
                                >
                                    <Switch
                                        className='profile-body__edit-grid-container__item__edit-info-container__not__option__toggle'
                                        size='small'
                                        onChange={() =>
                                            setShowReadOnlys(!showReadOnlys)
                                        }
                                    />
                                </Tooltip>
                            </ConfigProvider>
                        </div>
                    )}
                </div>
                <div className='misinmuebles__listcontainer'>
                    <div className='misinmuebles__listcontainer__list'>
                        <div className='misinmuebles__listcontainer__list__search'>
                            <input
                                className='misinmuebles__listcontainer__list__search__searchbar subtitle'
                                type='text'
                                placeholder='Buscar'
                                value={searchInput}
                                onChange={handleChange}
                            ></input>
                            <span className='misinmuebles__listcontainer__list__search__icon'>
                                <img
                                    className='misinmuebles__listcontainer__list__search__icon__img'
                                    src={`/svg-icons/SearchIcon.svg`}
                                />
                            </span>
                        </div>
                        <div className='misinmuebles__listcontainer__list__inmuebleslist'>
                            {/* <div
                                className='settings__grid-container__item__list__element subtitle'
                            >
                                <div
                                    className={`settings__grid-container__item__list__element__data subtitle`}
                                    style={{ color: '#2994E6', fontWeight: 'bold' }}
                                >
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Checkbox: {
                                                    colorPrimary: `${
                                                        '#2994e6'
                                                    }`,
                                                    colorBorder: `${
                                                        '#2994e6'
                                                    }`,
                                                    colorPrimaryHover: `${
                                                        '#2994e6'
                                                    }`
                                                }
                                            }
                                        }}
                                    >
                                        <Checkbox
                                            className={`settings__grid-container__item__list__element__data__checkbox`}
                                            onClick={selectAll}
                                            checked={propertyData.length === checkedKeys.length}
                                        />
                                    </ConfigProvider>
                                    <div
                                        className={`settings__grid-container__item__list__element__data__text`}
                                    >
                                        Seleccionar todo
                                    </div>
                                </div>
                            </div> */}
                            {treeData?.map(item =>
                                item.state !== 'ReadOnly' || showReadOnlys ? (
                                    <div
                                        className='settings__grid-container__item__list__element subtitle'
                                        key={item.key}
                                    >
                                        <div
                                            className={`settings__grid-container__item__list__element__${
                                                item.state === 'ReadOnly'
                                                    ? 'readOnly'
                                                    : 'data'
                                            } subtitle`}
                                            key={item.key}
                                            style={{ color: '#2994E6' }}
                                        >
                                            <ConfigProvider
                                                theme={{
                                                    components: {
                                                        Checkbox: {
                                                            colorPrimary: `${
                                                                item.state ===
                                                                'ReadOnly'
                                                                    ? '#C4C3C5'
                                                                    : '#2994e6'
                                                            }`,
                                                            colorBorder: `${
                                                                item.state ===
                                                                'ReadOnly'
                                                                    ? '#C4C3C5'
                                                                    : '#2994e6'
                                                            }`,
                                                            colorPrimaryHover: `${
                                                                item.state ===
                                                                'ReadOnly'
                                                                    ? '#C4C3C5'
                                                                    : '#2994e6'
                                                            }`
                                                        }
                                                    }
                                                }}
                                            >
                                                <Checkbox
                                                    className={`settings__grid-container__item__list__element__${
                                                        item.state ===
                                                        'ReadOnly'
                                                            ? 'readOnly'
                                                            : 'data'
                                                    }__checkbox`}
                                                    onClick={() =>
                                                        onCheck(item)
                                                    }
                                                    checked={checkedKeys.includes(
                                                        item.key
                                                    )}
                                                />
                                            </ConfigProvider>
                                            <div
                                                className={`settings__grid-container__item__list__element__${
                                                    item.state === 'ReadOnly'
                                                        ? 'readOnly'
                                                        : 'data'
                                                }__text`}
                                            >
                                                {item.title}
                                            </div>
                                            {item.state === 'ReadOnly' && (
                                                <span
                                                    className={`settings__grid-container__item__list__element__readOnly__logo`}
                                                >
                                                    <EyeOutlined rev={''} />
                                                </span>
                                            )}
                                            {item.children &&
                                                item.children.length > 0 && (
                                                    <button
                                                        className={`settings__grid-container__item__list__element__${
                                                            item.state ===
                                                            'ReadOnly'
                                                                ? 'readOnly'
                                                                : 'data'
                                                        }__dropdown`}
                                                        onClick={() =>
                                                            setTreeData(
                                                                prevItems =>
                                                                    prevItems.map(
                                                                        prevItem =>
                                                                            prevItem.key ===
                                                                            item.key
                                                                                ? {
                                                                                      ...prevItem,
                                                                                      showChildren:
                                                                                          !prevItem.showChildren
                                                                                  }
                                                                                : prevItem
                                                                    )
                                                            )
                                                        }
                                                    >
                                                        {item.showChildren ? (
                                                            <CaretUpOutlined
                                                                rev={''}
                                                            />
                                                        ) : (
                                                            <CaretDownOutlined
                                                                rev={''}
                                                            />
                                                        )}
                                                    </button>
                                                )}
                                        </div>
                                        {item.showChildren &&
                                            item.children &&
                                            item.children.length > 0 &&
                                            item.children.map(
                                                (child, index) => (
                                                    <div
                                                        className={`settings__grid-container__item__list__element__${
                                                            child.state ===
                                                            'ReadOnly'
                                                                ? 'readOnly'
                                                                : 'data'
                                                        } subtitle`}
                                                        style={{
                                                            marginLeft: '10%',
                                                            color: '#2994E6',
                                                            width: '95%'
                                                        }}
                                                        key={child.key}
                                                    >
                                                        <ConfigProvider
                                                            theme={{
                                                                components: {
                                                                    Checkbox: {
                                                                        colorPrimary:
                                                                            '#2994e6',
                                                                        colorBorder:
                                                                            '#2994e6',
                                                                        colorText:
                                                                            '#2994e6'
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            <Checkbox
                                                                className={`settings__grid-container__item__list__element__${
                                                                    item.state ===
                                                                    'ReadOnly'
                                                                        ? 'readOnly'
                                                                        : 'data'
                                                                }__checkbox`}
                                                                onClick={() =>
                                                                    onCheck(
                                                                        child
                                                                    )
                                                                }
                                                                checked={checkedKeys.includes(
                                                                    child.key
                                                                )}
                                                            />
                                                        </ConfigProvider>
                                                        <div
                                                            className={`settings__grid-container__item__list__element__${
                                                                child.state ===
                                                                'ReadOnly'
                                                                    ? 'readOnly'
                                                                    : 'data'
                                                            }__text`}
                                                        >
                                                            {child.title}
                                                        </div>
                                                        {child.state ===
                                                            'ReadOnly' && (
                                                            <EyeOutlined
                                                                rev={''}
                                                            />
                                                        )}
                                                    </div>
                                                )
                                            )}
                                    </div>
                                ) : (
                                    item.children &&
                                    item.children.length > 0 &&
                                    item.children.map(
                                        child =>
                                            child.state !== 'ReadOnly' && (
                                                <div
                                                    className='settings__grid-container__item__list__element subtitle'
                                                    key={child.key}
                                                    /* style={{
                                                        borderBottom:
                                                            '0.2px solid #2994E6'
                                                    }} */
                                                >
                                                    <div
                                                        className={`settings__grid-container__item__list__element__data subtitle`}
                                                        style={{
                                                            color: '#2994E6'
                                                        }}
                                                    >
                                                        <ConfigProvider
                                                            theme={{
                                                                components: {
                                                                    Checkbox: {
                                                                        colorPrimary:
                                                                            '#2994e6',
                                                                        colorBorder:
                                                                            '#2994e6',
                                                                        colorText:
                                                                            '#2994e6'
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            <Checkbox
                                                                className={`settings__grid-container__item__list__element__${
                                                                    item.state ===
                                                                    'ReadOnly'
                                                                        ? 'readOnly'
                                                                        : 'data'
                                                                }__checkbox`}
                                                                onClick={() =>
                                                                    onCheck(
                                                                        child
                                                                    )
                                                                }
                                                                checked={checkedKeys.includes(
                                                                    child.key
                                                                )}
                                                            />
                                                        </ConfigProvider>
                                                        <div
                                                            className={`settings__grid-container__item__list__element__data__text`}
                                                        >
                                                            {child.title}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    )
                                )
                            )}
                        </div>
                    </div>
                </div>
                <div className='misinmuebles__logo-container'>
                    {!isMobile ? (
                        <div className='misinmuebles__logo-container__logo-pryma'>
                            <Link to='/panel'>
                                <LogoComponent
                                    type='header'
                                    image='PRYMA_LOGO_VERDE.svg'
                                />
                            </Link>
                        </div>
                    ) : (
                        <div className='misinmuebles__logo-container__button'>
                            <button
                                className='button button-body--left-section'
                                onClick={CloseLeftSection}
                            >
                                Continuar
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default LeftSection;
