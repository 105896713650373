export const Globals = {
    localEnv: 'local',
    desaEnv: 'desa',
    prodEnv: 'prod',
    pageClass: 'page',
    toastDelayTime: 5000,
    IDBDefaultDateFilterKey: 'date',
    IDBDefaultDateFilterRange: ['0', '500012'],
    IDBDefaultMaterialFilterKey: 'materialId',
    ChartsDivisor: 1000,
    ChartCountMilestones: 12
};
