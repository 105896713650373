export interface RegisterInterface {
    id?: string;
    name: string;
    lastName: string;
    email: string;
    role: string;
    property?: string;
    rut: string;
    phone: string;
    password: string;
    newPasswordConfirm?: string;
    inviteId?: string;
    isOauth?: boolean | null;
    isGoogle?: boolean | null;
    isMs?: boolean | null;
    oauthId?: string | null;
}

export interface RegisterFormCreateInterface {
    name: string;
    lastName: string;
    email: string;
    role: string;
    property?: string;
    rut: string;
    phone: string;
    password: string;
    newPasswordConfirm?: string;
    inviteId?: string;
    isOauth?: boolean | null;
    isGoogle?: boolean | null;
    isMs?: boolean | null;
    oauthId?: string | null;
}

export const RegisterFormValues: RegisterFormCreateInterface = {
    name: '',
    lastName: '',
    email: '',
    role: '',
    property: '',
    rut: '',
    phone: '',
    password: '',
    newPasswordConfirm: '',
    inviteId: '',
    isOauth: null,
    isGoogle: null,
    isMs: null,
    oauthId: null
};
