import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
/* Pages */
/* Global components */
import Page404 from '../../pages/404';
import UsersSection from '../../pages/settings/user/user';
import { PagesList } from './PagesList';
import { GetStorage, SaveStorage } from '../utils/Storage';
import { useEffect } from 'react';
import { AppSectionLogInterface } from '../../interface';
import { ApiAccessLogDetails } from '../service';
import { useCallApi } from '../hooks/useCallApi';
import { HTTP_METHODS } from '../hooks/useCallApi/constants';

const deffaultRole =
    parseInt(GetStorage(process.env.REACT_APP_PUBLIC_DEFFAULT_ROLE)) || 0;

export const ProtectedRoutes = () => {
    const location = useLocation();
    const { userData } = useAppSelector(state => state.users);
    const { roles } = userData;
    const { appSections } = roles[deffaultRole];
    const { GetData } = useCallApi();

    const deffaultPage = PagesList.find(
        element => element.code === roles[deffaultRole].initialSection
    );

    const defaultPath = appSections.find(
        path => path.code === deffaultPage?.code
    );

    useEffect(() => {
        const path = location.pathname.substring(7, location.pathname.length);
        const appSection = appSections.find(item => item.path === path);
        if (path === '') return;
        SaveSection({ appSection, path });
    }, [location]);

    const SaveSection = async data => {
        const sectionsStorage: AppSectionLogInterface[] = await JSON.parse(
            GetStorage(process.env.REACT_APP_PUBLIC_USER_SECTIONS)
        );
        sectionsStorage.push({ ...data, order: sectionsStorage.length });
        SaveStorage(
            JSON.stringify(sectionsStorage),
            process.env.REACT_APP_PUBLIC_USER_SECTIONS
        );
        await GetData(ApiAccessLogDetails, HTTP_METHODS.PUT, sectionsStorage);
    };

    return (
        <>
            <Routes>
                {appSections.map(item => {
                    const code = item.code;
                    const matched = PagesList.find(
                        element => element.code === code
                    );
                    return (
                        <Route key={`${item.id}_page`} path={`${item.path}`}>
                            <Route
                                path='create'
                                element={matched?.CreateEdit}
                            />
                            <Route
                                path='edit/:id'
                                element={matched?.CreateEdit}
                            />
                            {matched?.code === 'PropePage' && (
                                <>
                                    <Route
                                        path=':id/units'
                                        element={matched?.Units}
                                    />
                                    <Route
                                        path=':id/units/edit/:propertyUnitId'
                                        element={matched?.UnitsCreateEdit}
                                    />
                                    <Route
                                        path=':id/units/create'
                                        element={matched?.UnitsCreateEdit}
                                    />
                                    <Route
                                        path=':id/reports-settings'
                                        element={matched?.Config}
                                    />
                                    <Route
                                        path=':id/units/:propertyUnitId/reports-settings'
                                        element={matched?.Config}
                                    />
                                </>
                            )}
                            {matched?.code === 'FontPage' && (
                                <>
                                    <Route
                                        path=':id/requirements'
                                        element={matched?.Config}
                                    />
                                    <Route
                                        path=':id/requirements/edit/:requirementId'
                                        element={matched?.ConfigCreateEdit}
                                    />
                                    <Route
                                        path=':id/requirements/create'
                                        element={matched?.ConfigCreateEdit}
                                    />
                                </>
                            )}
                            {matched?.code === 'CompaPage' && (
                                <>
                                    <Route
                                        path=':id/trucks'
                                        element={matched?.Trucks}
                                    />
                                    <Route
                                        path=':id/trucks/edit/:truckId'
                                        element={matched?.TrucksCreateEdit}
                                    />
                                    <Route
                                        path=':id/trucks/create'
                                        element={matched?.TrucksCreateEdit}
                                    />
                                    <Route
                                        path=':id/gestor-users'
                                        element={matched?.GestorUsers}
                                    />
                                    <Route
                                        path=':id/gestor-users/edit/:gestorUserId'
                                        element={matched?.GestorUsersCreateEdit}
                                    />
                                    <Route
                                        path=':id/gestor-users/create'
                                        element={matched?.GestorUsersCreateEdit}
                                    />
                                    <Route
                                        path=':id/default-values'
                                        element={matched?.DefaultValues}
                                    />
                                </>
                            )}
                            {matched?.code === 'ClientsPage' && (
                                <>
                                    <Route
                                        path=':id/properties'
                                        element={matched?.Properties}
                                    />
                                </>
                            )}
                            <Route index element={matched?.Component} />
                        </Route>
                    );
                })}
                {defaultPath && (
                    <Route
                        path='/'
                        element={
                            appSections.length > 0 && (
                                <Navigate to={defaultPath.path} />
                            )
                        }
                    />
                )}
                <Route path='/user' element={<UsersSection />} />
                <Route path='/404' element={<Page404 />} />
                <Route path='/*' element={<Page404 />} />
            </Routes>
        </>
    );
};
