import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
// eslint-disable-next-line @typescript-eslint/no-unused-vars

interface Props {
    onSuccess: (userData) => void;
    onError: () => void;
    textType?: 'signup_with' | 'signin_with' | 'signin' | 'continue_with';
}

const GoogleAuth = ({ onSuccess, onError, textType }: Props) => {
    const OnSuccess = credentialResponse => {
        const { clientId, credential } = credentialResponse;
        const decodedInfo: any = jwtDecode(JSON.stringify(credential));
        const googleUserData = {
            clientId,
            ...decodedInfo
        };
        onSuccess(googleUserData);
    };

    const OnError = () => {
        onError();
    };

    return (
        <div
            style={{ width: '140px', overflow: 'hidden', borderRadius: '4px' }}
        >
            <GoogleOAuthProvider clientId='519911183861-r7ggi30c3vheda0p2fe1457ccbvmf525.apps.googleusercontent.com'>
                <GoogleLogin
                    size='medium'
                    text={textType}
                    theme='filled_blue'
                    onSuccess={OnSuccess}
                    onError={OnError}
                />
            </GoogleOAuthProvider>
        </div>
    );
};

export default GoogleAuth;
