import {
    TreatmentInterface,
    MaterialInterface,
    FinalDisposalUnitInterface
} from '../../../interface';
export enum AlignValues {
    left = 'left',
    center = 'center',
    right = 'right'
}

export enum TextTypesValues {
    upperCase = 'upperCase',
    lowerCase = 'lowerCase',
    pascalCase = 'pascalCase'
}

export enum EditableTypesValues {
    text = 'text',
    list = 'list'
}

export enum TableEditableTypesValues {
    inRow = 1,
    inLineBottom = 2
}

type Align = typeof AlignValues[keyof typeof AlignValues];
type TextTypes = typeof TextTypesValues[keyof typeof TextTypesValues];
type EditableTypes =
    typeof EditableTypesValues[keyof typeof EditableTypesValues];
type TableEditableTypes =
    typeof TableEditableTypesValues[keyof typeof TableEditableTypesValues];

export interface OptionsInterface {
    strong?: boolean;
    align?: Align;
    textType?: TextTypes;
    color?: string;
}

export interface HeadersInterface {
    dataField: string | null;
    relatedDatafield?: string | null;
    dataFieldIsNotAutoFill?: string[] | null;
    compoundDataFIeld?: string | null;
    text: string;
    isEditable?: boolean;
    editableType?: EditableTypes;
    editableListData?: object[];
    editableListDataKey?: string;
    options?: OptionsInterface;
}

interface ActionButtonsInterface {
    onEdit?: (id: string) => void;
}

interface HighLight {
    field: string;
    addClass: string;
    show: boolean;
}

export interface TableInterface {
    headers: HeadersInterface[];
    data: any;
    saveData: (data: any) => void;
    actionsButtons?: ActionButtonsInterface;
    type: TableEditableTypes;
    highlight: HighLight | null;
    treatments: TreatmentInterface[];
    materials: MaterialInterface[];
    finalDisposalUnits: FinalDisposalUnitInterface[];
    onRefresh: number;
    onSaveChange: boolean;
}

export interface IsEditingInterface {
    editing: boolean;
    element: number | null;
}

export interface DataRowEditedInterface {
    id: string;
    text: string;
}
