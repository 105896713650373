export const constantsTypologyTable = {
    keysFilter: ['origin', 'stageInternalRegistry']
};

export const constantsFontTable = {
    keysFilter: ['type', 'name']
};

export const constantsRequirementTable = {
    keysFilter: ['name']
};
