import '../ranking.scss';
import { Tooltip } from 'antd';

interface Props {
    disqualified: any[];
    pdf?: boolean;
}

const Disqualified = ({ disqualified, pdf }: Props) => {
    return (
        <div
            className='ranking__punished'
            style={{ height: pdf ? 'auto' : '25%' }}
        >
            <div className='ranking__punished__title title'>
                Inmuebles/Unidades seleccionadas que no participan de ranking
                por falta de requerimiento
            </div>
            <div className='ranking__punished__content'>
                {disqualified.length > 0 &&
                    disqualified.map(item => (
                        <div
                            className='ranking__punished__content__list'
                            key={item.property ?? item.propertyUnit}
                        >
                            <div className='ranking__myproperty__content__number subtitle'>
                                #?
                            </div>
                            <div className='ranking__myproperty__content__data subtitle'>
                                <div className='ranking__myproperty__content__data__item'>
                                    {item.propertyName ?? item.propertyUnitName}
                                </div>
                                <div className='ranking__myproperty__content__data__item'>
                                    <img
                                        className='ranking-card__content-container__content__list-container__element__info__img'
                                        src={`/svg-icons/${
                                            item?.BZeroIndexTrim < 50
                                                ? 'DotIcon.svg'
                                                : item?.BZeroIndexTrim < 75
                                                ? 'DotIconYellow.svg'
                                                : 'DotIconRed.svg'
                                        }`}
                                    />
                                    Índice Bzero:
                                    <div className='ranking__myproperty__content__data__item__q'>
                                        {item.BZeroIndexTrim.toLocaleString(
                                            'es-CL',
                                            {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0
                                            }
                                        )}
                                        %
                                    </div>
                                </div>
                                <div className='ranking__myproperty__content__data__item'>
                                    {item.warning?.[0]?.message ? (
                                        <Tooltip
                                            title={
                                                <span className='main_menu__grid-container__tooltip'>
                                                    {item.warning?.[0]?.message}
                                                </span>
                                            }
                                            color='#fff'
                                        >
                                            <img
                                                className='ranking__list__content__podium__item__header__logo'
                                                src='/svg-icons/RecyclableWarn.svg'
                                            />
                                        </Tooltip>
                                    ) : (
                                        <img
                                            className='ranking__list__content__podium__item__header__logo'
                                            src='/svg-icons/Recyclable.svg'
                                        />
                                    )}
                                    [kg] Reciclables:
                                    <div className='ranking__myproperty__content__data__item__q'>
                                        {item.RecyclableTrim.toLocaleString(
                                            'es-CL',
                                            {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0
                                            }
                                        )}
                                    </div>
                                </div>
                                <div className='ranking__myproperty__content__data__item'>
                                    {item.warning?.[1]?.message ? (
                                        <Tooltip
                                            title={
                                                <span className='main_menu__grid-container__tooltip'>
                                                    {item.warning?.[1]?.message}
                                                </span>
                                            }
                                            color='#fff'
                                        >
                                            <img
                                                className='ranking__list__content__podium__item__header__logo'
                                                src='/svg-icons/OrganicWarn.svg'
                                            />
                                        </Tooltip>
                                    ) : (
                                        <img
                                            className='ranking__list__content__podium__item__header__logo'
                                            src='/svg-icons/material-icons/orgánicos.svg'
                                        />
                                    )}
                                    [kg] Orgánicos:
                                    <div className='ranking__myproperty__content__data__item__q'>
                                        {item.OrganicTrim.toLocaleString(
                                            'es-CL',
                                            {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0
                                            }
                                        )}
                                    </div>
                                </div>
                                <div className='ranking__myproperty__content__data__item'>
                                    {item.warning?.[2]?.message ? (
                                        <Tooltip
                                            title={
                                                <span className='main_menu__grid-container__tooltip'>
                                                    {item.warning?.[2]?.message}
                                                </span>
                                            }
                                            color='#fff'
                                        >
                                            <img
                                                className='ranking__list__content__podium__item__header__logo'
                                                src='/svg-icons/TrashWarn.svg'
                                            />
                                        </Tooltip>
                                    ) : (
                                        <img
                                            className='ranking__list__content__podium__item__header__logo'
                                            src='/svg-icons/material-icons/Basura.svg'
                                        />
                                    )}
                                    [kg] Basura:
                                    <div className='ranking__myproperty__content__data__item__q'>
                                        {item.TrashTrim.toLocaleString(
                                            'es-CL',
                                            {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0
                                            }
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default Disqualified;
