import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
//  <--COMPONENTS--> //
import { Container } from '../../components/styles';
import LogoComponent from '../../components/logo/logo';
import FormBuilder from '../../components/formBuilder/formBuilder';
//  <--HOOKS--> //
import { useToastHook } from '../../store/slices/toast/toast.hook';
import { useToast } from '../../config/hooks/useToast';
import { useCallApi } from '../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../config/hooks/useCallApi/constants';
//  <--INTERFACE--> //
import { UserRequestResetPasswordFormValues } from '../../interface';
//  <--REDUX--> //
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import { useAppSelector } from '../../store/hooks';
//  <--SERVICES--> //
import { ApiUsersRequestResetPassword } from '../../config/service';
//  <--OTHERS--> //
import { lang } from '../langs';
import { FormRequestResetPassword } from './form';
import '../../scss/global/global.scss';

const RequestResetPassword = () => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const { ToastConfig } = useToastHook();
    const { Colors, goPreviousPage } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const fieldRef = useRef(null);
    const {
        [defaultLang as keyof typeof lang]: {
            pagePassword: { recoveryTitle }
        }
    } = lang;
    const [successCleanForm, setSuccessCleanForm] = useState<boolean>(true);

    /**
     * @description
     * @function OnSubmit is a function that is called when the form is submitted
     * @param values is a object that contains the data of the form
     */
    const OnSubmit = async values => {
        setSuccessCleanForm(false);
        const response = await GetData(
            ApiUsersRequestResetPassword,
            HTTP_METHODS.POST,
            values
        );
        const { message, status } = response;
        if (status) {
            ToastConfig({
                message,
                color: Colors.Success
            });
            goPreviousPage();
        } else {
            ToastConfig({
                message,
                color: Colors.Error
            });
        }
    };

    /**
     * @description
     * @function FocusOnInput is a function that is called when the form's cancel button is clicked
     * @param ref is a reference to the form
     */
    const FocusOnInput = (ref: any) => {
        ref.current.focus();
    };

    /**
     * @description
     * @constant FormButtons is a object that contains the buttons of the form
     */
    const FormButtons = {
        ok: {
            controller: OnSubmit
        },
        cancel: {
            controller: FocusOnInput
        }
    };

    return (
        <div className='register_page'>
            <div className='register_page__item left-register'>
                <Container className='_register'>
                    <LogoComponent image='PRYMA_LOGO_BLANCO.png' />
                    {LoadingData && <LoaderElement />}
                </Container>
                <div className='register-logo-inferior'>
                    {/* <div className='poweredby-text'>Powered by</div> */}
                    <LogoComponent image='BZERO_LOGO_BLANCO.svg' />
                </div>
            </div>
            <div className='register_page__item right-register'>
                <div className='register_page__item__content'>
                    <div className='register_page__item__content__title'>
                        <img
                            className='register_page__item__content__title__img'
                            src='/svg-icons/SettingsIconG.svg'
                        />
                        <div className='register_page__item__content__title__text title'>
                            {recoveryTitle}
                        </div>
                    </div>
                    <div className='register_page__item__content__form'>
                        <FormBuilder
                            initialValues={UserRequestResetPasswordFormValues}
                            buttons={FormButtons}
                            lang={defaultLang}
                            formStructure={FormRequestResetPassword}
                            focusOn={fieldRef}
                            config={{ noClearButton: false }}
                            onSuccessCleanForm={successCleanForm}
                        />
                    </div>
                    <div className='register_page__item__content__message'>
                        <Link
                            to={`/login`}
                            className='register_page__item__content__message__link title'
                        >
                            Volver al inicio de sesión
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        /* <Container>
            <PageTitle title={recoveryTitle} icon='settings/propertiesB.svg' />
            <GoBack />
            <Wrapper>
                <FormBuilder
                    initialValues={UserRequestResetPasswordFormValues}
                    buttons={FormButtons}
                    lang={defaultLang}
                    formStructure={FormRequestResetPassword}
                    focusOn={fieldRef}
                    config={{ noClearButton: false }}
                    onSuccessCleanForm={successCleanForm}
                />
            </Wrapper>
            {LoadingData && <LoaderElement />}
        </Container> */
    );
};

export default RequestResetPassword;
