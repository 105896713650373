import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
//  <--COMPONENTS--> //
import { Wrapper, Container } from '../../components/styles';
import PageTitle from '../../components/pagetitle/pageTitle';
import FormBuilder from '../../components/formBuilder/formBuilder';
//  <--HOOKS--> //
import { useToastHook } from '../../store/slices/toast/toast.hook';
import { useToast } from '../../config/hooks/useToast';
import { useCallApi } from '../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../config/hooks/useCallApi/constants';
//  <--INTERFACE--> //
import { UserInterface, UserRecoverPasswordFormValues } from '../../interface';
//  <--REDUX--> //
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import { useAppSelector } from '../../store/hooks';
//  <--SERVICES--> //
import {
    ApiUsersVerifyRptId,
    ApiUsersResetPassword
} from '../../config/service';
//  <--OTHERS--> //
import { lang } from '../langs';
import { FormChangeUserPass } from './form';
import '../../scss/global/global.scss';

const ResetPassword = () => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const { ToastConfig } = useToastHook();
    const { Colors } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const { id } = useParams();
    const fieldRef = useRef(null);
    const {
        [defaultLang as keyof typeof lang]: {
            pagePassword: {
                notExistRpt,
                greeting,
                postGreeting,
                recoveryTitle,
                errorPassvalidate
            }
        }
    } = lang;
    const [readyForm, setReadyForm] = useState<boolean>(false);
    const [resetPassToken, setResetPassToken] = useState<string>('');
    const [entity, setEntity] = useState<UserInterface>();
    const [successCleanForm, setSuccessCleanForm] = useState<boolean>(true);

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        if (id) {
            setResetPassToken(id);
        }
    }, [id]);

    /**
     * @description CallData function, call the api to get the data of the entity and set the data in state
     */
    const CallData = async () => {
        const response = await GetData(
            ApiUsersVerifyRptId(resetPassToken),
            HTTP_METHODS.GET
        );
        const { data, status } = response;
        if (!data) {
            setReadyForm(false);
            return;
        }
        setEntity(data);
        setReadyForm(true);
        if (!status) {
            ToastConfig({
                message: data.message,
                color: Colors.Error
            });
        }
    };

    /**
     * @description this effect check that inviteId is not empty and if it changes, if not empty, call the function to CallData
     */
    useEffect(() => {
        if (resetPassToken !== '') {
            CallData();
        }
    }, [resetPassToken]);

    /**
     * @description
     * @function OnSubmit is a function that is called when the form is submitted
     * @param values is a object that contains the data of the form
     */
    const OnSubmit = async values => {
        setSuccessCleanForm(false);
        if (values.password !== values.passwordConfirm) {
            ToastConfig({
                message: errorPassvalidate,
                color: Colors.Warning
            });
            return;
        }
        values.resetPasswordToken = id;
        const response = await GetData(
            ApiUsersResetPassword,
            HTTP_METHODS.POST,
            values
        );
        const { message, data, status } = response;
        if (status) {
            ToastConfig({
                message,
                color: Colors.Success
            });
        } else {
            ToastConfig({
                message: data.message,
                color: Colors.Error
            });
        }
    };

    /**
     * @description
     * @function FocusOnInput is a function that is called when the form's cancel button is clicked
     * @param ref is a reference to the form
     */
    const FocusOnInput = (ref: any) => {
        ref.current.focus();
    };

    /**
     * @description
     * @constant FormButtons is a object that contains the buttons of the form
     */
    const FormButtons = {
        ok: {
            controller: OnSubmit
        },
        cancel: {
            controller: FocusOnInput
        }
    };

    return (
        <Container>
            <PageTitle title={recoveryTitle} icon='' />
            {!entity && <p> {notExistRpt} </p>}
            <Wrapper>
                {readyForm && (
                    <>
                        <p className='title'>
                            {greeting} {entity?.name} {postGreeting}{' '}
                        </p>
                        <FormBuilder
                            initialValues={UserRecoverPasswordFormValues}
                            buttons={FormButtons}
                            lang={defaultLang}
                            formStructure={FormChangeUserPass}
                            focusOn={fieldRef}
                            config={{ noClearButton: false }}
                            onSuccessCleanForm={successCleanForm}
                        />
                    </>
                )}
            </Wrapper>
            {LoadingData && <LoaderElement />}
        </Container>
    );
};

export default ResetPassword;
