import { FormBuilderFields } from '../../../../components/formBuilder/form-builder.interface';

export const FormCreateTypology: FormBuilderFields[] = [
    {
        label: 'Nombre',
        fieldNameId: 'name',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese su descripción',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Tipo de medición',
        fieldNameId: 'transferResidue',
        fieldType: 'boolean',
        deffaultValues: true,
        elements: [
            {
                id: false,
                name: 'De acumulación'
            },
            {
                id: true,
                name: 'De entrega'
            }
        ],
        fieldPlaceholder: 'Seleccione el traspaso',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Origen',
        fieldNameId: 'origin',
        fieldType: 'select',
        elements: [
            { id: 'Acopio Final', name: 'Acopio Final' },
            { id: 'Edificio', name: 'Edificio' },
            { id: 'Unidad', name: 'Unidad' }
        ],
        fieldPlaceholder: 'Seleccione el origen',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Destino',
        fieldNameId: 'destination',
        fieldType: 'select',
        elements: [
            { id: 'Acopio Final', name: 'Acopio Final' },
            { id: 'Edificio', name: 'Edificio' },
            { id: 'Gestor', name: 'Gestor' },
            { id: 'Tratamiento', name: 'Tratamiento' }
        ],
        fieldPlaceholder: 'Seleccione el destino',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    }
];
