export const SaveStorage = (data: any, name: string | undefined) => {
    if (name) {
        localStorage.setItem(name, JSON.stringify(data));
    }
};

export const GetStorage = (name: string | undefined) => {
    if (name) {
        const data: any = localStorage.getItem(name);
        return JSON.parse(data);
    }
};

export const DeleteStorage = (name: string | undefined) => {
    if (name) {
        localStorage.removeItem(name);
    }
};

export const DeleteAllStorage = () => {
    const sessionsData = localStorage.getItem('sessions');
    localStorage.clear();
    if (sessionsData) {
        localStorage.setItem('sessions', sessionsData);
    }
};
