//  <--COMPONENTS--> //
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import './header.scss';
import { lang } from '../langs';
import { useAppSelector } from '../../store/hooks';
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import { Breadcrumb, ConfigProvider } from 'antd';

interface ItemInterface {
    name: string;
    link?: string;
}

interface Props {
    title?: ItemInterface;
    icon?: string;
    subtitle?: ItemInterface;
    subsubtitle?: ItemInterface;
    subsubsubtitle?: ItemInterface;
}

const Header = ({
    title,
    icon,
    subtitle,
    subsubtitle,
    subsubsubtitle
}: Props) => {
    const navigate = useNavigate();
    const GoBack = () => {
        navigate(-1);
    };
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            header: { goBack }
        }
    } = lang;

    const breadcrumbItems = [
        {
            title: (
                <div className='header__breadcrumb__title'>
                    {icon && (
                        <img
                            className='header__breadcrumb__title__imgLogo'
                            src={`/svg-icons/settings/${icon}`}
                        />
                    )}
                    {title?.link ? (
                        <Link
                            className='header__breadcrumb__title__text'
                            to={title.link}
                        >
                            {title.name}
                        </Link>
                    ) : (
                        <span className='header__breadcrumb__title__text'>
                            {title?.name}
                        </span>
                    )}
                </div>
            )
        }
    ];

    if (subtitle?.link) {
        breadcrumbItems.push({
            title: (
                <Link
                    className='header__breadcrumb__title__text'
                    to={subtitle.link}
                >
                    {subtitle.name}
                </Link>
            )
        });
    } else if (subtitle) {
        breadcrumbItems.push({
            title: (
                <span className='header__breadcrumb__title__text'>
                    {subtitle.name}
                </span>
            )
        });
    }

    if (subsubtitle?.link) {
        breadcrumbItems.push({
            title: (
                <Link
                    className='header__breadcrumb__title__text'
                    to={subsubtitle.link}
                >
                    {subsubtitle.name}
                </Link>
            )
        });
    } else if (subsubtitle) {
        breadcrumbItems.push({
            title: (
                <span className='header__breadcrumb__title__text'>
                    {subsubtitle.name}
                </span>
            )
        });
    }

    if (subsubsubtitle?.link) {
        breadcrumbItems.push({
            title: (
                <Link
                    className='header__breadcrumb__title__text'
                    to={subsubsubtitle.link}
                >
                    {subsubsubtitle.name}
                </Link>
            )
        });
    } else if (subsubsubtitle) {
        breadcrumbItems.push({
            title: (
                <span className='header__breadcrumb__title__text'>
                    {subsubsubtitle.name}
                </span>
            )
        });
    }

    return (
        <div className='header'>
            <ConfigProvider
                theme={{
                    components: {
                        Breadcrumb: {
                            colorText: 'rgb(31 31 31)'
                        }
                    }
                }}
            >
                <Breadcrumb
                    className='header__breadcrumb title'
                    items={breadcrumbItems}
                />
            </ConfigProvider>
            <a className='header__goBack subtitle' onClick={GoBack}>
                {goBack}
            </a>
        </div>
    );
};

export default Header;
