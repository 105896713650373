import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DetailsIcon from '@material-ui/icons/Notes';
import CompanyIcon from '@material-ui/icons/BusinessTwoTone';
//  <--HOOKS--> //
import { useToast } from '../../config/hooks/useToast';
import { useAlert } from '../../config/hooks/useAlert';
import { useCallApi } from '../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../config/hooks/useCallApi/constants';
//  <--COMPONENTS--> //
import { Wrapper, WrapperButtons, Container } from '../../components/styles';
import PageTitle from '../../components/pagetitle/pageTitle';
import TableComponent from '../../components/table';
import { GestorUserTable } from '../../components/table/data/columns_table';
// <--SERVICES--> //
import { ApiGestorUser, ApiGestorUserId } from '../../config/service';
// <--INTERFACE--> //
import { GestorUserInterface } from '../../interface';
import { constantsTable } from './constants';
import { lang } from '../langs';
//  <--REDUX--> //
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import { useAppSelector } from '../../store/hooks';

const GestorUser = () => {
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        AlertElement,
        alertManagerRef,
        ConfigAlert,
        setConfigAlert,
        Defaults
    } = useAlert({ defaultLang });
    const navigate = useNavigate();
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const [dataList, setDataList] = useState<GestorUserInterface[]>([]);

    const {
        [defaultLang as keyof typeof lang]: {
            pageGestorUser: { gestorUsersTitle, buttonNew, deleteMessage }
            // detailsTitle
        }
    } = lang;
    const { keysFilter } = constantsTable;

    /**
     * @description
     * @function CallData is a function that returns a promise with the GestorUser list
     */
    const CallData = async () => {
        const response = await GetData(ApiGestorUser, HTTP_METHODS.GET);
        const { data, status } = response;
        setDataList(data);
        if (!status) {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * Function that is executed when the component is mounted, calls the function that returns the GestorUser list
     */
    useEffect(() => {
        CallData();
    }, []);

    /**
     * @description
     * @function Edit is a function that redirects to the GestorUser edition page
     * @param {string} id is a string that contains the id of the GestorUser to be deleted
     */
    const Edit = (id: string) => {
        navigate(`./edit/${id}`);
    };

    /**
     * @description function that is executed when the delete button is pressed
     * @function Delete is a function that launches an alert to confirm the deletion of a GestorUser
     * @param id is a string that contains the id of the GestorUser to be deleted
     */
    const Delete = async (id: string) => {
        ConfigAlert.title = Defaults.titles.Delete;
        ConfigAlert.message = deleteMessage;
        ConfigAlert.okButtonText = Defaults.buttons.Ok.Accept;
        ConfigAlert.okButtonController = { func: ConfirmDelete, param: id };
        ConfigAlert.cancelButtonText = Defaults.buttons.Cancel.Cancel;
        setConfigAlert(ConfigAlert);
    };

    /**
     * @description function that is executed when the okDelete button is pressed
     * @function Delete is a function that deletes a GestorUser
     * @param id is a string that contains the id of the GestorUser to be deleted
     */
    const ConfirmDelete = async (id: string) => {
        const response = await GetData(
            ApiGestorUserId(id),
            HTTP_METHODS.DELETE
        );
        const { data, status, message } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            CallData();
            setConfigToast(ConfigToast);
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description function that calls the material data selected
     * @function CallGestorUserData is a function that calls the material data selected
     * @param id is a string that contains the id of the material to be consulted
     */
    const CallGestorUserData = async (id: string) => {};

    /**
     * @description function that is executed when the detail button is pressed
     * @function MaterialsData is a function that opens the detail modal and calls the function that returns the GestorUser data
     * @param id is a string that contains the id of the GestorUser to be consulted
     */
    const MaterialsData = async (id: string) => {
        CallGestorUserData(id);
    };

    /**
     * @description function that is executed when saves the material data related to the GestorUser
     * @function SuccessSave is a function that saves the material data related to the GestorUser
     * @param status is a boolean that indicates if the operation was successful
     */
    //  const SuccessSave = (status: boolean) => {
    //      if (status && selectedElement) {
    //          //  CallMaterialsData(selectedMaterialKind[0].id);
    //          console.log(selectedElement);
    //      }
    //  };

    return (
        <Container>
            <ToastElement ref={toastManagerRef} />
            <AlertElement ref={alertManagerRef} />
            <PageTitle title={gestorUsersTitle} />
            <Wrapper>
                <WrapperButtons className='app-d-flex__right'>
                    <Link to={'./create'} className='btn bzero_btn_primary'>
                        {buttonNew}
                    </Link>
                </WrapperButtons>
                <TableComponent
                    data={dataList}
                    headers={GestorUserTable}
                    showFilters={true}
                    keysFilter={keysFilter}
                    showPagination={true}
                    actionsButtons={{ onEdit: Edit, onDelete: Delete }}
                    addsActions={[
                        {
                            action: MaterialsData,
                            title: 'Ver materiales',
                            icon: <DetailsIcon />
                        },
                        {
                            action: MaterialsData,
                            title: 'Ver compañías',
                            icon: <CompanyIcon />
                        }
                    ]}
                />
            </Wrapper>
            {LoadingData && <LoaderElement />}
            {/* {showDetails && (
                <GestorUserDetail
                    showDetails={showDetails}
                    setShowDetails={setShowDetails}
                    detailsTitle={detailsTitle}
                    selectedEntity={selectedElement}
                    SuccessSave={SuccessSave}
                />
            )} */}
        </Container>
    );
};

export default GestorUser;
