//  <--COMPONENTS--> //
import { useEffect, useState, useRef } from 'react';
import SubNavBar from '../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../components/header/header';
//  <--REDUX--> //
import { useAppSelector } from '../../../store/hooks';
import { PropertyStoreInterface } from '../../../store/slices/property/property.interface';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { UnitStoreInterface } from '../../../store/slices/unit/unit.interface';
import { PDFExport } from '@progress/kendo-react-pdf';
import './ranking.scss';
import { lang } from '../../langs';
import MyProperty from './components/my-property';
import Disqualified from './components/disqualified';
import RankingList from './components/ranking-list';

interface ShowDataItem {
    BZeroIndexMonth: string;
    BZeroIndexTrim: number;
    OrganicMonth: string;
    OrganicTrim: number;
    RecyclableMonth: string;
    RecyclableTrim: number;
    TrashMonth: string;
    TrashTrim: number;
    property: string | null;
    propertyName: string | null;
    propertyUnit: string | null;
    propertyUnitName: string | null;
    warning?: any[];
}

const Ranking = () => {
    const { userData, preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { propertyData }: PropertyStoreInterface = useAppSelector(
        state => state.property
    );
    const { unitData }: UnitStoreInterface = useAppSelector(
        state => state.unit
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            pageDataCenter: {
                pageRanking: { title }
            }
        }
    } = lang;

    const [podio, setPodio] = useState<any[]>([]);
    const [data, setData] = useState<ShowDataItem[]>([]);
    const [disqualified, setDisqualified] = useState<ShowDataItem[]>([]);
    const [myProperty, setMyProperty] = useState<any>();
    const pdfExportComponent = useRef<PDFExport>(null);

    useEffect(() => {
        const metrics = localStorage.getItem('metrics');
        const metricsArray = metrics ? JSON.parse(metrics) : [];
        const metricsFloat = metricsArray.ranking.map(item => ({
            ...item,
            BZeroIndexTrim: parseFloat(item.BZeroIndexTrim) * 100,
            RecyclableTrim: parseFloat(item.RecyclableTrim) / 1000,
            OrganicTrim: parseFloat(item.OrganicTrim) / 1000,
            TrashTrim: parseFloat(item.TrashTrim) / 1000
        }));
        const validBZeroIndex = metricsFloat.filter(
            item => item.BZeroIndexTrim > 0
        );
        const sortedMetrics = [...validBZeroIndex].sort(
            (a, b) => a.BZeroIndexTrim - b.BZeroIndexTrim
        );
        setData(sortedMetrics);

        const podio: any = [];
        for (let i = 0; i < Math.min(sortedMetrics.length, 3); i++) {
            podio.push(sortedMetrics[i]);
        }
        if (podio.length >= 2) {
            const temp = podio[0];
            podio[0] = podio[1];
            podio[1] = temp;
        }
        setPodio(podio);

        const metricsDisqulifiedFloat = metricsArray.disqualified.map(item => ({
            ...item,
            BZeroIndexTrim: parseFloat(item.BZeroIndexTrim) * 100,
            RecyclableTrim: parseFloat(item.RecyclableTrim) / 1000,
            OrganicTrim: parseFloat(item.OrganicTrim) / 1000,
            TrashTrim: parseFloat(item.TrashTrim) / 1000
        }));
        setDisqualified(
            [...metricsDisqulifiedFloat].sort(
                (a, b) => a.BZeroIndexTrim - b.BZeroIndexTrim
            )
        );
        const mypropIndex = sortedMetrics.findIndex(
            item =>
                item?.property === propertyData[0]?.id ||
                item?.propertyUnit === unitData[0]?.id
        );
        const myprop =
            metricsFloat.find(item => {
                let found = false;
                propertyData.forEach(property => {
                    if (item?.property === property?.id) {
                        found = true;
                    } else if (property?.propertyUnits) {
                        property.propertyUnits.forEach(unit => {
                            if (
                                item?.property === unit?.id ||
                                item?.propertyUnit === unit?.id
                            ) {
                                found = true;
                            }
                        });
                    }
                });
                return found;
            }) ||
            metricsDisqulifiedFloat.find(item => {
                let found = false;
                propertyData.forEach(property => {
                    if (item?.property === property?.id) {
                        found = true;
                    } else if (property?.propertyUnits) {
                        property.propertyUnits.forEach(unit => {
                            if (
                                item?.property === unit?.id ||
                                item?.propertyUnit === unit?.id
                            ) {
                                found = true;
                            }
                        });
                    }
                });
                return found;
            });
        const mypropWithIndex = {
            ...myprop,
            index: mypropIndex === -1 ? '?' : mypropIndex + 1
        };
        setMyProperty(mypropWithIndex);
    }, []);

    const DownloadRanking = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save();
        }
    };

    return (
        <>
            <SubNavBar></SubNavBar>
            <div className='ranking'>
                <Header title={{ name: title }} />
                {(userData.roles[0].name.includes('Property Admin') ||
                    userData.roles[0].name.includes('Unit Admin')) && (
                    <MyProperty myProperty={myProperty} />
                )}
                <RankingList podio={podio} data={data} />
                <Disqualified disqualified={disqualified} />
                <button
                    className='button button-body--download-ranking'
                    onClick={DownloadRanking}
                >
                    Descargar ranking
                </button>
            </div>
            <div className='pdf-container'>
                <PDFExport
                    margin={{
                        top: '3cm',
                        left: '3cm',
                        right: '3cm',
                        bottom: '3cm'
                    }}
                    forcePageBreak={undefined}
                    ref={pdfExportComponent}
                    fileName='ranking.pdf'
                >
                    <div className='ranking'>
                        {(userData.roles[0].name.includes('Property Admin') ||
                            userData.roles[0].name.includes('Unit Admin')) && (
                            <MyProperty myProperty={myProperty} pdf={true} />
                        )}
                        <RankingList podio={podio} data={data} pdf={true} />
                        <Disqualified disqualified={disqualified} pdf={true} />
                    </div>
                </PDFExport>
            </div>
        </>
    );
};

export default Ranking;
