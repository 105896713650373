import { FormBuilderFields } from '../../../../components/formBuilder/form-builder.interface';

export const FormCreateRole: FormBuilderFields[] = [
    {
        label: 'Nombre',
        fieldNameId: 'name',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese su nombre',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Sección inicial',
        fieldNameId: 'initialSection',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese la sección inicial',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Valor',
        fieldNameId: 'value',
        fieldType: 'number',
        fieldPlaceholder: 'Ingrese el valor',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    }
    //  {
    //      label: 'Path',
    //      fieldNameId: 'path',
    //      fieldType: 'text',
    //      fieldPlaceholder: 'Ingrese el path',
    //      validations: {
    //          type: 'string',
    //          required: {
    //              state: true,
    //              message: 'Este campo es requerido'
    //          },
    //          min: {
    //              length: 3,
    //              state: true,
    //              message: 'Mínimo 3 carateres'
    //          },
    //      }
    //  },
    //  {
    //      label: 'Code',
    //      fieldNameId: 'path',
    //      fieldType: 'text',
    //      fieldPlaceholder: 'Ingrese el path',
    //      validations: {
    //          type: 'string',
    //          required: {
    //              state: true,
    //              message: 'Este campo es requerido'
    //          },
    //          min: {
    //              length: 3,
    //              state: true,
    //              message: 'Mínimo 3 carateres'
    //          },
    //      }
    //  },
    //  {
    //      label: 'Is Aside',
    //      fieldNameId: 'isAside',
    //      fieldType: 'select',
    //      elements: [
    //          {
    //              id: false,
    //              name: 'No'
    //          }
    //      ],
    //      fieldPlaceholder: 'Seleccione un elemento',
    //      validations: {
    //          type: 'string',
    //          required: {
    //              state: true,
    //              message: 'Este campo es requerido'
    //          }
    //      }
    //  },
    //  {
    //      label: 'Icon',
    //      fieldNameId: 'icon',
    //      fieldType: 'text',
    //      fieldPlaceholder: 'Ingrese el ícono',
    //      validations: {
    //          type: 'string',
    //          required: {
    //              state: true,
    //              message: 'Este campo es requerido'
    //          },
    //          min: {
    //              length: 3,
    //              state: true,
    //              message: 'Mínimo 3 carateres'
    //          },
    //      }
    //  }
];
