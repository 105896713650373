import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    registerables
} from 'chart.js';
import './style.scss';

ChartJS.register(
    ...registerables,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

export * from './bar/barChartComponent';
export * from './linear/linearChartComponent';
export * from './stacked-bar/stackerBarChartComponent';
export * from './pie/pieChartComponent';
