import './ecoequivalencesCard.scss';
import EcoequivalenceItem from './ecoequivalenceItem/ecoequivalenceItem';
import { useState, useEffect } from 'react';
import { FormatData } from '../../../config/utils/IdbFormatData';
import { MaterialInterface } from '../../../interface/material.interface';
import { lang } from '../../langs';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../store/hooks';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface Props {
    data: any;
    filterKinds: MaterialInterface[];
    filter: any;
    labels: string[];
    colorsDB: any;
    certificate?: boolean;
}

interface WasteCardInterface {
    id: string;
    label: string;
    total: string;
    percentage: string;
    color: string;
}

interface EcoequivalenceItemInterface {
    label: string;
    total: number;
    eqlabel: string;
    equivalent: number;
    unit: string;
    logo: string;
    type: string;
}

const EcoequivalencesCard = ({
    data,
    filterKinds,
    filter,
    labels,
    colorsDB,
    certificate
}: Props) => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            dataCenterComponents: {
                ecoequivalencesCard: { title, subtitle, detailMessage }
            }
        }
    } = lang;
    const [array, setArray] = useState<WasteCardInterface[]>([]);
    const [items, setItems] = useState<EcoequivalenceItemInterface[]>([]);

    const calculate = () => {
        const paper = array.find(
            obj => obj.id === '0ea9b622-6e03-8b90-982b-e6dbb64aab5f'
        );
        const carton = array.find(
            obj => obj.id === '68a12215-2298-051d-7324-c36b750821f4'
        );
        const aluminum = array.find(
            obj => obj.id === '00a9ecb5-1383-bcbc-b9bd-4e3948035a36'
        );
        const iron = array.find(
            obj => obj.id === '67e2744f-e62c-1717-350e-a4a67d8b0a84'
        );
        const plastic = array.find(
            obj => obj.id === '1cc267d0-bc88-10db-b3cd-ae3c57ede122'
        );
        const tetrapack = array.find(
            obj => obj.id === '49a844bf-8db8-4c01-db62-9f58ce4f164c'
        );
        const glass = array.find(
            obj => obj.id === '376e5241-b139-6592-2a21-dd7d4e9e6018'
        );
        const organic = array.find(
            obj => obj.id === '8d1c7369-111a-1050-68db-c54405f06de7'
        );
        const electronic = array.find(
            obj => obj.id === '5b281dc3-85f3-a7a5-e320-0723859965c9'
        );
        const newspaper = array.find(
            obj => obj.id === '5df2b576-ee52-ae36-0309-c7b32a05431e'
        );
        const magazines = array.find(
            obj => obj.id === '360ce3ce-70db-7857-8110-5a08516ff1ed'
        );
        const PERigid = array.find(
            obj => obj.id === 'b156bcb8-c8ba-aba4-6457-b2156947b30c'
        );
        const PET = array.find(
            obj => obj.id === '9e24923b-3925-260c-3d10-c1fbb6b0e6dc'
        );
        const PPRigid = array.find(
            obj => obj.id === '79f97056-579e-743b-3320-d832a092d0a2'
        );

        const paperTotal = paper ? parseFloat(paper.total) : 0;
        const cartonTotal = carton ? parseFloat(carton.total) : 0;
        const aluminumTotal = aluminum ? parseFloat(aluminum.total) : 0;
        const ironTotal = iron ? parseFloat(iron.total) : 0;
        const plasticTotal = plastic ? parseFloat(plastic.total) : 0;
        const tetrapackTotal = tetrapack ? parseFloat(tetrapack.total) : 0;
        const glassTotal = glass ? parseFloat(glass.total) : 0;
        const organicTotal = organic ? parseFloat(organic.total) : 0;
        const electronicTotal = electronic ? parseFloat(electronic.total) : 0;
        const newspaperTotal = newspaper ? parseFloat(newspaper.total) : 0;
        const magazinesTotal = magazines ? parseFloat(magazines.total) : 0;
        const PERigidTotal = PERigid ? parseFloat(PERigid.total) : 0;
        const PETTotal = PET ? parseFloat(PET.total) : 0;
        const PPRigidTotal = PPRigid ? parseFloat(PPRigid.total) : 0;

        const totalCO2kg =
            (0.0006758 * organicTotal +
                0.0011161 * electronicTotal +
                0.0100835 * aluminumTotal +
                0.0020418 * ironTotal +
                0.0003267 * glassTotal +
                0.0039906 * paperTotal +
                0.0033121 * tetrapackTotal +
                0.0020526 * newspaperTotal +
                0.0029132 * magazinesTotal +
                0.0008584 * PERigidTotal +
                0.001164 * PETTotal +
                0.0008973 * PPRigidTotal +
                0.0036563 * cartonTotal) *
            1000;

        const totalWater =
            (paperTotal + cartonTotal) * 26.5 +
            aluminumTotal * 0.98 +
            plasticTotal * 39.26 +
            tetrapackTotal * 19.8 +
            ironTotal * 0.27;
        const totalTrees = totalCO2kg / 1000 / 0.06;

        const totalEnergy = (totalCO2kg / 1000 / 7.94041) * 11880;

        const calculatedItems = [
            {
                type: 'trees',
                label: 'Árboles salvados',
                total: totalTrees,
                unit: '',
                eqlabel: ' resmas de papel',
                equivalent: totalTrees * 16.67,
                logo: '/svg-icons/eco-equivalences/trees.svg'
            },
            {
                type: 'co2',
                label: 'CO2 no emitido',
                total: totalCO2kg > 1000 ? totalCO2kg / 1000 : totalCO2kg,
                unit: totalCO2kg > 1000 ? 'ton' : 'kg',
                eqlabel: ' autos sin salir por un día',
                equivalent: totalCO2kg / 1000 / 0.01271518,
                logo: '/svg-icons/eco-equivalences/co2.svg'
            },
            {
                type: 'energy',
                label: 'Energía evitada',
                total: totalEnergy > 1000 ? totalEnergy / 1000 : totalEnergy,
                unit: totalEnergy > 1000 ? 'MWh' : 'kWh',
                eqlabel: ' meses de consumo de un hogar',
                equivalent: totalEnergy / 172.83,
                logo: '/svg-icons/eco-equivalences/energy.svg'
            },
            {
                type: 'water',
                label: 'Agua evitada',
                total: totalWater > 1000 ? totalWater / 1000 : totalWater,
                unit: totalWater > 1000 ? 'm' : 'l',
                eqlabel: ' horas regando',
                equivalent: totalWater / 1200,
                logo: '/svg-icons/eco-equivalences/water.svg'
            }
        ];
        setItems(calculatedItems);
    };

    useEffect(() => {
        if (labels.length > 0 && filterKinds.length > 0) {
            const dataFormated = FormatData({
                data,
                labels,
                chart: 'bar',
                milestones: 'date',
                dataSetList: filterKinds,
                dataSetList2: true,
                filter,
                colorsDB
            });
            const arrayvalues: any = [];
            dataFormated.datasets.map(item => {
                const data = {
                    id: item.id,
                    label: item.label,
                    total: item.data
                        .reduce(
                            (accumulator, currentValue) =>
                                parseFloat(accumulator) +
                                parseFloat(currentValue),
                            0
                        )
                        .toFixed(0),
                    color: item.backgroundColor
                };
                arrayvalues.push(data);
                return true;
            });
            setArray([...arrayvalues].sort((a, b) => b.total - a.total));
        } else {
            setArray([]);
        }
    }, [data, labels, filterKinds]);

    useEffect(() => {
        calculate();
    }, [array]);

    return (
        <div className='ecoequivalences-card'>
            {!certificate && (
                <>
                    <div className='ecoequivalences-card__titulo-card title'>
                        {title}
                    </div>
                    <div className='ecoequivalences-card__subtitulo-card subtitle'>
                        {subtitle}
                        <Tooltip
                            title={
                                <span className='main_menu__grid-container__tooltip'>
                                    {detailMessage}
                                </span>
                            }
                            color='#fff'
                        >
                            <InfoCircleOutlined
                                className='ecoequivalences-card__subtitulo-card__icon'
                                rev={''}
                            />
                        </Tooltip>
                    </div>
                </>
            )}
            <div
                className={
                    certificate
                        ? 'ecoequivalences-card__line-container'
                        : 'ecoequivalences-card__grid-container'
                }
            >
                {items.map((item, index) => {
                    return (
                        <EcoequivalenceItem
                            key={item.type}
                            logo={item.logo}
                            name={item.label}
                            kg={`${item.total} `}
                            eqlabel={item.eqlabel}
                            eq={`${item.equivalent}`}
                            unit={item.unit}
                            type={item.type}
                            certificate={certificate}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default EcoequivalencesCard;
