// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { MicrosoftLogin } from 'react-microsoft-login';
import './style.scss';

interface Props {
    onSuccess: (userData) => void;
    onError: () => void;
}

const MicrosoftAuth = ({ onSuccess, onError }: Props) => {
    const authHandler = (err, data) => {
        if (err) {
            onError();
        }
        if (data) {
            onSuccess(data);
        }
    };

    return (
        <div
            style={{ width: '140px', overflow: 'hidden', borderRadius: '4px' }}
        >
            <MicrosoftLogin
                clientId={'3dcf50c4-6a2f-4205-b040-bff7ccf1c238'}
                authCallback={authHandler}
                buttonTheme='dark_short'
                graphScopes={['user.read']}
                withUserData
                prompt='select_account'
            >
                <button className='microsfoftButton'>Microsoft</button>
            </MicrosoftLogin>
        </div>
    );
};

export default MicrosoftAuth;
