import { useEffect, useState } from 'react';
import {
    TableInterface,
    /* EditableTypesValues, */
    TableEditableTypesValues,
    IsEditingInterface,
    DataRowEditedInterface
} from './interface/table.interface';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import './table.scss';
import { Select } from 'antd';

const TableEditable = ({
    data,
    headers,
    actionsButtons,
    type,
    highlight,
    treatments,
    materials,
    finalDisposalUnits,
    saveData,
    onRefresh,
    onSaveChange
}: TableInterface) => {
    const [editing, setEditing] = useState<IsEditingInterface>({
        editing: false,
        element: null
    });

    const AddRow = (item, index) => {
        const now = new Date();
        const newRow = {
            id: now.getTime(),
            isEdit: false,
            isNew: true,
            rowEdited: false,
            edited_materialId: false,
            edited_treatmentId: false,
            edited_finalDisposalId: false,
            materialName: '',
            materialId: '',
            treatmentName: '',
            treatmentId: '',
            finalDisposalUnitName: '',
            finalDisposalUnitId: ''
        };
        const regs = registers;
        regs.push(newRow);
        setRegisters(regs);
        SetEditRow(item, index);
    };

    useEffect(() => {
        const regs: object[] = [];
        if (data && data.length > 0) {
            for (const el of data) {
                el.isEdit = false;
                el.rowEdited = false;
                el.edited_materialId = false;
                el.edited_treatmentId = false;
                el.edited_finalDisposalId = false;
                el.materialName = el.material.name;
                el.materialId = el.material.id;
                el.treatmentName = el.treatment.name;
                el.treatmentId = el.treatment.id;
                el.finalDisposalUnitName = `${el.finalDisposalUnit?.name} (${el.finalDisposalUnit?.code})`;
                el.finalDisposalUnitId = el.finalDisposalUnit?.id;
                regs.push(el);
            }
        }
        const now = new Date();
        const newRow = {
            id: now.getTime(),
            active: true,
            isEdit: false,
            isNew: true,
            rowEdited: false,
            edited_materialId: false,
            edited_treatmentId: false,
            edited_finalDisposalId: false,
            materialName: '',
            materialId: '',
            treatmentName: '',
            treatmentId: '',
            finalDisposalUnitName: '',
            finalDisposalUnitId: ''
        };
        regs.push(newRow);
        setRegisters(regs);
    }, [data]);

    const [registers, setRegisters] = useState<any>([]);
    const [editedData, setEditedData] = useState<DataRowEditedInterface[]>([]);

    useEffect(() => {
        setEditedData([]);
        setEditing({
            editing: false,
            element: null
        });
    }, [onRefresh]);

    const OnEditChangeValue = (
        valueName,
        element,
        field,
        index,
        relatedDatafield,
        selectedKey
    ) => {
        const value = selectedKey;
        const dataEdited = editedData;
        const obj = {
            id: value,
            text: valueName
        };
        const exist = dataEdited.find(element => element.id === obj.id);
        if (!exist) {
            ChangeStateData(
                registers,
                field,
                value,
                index,
                element,
                relatedDatafield,
                valueName
            );
        }
    };

    const SetEditRow = (item, index) => {
        setEditing({ ...editing, editing: true, element: index });
        ChangeStateData(registers, 'isEdit', true, index, item);
    };

    const SaveEditRow = (element, index) => {
        const array = editedData;
        if (!array.includes(element.id)) {
            array.push(element);
        }
        setEditedData(array);

        let changeData = registers;
        element.isEdit = false;
        element.autofilled = 0;
        changeData.splice(index, 1);
        changeData.splice(index, 0, element);
        changeData = [...changeData, element];
        changeData.splice(changeData.length - 1, 1);
        setRegisters(changeData);
        setEditing({ ...editing, editing: false, element: index });
    };

    const ChangeStateData = (
        originData,
        keyMutate,
        value,
        index,
        row,
        relatedDatafield = null,
        valueName = null
    ) => {
        let changeData = originData;
        const selected = changeData.find(x => x.id === row.id);
        selected[keyMutate] = value;
        if (relatedDatafield !== null && valueName !== null) {
            selected[relatedDatafield] = value;
            selected[keyMutate] = valueName;
            selected[`edited_${relatedDatafield}`] = true;
        }
        changeData.splice(index, 1);
        changeData.splice(index, 0, selected);
        changeData = [...changeData, selected];
        changeData.splice(changeData.length - 1, 1);
        setRegisters(changeData);
    };

    useEffect(() => {
        if (onSaveChange) {
            saveData(registers);
        }
    }, [onSaveChange]);

    const onSearch = (value: string) => {
        console.log('search:', value);
    };

    const filterOption = (
        input: string,
        option?: { label: string; value: string }
    ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <div className='tbl'>
            <table className='tbl__editable'>
                <thead className='tbl__editable__thead'>
                    <tr className='tbl__editable__thead__tr'>
                        {headers.map(item => (
                            <th
                                key={item.text}
                                className={`tbl__editable__thead__tr__th ${item.text}-default subtitle`}
                            >
                                {item.options ? (
                                    <span
                                        className='tbl__editable__thead__tr__th__text'
                                        style={{
                                            width: '100%',
                                            display: 'block',
                                            textAlign: item.options.align,
                                            fontWeight: item.options.strong
                                                ? 'bold'
                                                : 'normal'
                                        }}
                                    >
                                        {item.text}
                                    </span>
                                ) : (
                                    <span className='tbl__editable__thead__tr__th__text'>
                                        {item.text}
                                    </span>
                                )}
                            </th>
                        ))}
                        <th className='tbl__editable__thead__tr__th editColumn'>
                            {' '}
                            &nbsp;{' '}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {registers.map((item: any, index) => (
                        <tr
                            key={`TBL_EDITABLE_BODY_${index}`}
                            className={`tbl__editable__tbody__tr ${
                                highlight !== null &&
                                item[highlight?.field] === 1 &&
                                highlight.show
                                    ? highlight.addClass
                                    : ''
                            }`}
                        >
                            {type === TableEditableTypesValues.inRow &&
                                headers.map((header, idx) => {
                                    const nameCol =
                                        header.dataField === null
                                            ? 'id'
                                            : header.dataField;
                                    const addedclases = '';
                                    const selectedItemValue = item[nameCol];
                                    let options;
                                    if (nameCol === 'materialName') {
                                        options = materials.map(material => ({
                                            value: material.id,
                                            label: material.name
                                        }));
                                    } else if (nameCol === 'treatmentName') {
                                        options = treatments.map(treatment => ({
                                            value: treatment.id,
                                            label: treatment.name
                                        }));
                                    } else {
                                        options = finalDisposalUnits.map(
                                            finalDisposalUnit => ({
                                                value: finalDisposalUnit.id,
                                                label: `${finalDisposalUnit.name} (${finalDisposalUnit.code})`
                                            })
                                        );
                                    }
                                    return editing.editing &&
                                        index === editing.element ? (
                                        <td
                                            key={`TBL_EDITABLE_BODY_${nameCol}_${idx}`}
                                            className={
                                                header.isEditable
                                                    ? `tbl__editable__tbody__tr__td ${addedclases} subtitle`
                                                    : `tbl__editable__tbody__tr__td subtitle`
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: 'flex'
                                                }}
                                            >
                                                <Select
                                                    className='tbl__editable__tbody__tr__td__lst'
                                                    onChange={selectedKey => {
                                                        const selectedValue =
                                                            options.find(
                                                                option =>
                                                                    option.value ===
                                                                    selectedKey
                                                            ).label;
                                                        OnEditChangeValue(
                                                            selectedValue,
                                                            item,
                                                            header.dataField,
                                                            index,
                                                            header.relatedDatafield,
                                                            selectedKey
                                                        );
                                                    }}
                                                    defaultValue={
                                                        selectedItemValue
                                                    }
                                                    showSearch
                                                    optionFilterProp='children'
                                                    onSearch={onSearch}
                                                    filterOption={filterOption}
                                                    options={options}
                                                />
                                            </div>
                                        </td>
                                    ) : (
                                        <td
                                            key={`TBL_EDITABLE_BODY_${nameCol}_${idx}`}
                                            className={
                                                header.isEditable
                                                    ? `tbl__editable__tbody__tr__td ${addedclases} subtitle`
                                                    : `tbl__editable__tbody__tr__td subtitle`
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: 'flex'
                                                }}
                                            >
                                                {/* {!item.isEdit && ( */}
                                                <span
                                                    className={
                                                        highlight?.show &&
                                                        header.isEditable &&
                                                        item[
                                                            highlight.field
                                                        ] === 1
                                                            ? 'tbl__editable__tbody__tr__td__span isEditable subtitle'
                                                            : 'tbl__editable__tbody__tr__td__span subtitle'
                                                    }
                                                    style={{
                                                        width: '100%',
                                                        display: 'block'
                                                    }}
                                                >
                                                    {item[nameCol]}
                                                </span>
                                                {/* )} */}
                                            </div>
                                        </td>
                                    );
                                })}
                            <td
                                className=''
                                style={{
                                    minWidth: '130px',
                                    borderBottom: 'unset'
                                }}
                            >
                                {editing.editing &&
                                index === editing.element ? (
                                    <SaveIcon
                                        titleAccess='Grabar'
                                        className='tbl__editable__tbody__tr__td__icon'
                                        onClick={() => {
                                            SaveEditRow(item, index);
                                        }}
                                    />
                                ) : index === registers.length - 1 ? (
                                    <AddIcon
                                        titleAccess='Agregar'
                                        className='tbl__editable__tbody__tr__td__icon'
                                        onClick={() => {
                                            AddRow(item, index);
                                        }}
                                    />
                                ) : (
                                    <EditIcon
                                        titleAccess='Editar'
                                        className='tbl__editable__tbody__tr__td__icon'
                                        onClick={() => {
                                            SetEditRow(item, index);
                                        }}
                                    />
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TableEditable;
