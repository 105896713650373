import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
//  <--COMPONENTS--> //
import { Wrapper, Container } from '../../../components/styles';
import PageTitle from '../../../components/pagetitle/pageTitle';
import FormBuilder from '../../../components/formBuilder/formBuilder';
//  <--HOOKS--> //
import { useToast } from '../../../config/hooks/useToast';
import { useToastHook } from '../../../store/slices/toast/toast.hook';
import { useCallApi } from '../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../config/hooks/useCallApi/constants';
//  <--INTERFACE--> //
import {
    AppSectionFormCreateInterface,
    AppSectionFormValues
} from '../../../interface';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../store/hooks';
//  <--SERVICES--> //
import { ApiAppSection, ApiAppSectionId } from '../../../config/service';
//  <--OTHERS--> //
import { lang } from '../../langs';
import { FormCreateAppSection } from './form';
import '../../../scss/global/global.scss';

const AppSectionsCreate = () => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const { ToastConfig } = useToastHook();
    const { Colors, goPreviousPage } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const fieldRef = useRef(null);
    const { id } = useParams();
    const {
        [defaultLang as keyof typeof lang]: {
            pageAppSection: { createTitle, editTitle, errorMessage }
        }
    } = lang;
    const [entityId, setEntityId] = useState<string>('');
    const [entity, setEntity] = useState<AppSectionFormCreateInterface>();
    const [readyForm, setReadyForm] = useState<boolean>(false);
    const [successCleanForm, setSuccessCleanForm] = useState<boolean>(true);

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        if (id) {
            setEntityId(id);
        } else {
            setReadyForm(true);
        }
    }, [id]);

    /**
     * @description CallDataEdit function, call the api to get the data of the entity and set the data in state
     */
    const CallDataEdit = async () => {
        const response = await GetData(
            ApiAppSectionId(entityId),
            HTTP_METHODS.GET
        );
        const { data, status } = response;
        setEntity(data);
        setReadyForm(true);
        if (!status) {
            ToastConfig({
                message: data.message,
                color: Colors.Error
            });
        }
    };

    /**
     * @description this effect check that entityId is not empty and if it changes, if not empty, call the function to CallDataEdit
     */
    useEffect(() => {
        if (entityId !== '') {
            CallDataEdit();
        }
    }, [entityId]);

    /**
     * @description
     * @function OnSubmit is a function that is called when the form is submitted
     * @param values is a object that contains the data of the form
     */
    const OnSubmit = async (values: AppSectionFormCreateInterface) => {
        values.isAside =
            typeof values.isAside !== 'boolean' && values.isAside === 'true';
        values.isHeader =
            typeof values.isHeader !== 'boolean' && values.isHeader === 'true';
        values.isIcon =
            typeof values.isIcon !== 'boolean' && values.isIcon === 'true';
        try {
            setSuccessCleanForm(false);
            const response = id
                ? await GetData(
                      ApiAppSectionId(entityId),
                      HTTP_METHODS.PATCH,
                      values
                  )
                : await GetData(ApiAppSection, HTTP_METHODS.POST, values);
            const { message, data, status } = response;
            if (status) {
                setSuccessCleanForm(true);
                ToastConfig({
                    message,
                    color: Colors.Success
                });
                goPreviousPage();
            } else {
                ToastConfig({
                    message: data.message,
                    color: Colors.Error
                });
            }
        } catch (err) {
            ToastConfig({ message: errorMessage, color: Colors.Error });
        }
    };

    /**
     * @description
     * @function FocusOnInput is a function that is called when the form's cancel button is clicked
     * @param ref is a reference to the form
     */
    const FocusOnInput = (ref: any) => {
        ref.current.focus();
    };

    /**
     * @description
     * @constant FormButtons is a object that contains the buttons of the form
     */
    const FormButtons = {
        ok: {
            controller: OnSubmit
        },
        cancel: {
            controller: FocusOnInput
        }
    };

    return (
        <Container>
            <PageTitle
                title={entity?.name ? editTitle : createTitle}
                icon='settings/propertiesB.svg'
            />
            <Wrapper>
                {readyForm && (
                    <FormBuilder
                        initialValues={
                            entity?.name ? entity : AppSectionFormValues
                        }
                        buttons={FormButtons}
                        lang={defaultLang}
                        formStructure={FormCreateAppSection}
                        focusOn={fieldRef}
                        config={
                            entity?.name
                                ? { noClearButton: true }
                                : { noClearButton: false }
                        }
                        onSuccessCleanForm={successCleanForm}
                    />
                )}
            </Wrapper>
            {LoadingData && <LoaderElement />}
        </Container>
    );
};

export default AppSectionsCreate;
