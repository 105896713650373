import { MaterialInterface } from './material.interface';

export interface MaterialKindInterface {
    id: string;
    name: string;
    description: string;
    color: string;
    codeLer: string;
    state: number;
    createdAt: string;
    updatedAt: string;
    materials?: MaterialInterface[];
}

export interface MaterialKindFormCreateInterface {
    name: string;
    description: string;
    color: string;
    codeLer: string;
}

export const MaterialKindFormValues: MaterialKindFormCreateInterface = {
    name: '',
    description: '',
    color: '',
    codeLer: ''
};
