export const constantsTable = {
    keysFilter: [
        'name',
        'email',
        'rut',
        'address',
        'contactName',
        'contactNumber'
    ]
};
