import {
    AlignValues,
    TextTypesValues,
    HeadersInterface
} from '../interface/table.interface';

export const tableData = {
    regXpage: 10,
    listCountPages: [10, 20, 30, 40, 50],
    emptyData: 'No data to display',
    filesPageTxt: 'Records per page',
    filesPageTxtConnector: 'Of',
    regsPageTxt: 'Records'
};
/**
 * This is simple object passed to render data in table component
 * and render table headers
 */
export const CompanyTable: HeadersInterface[] = [
    {
        dataField: 'name',
        text: 'Name'
    }
    /* {
        dataField: 'email',
        text: 'Email',
        options: {
            strong: false,
            align: AlignValues.center,
            textType: TextTypesValues.lowerCase,
            color: '#4e5fab'
        }
    } */
];

export const RoleTable: HeadersInterface[] = [
    {
        dataField: 'name',
        text: 'Name'
    }
];

export const AppSectionTable: HeadersInterface[] = [
    {
        dataField: 'name',
        text: 'Name'
    },
    {
        dataField: 'path',
        text: 'Path'
    },
    {
        dataField: 'code',
        text: 'Code'
    },
    {
        dataField: 'isAside',
        text: 'Aside'
    },
    {
        dataField: 'isHeader',
        text: 'Header'
    },
    {
        dataField: 'isIcon',
        text: 'Icon'
    },
    {
        dataField: 'icon',
        text: 'IconFile'
    },
    {
        dataField: 'order',
        text: 'Orden'
    }
];

export const MaterialKindTable: HeadersInterface[] = [
    {
        dataField: 'name',
        text: 'Name'
    }
    /* {
        dataField: 'description',
        text: 'Description'
    } */
];

export const MaterialTable: HeadersInterface[] = [
    /* {
        dataField: 'kind.name',
        text: 'Kind'
    }, */
    {
        dataField: 'name',
        text: 'Name'
    }
    /* {
        dataField: 'color',
        text: 'Color'
    }, */
    /* {
        dataField: 'codeLer',
        text: 'Code Ler'
    } */
];

export const ProgramTable: HeadersInterface[] = [
    {
        dataField: 'name',
        text: 'Name'
    },
    {
        dataField: 'description',
        text: 'Description'
    }
];

export const PropertyTable: HeadersInterface[] = [
    {
        dataField: 'name',
        text: 'Name'
    }
    /* {
        dataField: 'address',
        text: 'Adrress'
    },
    {
        dataField: 'rut',
        text: 'Rut'
    },
    {
        dataField: 'paCode',
        text: 'PaCode'
    },
    {
        dataField: 'csCode',
        text: 'CsCode'
    } */
];

export const ClientsTable: HeadersInterface[] = [
    {
        dataField: 'name',
        text: 'Name'
    }
    /* {
        dataField: 'address',
        text: 'Adrress'
    },
    {
        dataField: 'rut',
        text: 'Rut'
    },
    {
        dataField: 'paCode',
        text: 'PaCode'
    },
    {
        dataField: 'csCode',
        text: 'CsCode'
    } */
];

export const PropertyUnitTable: HeadersInterface[] = [
    {
        dataField: 'name',
        text: 'name'
    }
];

export const GestorUserTable: HeadersInterface[] = [
    {
        dataField: 'name',
        text: 'name'
    },
    {
        dataField: 'lastName',
        text: 'Last name'
    }
    /* {
        dataField: 'email',
        text: 'Email'
    },
    {
        dataField: 'rut',
        text: 'Rut'
    },
    {
        dataField: 'phone',
        text: 'Phone'
    } */
];

export const UsersTable: HeadersInterface[] = [
    {
        dataField: 'name',
        text: 'Name'
    },
    {
        dataField: 'lastName',
        text: 'Last name'
    } /* ,
    {
        dataField: 'email',
        text: 'Email'
    },
    {
        dataField: 'rut',
        text: 'Rut'
    },
    {
        dataField: 'phone',
        text: 'Phone'
    } */
];

export const TruckTable: HeadersInterface[] = [
    /* {
        dataField: 'gestor.name',
        text: 'Gestor'
    }, */
    /* {
        dataField: 'brand',
        text: 'Brand'
    }, */
    {
        dataField: 'patent',
        text: 'Patent'
    }
    /* {
        dataField: 'capacity',
        text: 'Capacity'
    } */
    //  {
    //      dataField: 'state',
    //      text: 'State',
    //      renderFunction: GetStatus
    //  },
    //  {
    //      dataField: 'createdAt',
    //      text: 'Created At',
    //      renderFunction: DateToString
    //  }
];

export const InvitationsTable: HeadersInterface[] = [
    {
        dataField: 'email',
        text: 'Email',
        options: {
            strong: false,
            align: AlignValues.center,
            textType: TextTypesValues.lowerCase,
            color: '#4e5fab'
        }
    } /* ,
    {
        dataField: 'id',
        text: 'Id'
    }  */
];

export const PropertiesUserTable: HeadersInterface[] = [
    {
        dataField: 'name',
        text: 'Name'
    }
];

export const TypologyTable: HeadersInterface[] = [
    {
        dataField: 'name',
        text: 'name'
    }
];

export const FontTable: HeadersInterface[] = [
    {
        dataField: 'name',
        text: 'name'
    }
];

export const RequirementsTable: HeadersInterface[] = [
    {
        dataField: 'name',
        text: 'name'
    }
];
