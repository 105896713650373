import { useState, useEffect } from 'react';
import './subnavbar.scss';
import Menu from '../rightsection/menu/index';
import SettingsBar from '../../../settingsBar/settingsBar';
import { AppSectionInterface } from '../../../../interface/app-section.interface';
import { useAppSelector } from '../../../../store/hooks';
import useWindowDimensions from '../../../../config/hooks/useWindowDimentions';
import { GetStorage } from '../../../../config/utils/Storage';
import { Link, useLocation } from 'react-router-dom';
import { Tooltip } from 'antd';

interface Props {
    type?: string;
    title?: string;
    icon?: string;
    kind?: string;
    option?: string;
}

const deffaultRole =
    parseInt(GetStorage(process.env.REACT_APP_PUBLIC_DEFFAULT_ROLE)) || 0;

const SubNavBar = ({ type, title, icon, kind, option }: Props) => {
    const location = useLocation();
    const { userData } = useAppSelector(state => state.users);
    const { roles } = userData;
    const { appSections } = roles[deffaultRole];
    const [path, setPath] = useState<string>('/');
    const [sections, setSections] = useState<AppSectionInterface[]>([]);
    const { width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [menuOpen, setMenuOpen] = useState(false);

    const linkClassName = item => {
        if (path === item.path) {
            return 'subnavbar__content__item__link active';
        } else {
            return 'subnavbar__content__item__link';
        }
    };

    useEffect(() => {
        setSections(appSections);
    }, [appSections]);

    useEffect(() => {
        const pathy = location.pathname.substring(7, location.pathname.length);
        setPath(pathy);
    }, [location]);

    useEffect(() => {
        setIsMobile(width <= 768);
        setMenuOpen(false);
    }, [width]);

    return (
        <div className='subnavbar'>
            {menuOpen ? (
                <div className='subnavbar__menu'>
                    <Menu />
                </div>
            ) : type === 'settings' ? (
                <div className='subnavbar__menu sett'>
                    <SettingsBar
                        title={title ?? ''}
                        icon={icon ?? ' '}
                        kind={kind}
                        option={option}
                    />
                </div>
            ) : (
                <div className='subnavbar__content'>
                    {sections.map(item => {
                        return (
                            item.isHeader && (
                                <Tooltip
                                    title={
                                        <span className='subnavbar__content__tooltip'>
                                            {item.path === 'control-panel'
                                                ? 'PRÓXIMAMENTE'
                                                : item.name}
                                        </span>
                                    }
                                    key={item.id}
                                    color='#fff'
                                >
                                    <div className='subnavbar__content__item'>
                                        <Link
                                            className={`${linkClassName(
                                                item
                                            )}  bigtitle`}
                                            to={`../${item.path}`}
                                        >
                                            {item.name}
                                        </Link>
                                    </div>
                                </Tooltip>
                            )
                        );
                    })}
                </div>
            )}
            {isMobile && (
                <button
                    className='menu-img'
                    onClick={() => setMenuOpen(!menuOpen)}
                >
                    <img className='menu-img' src='/svg-icons/MenuIcon.svg' />
                </button>
            )}
        </div>
    );
};

export default SubNavBar;
