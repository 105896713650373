import './eventsCard.scss';

interface Props {
    name: string;
    icon: string;
}

const EventsCard = ({ name, icon }: Props) => {
    return (
        <div className='eventscard'>
            <div className='eventscard__title disabled'>
                {name}
                <span>
                    <img className='eventscard__noticon' src={icon}></img>
                </span>
            </div>
            <div className='eventscard__alerts-grid-container'>
                {/* <div className='eventscard__alerts-grid-container__item'>
                    <AlertCard
                        type='alertcard__red'
                        action='Enter data for SINADER Form'
                        schedule=''
                        property=''
                        date='15/04/2023'
                        logo='/svg-icons/ClockIcon.svg'
                    ></AlertCard>
                </div>
                <div className='eventscard__alerts-grid-container__item'>
                    <AlertCard
                        type='alertcard__yellow'
                        action='Recolección de Residuos Cartón'
                        schedule='pm'
                        property='Kyklos'
                        date='25/04/2023'
                        logo=''
                    ></AlertCard>
                </div>
                <div className='eventscard__alerts-grid-container__item'>
                    <AlertCard
                        type='alertcard__green'
                        action='Recolección de Residuos Papel'
                        schedule='am'
                        property='Inm.'
                        date='21/04/2023'
                        logo=''
                    ></AlertCard>
                </div>
                <div className='eventscard__alerts-grid-container__item'>
                    <AlertCard
                        type='alertcard__yellow'
                        action='Recolección de Residuos Cartón'
                        schedule='pm'
                        property='Kyklos'
                        date='25/04/2023'
                        logo=''
                    ></AlertCard>
                </div>
                <div className='eventscard__alerts-grid-container__item'>
                    <AlertCard
                        type='alertcard__green'
                        action='Recolección de Residuos Papel'
                        schedule='am'
                        property='Inm.'
                        date='21/04/2023'
                        logo=''
                    ></AlertCard>
                </div> */}
            </div>
        </div>
    );
};

export default EventsCard;
