import './help.scss';
import SubNavBar from '../../components/layout/main/subnavbar/subnavbar';
import type { CollapseProps } from 'antd';
import { Collapse, ConfigProvider, Input } from 'antd';

const { TextArea } = Input;

const Help = () => {
    const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: '¿Cómo administro mis inmuebles?',
            children: <p className='help__section__answer subtitle'>{text}</p>
        },
        {
            key: '2',
            label: '¿Cómo administro mis inmuebles?',
            children: <p className='help__section__answer subtitle'>{text}</p>
        },
        {
            key: '3',
            label: '¿Cómo administro mis inmuebles?',
            children: <p className='help__section__answer subtitle'>{text}</p>
        },
        {
            key: '4',
            label: '¿Cómo administro mis inmuebles?',
            children: <p className='help__section__answer subtitle'>{text}</p>
        },
        {
            key: '5',
            label: '¿Cómo administro mis inmuebles?',
            children: <p className='help__section__answer subtitle'>{text}</p>
        },
        {
            key: '6',
            label: '¿Cómo administro mis inmuebles?',
            children: <p className='help__section__answer subtitle'>{text}</p>
        },
        {
            key: '7',
            label: '¿Cómo administro mis inmuebles?',
            children: <p className='help__section__answer subtitle'>{text}</p>
        }
    ];

    return (
        <>
            <SubNavBar
                type='settings'
                title='Información Pryma'
                icon='HelpIconB.svg'
            ></SubNavBar>
            <div className='help'>
                <div className='help__section'>
                    <div className='help__section__title title'>
                        Material de apoyo Pryma
                    </div>
                    <div className='help__section__grid-container'>
                        <div
                            className='help__section__grid-container__item'
                            style={{
                                backgroundImage:
                                    'url(/svg-icons/help/pryma.svg)'
                            }}
                        >
                            <div className='help__section__grid-container__item__title subtitle'>
                                Conoce la plataforma Pryma aquí
                            </div>
                        </div>
                        <div
                            className='help__section__grid-container__item'
                            style={{
                                backgroundImage: 'url(/svg-icons/help/blue.svg)'
                            }}
                        >
                            <div className='help__section__grid-container__item__title subtitle'>
                                Cómo y qué reciclar
                            </div>
                        </div>
                        <div
                            className='help__section__grid-container__item'
                            style={{
                                backgroundImage:
                                    'url(/svg-icons/help/yellow.svg)'
                            }}
                        >
                            <div className='help__section__grid-container__item__title subtitle'>
                                Nuevas herramientas
                            </div>
                        </div>
                        <div className='help__section__grid-container__item'></div>
                        <div className='help__section__grid-container__item'></div>
                        <div className='help__section__grid-container__item'></div>
                    </div>
                </div>
                <div className='help__section'>
                    <div className='help__section__title title'>
                        Preguntas frecuentes
                    </div>
                    <div className='help__section__FAQ'>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Collapse: {
                                        colorLink: '#a2a2a3',
                                        colorLinkActive: '#a2a2a3',
                                        colorPrimary: '#a2a2a3',
                                        colorPrimaryHover: '#a2a2a3',
                                        colorBgContainer: '#f3f2f5',
                                        fontFamily: 'Roboto',
                                        colorIcon: '#a2a2a3',
                                        colorText: '#C4C3C5',
                                        colorTextHeading: '#a2a2a3',
                                        colorTextPlaceholder: '#a2a2a3'
                                    }
                                }
                            }}
                        >
                            <Collapse
                                items={items}
                                defaultActiveKey={[
                                    '0'
                                ]} /* onChange={onChange} */
                            />
                        </ConfigProvider>
                    </div>
                </div>
                <div className='help__section'>
                    <div className='help__section__title title'>
                        ¿Tienes otra pregunta? Envíanos un mensaje
                    </div>
                    <TextArea
                        className='help__section__text-area subtitle'
                        style={{ height: 120, resize: 'none' }}
                        placeholder='Máximo 200 caracteres'
                        maxLength={200}
                    />
                    <button
                        className='button button-body--help subtitle'
                        /* onClick={UploadDataToServer} */
                    >
                        Enviar
                    </button>
                </div>
            </div>
        </>
    );
};

export default Help;
