import { useEffect, useState } from 'react';
import {
    TableInterface,
    EditableTypesValues,
    TableEditableTypesValues,
    IsEditingInterface,
    DataRowEditedInterface
} from './interface/table.interface';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import './table.scss';
import { Tooltip } from 'antd';

const TableEditable = ({
    data,
    headers,
    actionsButtons,
    type,
    highlight,
    saveData,
    onRefresh,
    onSaveChange
}: TableInterface) => {
    useEffect(() => {
        if (data && data.length > 0) {
            data.sort((a, b) => {
                const dateA = parseInt(a.date);
                const dateB = parseInt(b.date);

                if (dateA < dateB) {
                    return -1;
                } else if (dateA > dateB) {
                    return 1;
                } else {
                    return 0;
                }
            });
            const regs: object[] = [];
            for (const el of data) {
                el.isEdit = false;
                el.rowEdited = false;
                el.edited_truckId = false;
                el.edited_finalDUId = false;
                el.edited_managementId = false;
                el.edited_treatmentId = false;
                el.weight = parseFloat(el.weight).toLocaleString('es-CL', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                });
                regs.push(el);
            }
            setRegisters(regs);
        }
    }, [data]);

    useEffect(() => {
        if (highlight?.show) {
            let changeData = registers;
            registers.map((element, index) => {
                if (
                    (!element.rowEdited && element.truckId === null) ||
                    element.finalDUId === null ||
                    element.managementId === null ||
                    element.treatmentId === null
                ) {
                    element.rowEdited = true;
                    if (
                        element.truck_autofill === 0 &&
                        element.truckId === null
                    ) {
                        element.fillpending = true;
                    } else {
                        element.fillpending = false;
                    }
                    if (
                        element.fdu_autofill === 0 &&
                        element.finalDUId === null
                    ) {
                        element.fillpending = true;
                    } else {
                        element.fillpending = false;
                    }
                    if (
                        element.management_autofill === 0 &&
                        element.managementId === null
                    ) {
                        element.fillpending = true;
                    } else {
                        element.fillpending = false;
                    }
                    if (
                        element.treatment_autofill === 0 &&
                        element.treatmentId === null
                    ) {
                        element.fillpending = true;
                    } else {
                        element.fillpending = false;
                    }
                    changeData.splice(index, 1);
                    changeData.splice(index, 0, element);
                    changeData = [...changeData, element];
                    changeData.splice(changeData.length - 1, 1);
                    setRegisters(changeData);
                }

                if (element.truckId === null) {
                    element.truckId = element.dtm_truckId;
                    element.truckPatent = element.dtm_truckPatent;
                }

                if (element.finalDUId === null) {
                    element.finalDUId = element.mdt_finalDUId;
                    element.finalDUName = element.mdt_finalDUName;
                }

                if (element.managementId === null) {
                    element.managementId = element.mdt_management;
                    element.managementName = element.mdt_managementName;
                }

                if (element.treatmentId === null) {
                    element.treatmentId = element.mdt_treatmentId;
                    element.treatmentName = element.mdt_treatmentName;
                }

                if (
                    element.truck_autofill === null &&
                    element.truckId !== null
                ) {
                    element.truck_autofill = true;
                }
                if (
                    element.fdu_autofill === null &&
                    element.finalDUId !== null
                ) {
                    element.fdu_autofill = true;
                }
                if (
                    element.management_autofill === null &&
                    element.managementId !== null
                ) {
                    element.management_autofill = true;
                }
                if (
                    element.treatment_autofill === null &&
                    element.treatmentId !== null
                ) {
                    element.treatment_autofill = true;
                }
                return false;
            });
        }
    }, [highlight]);

    const [registers, setRegisters] = useState<any>([]);
    const [editing, setEditing] = useState<IsEditingInterface>({
        editing: false,
        element: null
    });
    const [editedData, setEditedData] = useState<DataRowEditedInterface[]>([]); // setEditedData

    useEffect(() => {
        setEditedData([]);
        setEditing({
            editing: false,
            element: null
        });
    }, [onRefresh]);

    const OnEditChangeValue = (event, element, index, header, idx = 0) => {
        const value = event.target.value;
        const indexSelected = event.nativeEvent.target.selectedIndex;
        const valueName = event.nativeEvent.target[indexSelected].text;
        const dataEdited = editedData;
        const obj = {
            id: value,
            text: valueName
        };
        const exist = dataEdited.find(element => element.id === obj.id);
        if (
            header.dependsThisListDataKey &&
            header.dependsThisListDataKey !== ''
        ) {
            if (
                headers[header.dependsThisListDataIndexColumn].editableListData
            ) {
                if (headers[idx].editableListData !== undefined) {
                    const sel = headers[idx]?.editableListData?.find(
                        (item: any) => item.id === obj.id
                    );
                    const newList = sel
                        ? sel[header.dependsThisListDataKey]
                        : [];
                    headers[
                        header.dependsThisListDataIndexColumn
                    ].editableListData = newList;
                }
            }
        }
        if (!exist) {
            ChangeStateData(
                registers,
                header.dataField,
                value,
                index,
                element,
                header.relatedDatafield,
                valueName,
                header.fieldAutofill
            );
        }
    };

    const SetEditRow = (item, index) => {
        const idx = 6;
        const header = headers[idx];
        const id = item.managementId;
        if (
            header.dependsThisListDataKey &&
            header.dependsThisListDataKey !== ''
        ) {
            if (
                header.dependsThisListDataIndexColumn &&
                headers[header.dependsThisListDataIndexColumn].editableListData
            ) {
                if (headers[idx].editableListData !== undefined) {
                    const sel = headers[idx]?.editableListData?.find(
                        (item: any) => item.id === id
                    );
                    const newList = sel
                        ? sel[header.dependsThisListDataKey]
                        : [];
                    headers[
                        header.dependsThisListDataIndexColumn
                    ].editableListData = newList;
                }
            }
        }
        setEditing({ ...editing, editing: true, element: index });
        ChangeStateData(registers, 'isEdit', true, index, item);
    };

    const SaveEditRow = (element, index) => {
        const array = editedData;
        if (!array.includes(element.id)) {
            array.push(element);
        }
        setEditedData(array);

        let changeData = registers;
        element.isEdit = false;
        element.autofilled = 0;
        element.rowEdited = true;
        changeData.splice(index, 1);
        changeData.splice(index, 0, element);
        changeData = [...changeData, element];
        changeData.splice(changeData.length - 1, 1);
        setRegisters(changeData);
        setEditing({ ...editing, editing: false, element: index });
    };

    const ChangeStateData = (
        originData,
        keyMutate,
        value,
        index,
        row,
        relatedDatafield = null,
        valueName = null,
        fieldAutofill = null
    ) => {
        let changeData = originData;
        const selected = changeData.find(x => x.id === row.id);
        selected[keyMutate] = value;
        if (relatedDatafield !== null && valueName !== null) {
            selected[relatedDatafield] = value;
            selected[keyMutate] = valueName;
            selected[`edited_${relatedDatafield}`] = true;
            if (fieldAutofill) {
                selected[fieldAutofill] = false;
            }
        }
        changeData.splice(index, 1);
        changeData.splice(index, 0, selected);
        changeData = [...changeData, selected];
        changeData.splice(changeData.length - 1, 1);
        setRegisters(changeData);
    };

    useEffect(() => {
        if (onSaveChange) {
            saveData(registers);
        }
    }, [onSaveChange]);

    return (
        <div className='table'>
            <table className='table__editable'>
                <thead className='table__editable__thead'>
                    <tr className='table__editable__thead__tr'>
                        {headers.map(item => (
                            <th
                                key={item.text}
                                className={`table__editable__thead__tr__th ${item.text} subtitle`}
                            >
                                {item.options ? (
                                    <span
                                        className='table__editable__thead__tr__th__text'
                                        style={{
                                            width: '100%',
                                            display: 'block',
                                            textAlign: item.options.align,
                                            fontWeight: item.options.strong
                                                ? 'bold'
                                                : 'normal'
                                        }}
                                    >
                                        {item.text}
                                    </span>
                                ) : (
                                    <span className='table__editable__thead__tr__th__text'>
                                        {item.text}
                                    </span>
                                )}
                            </th>
                        ))}
                        <th className='table__editable__thead__tr__th editColumn'>
                            {' '}
                            &nbsp;{' '}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {registers.map((item: any, index) => {
                        return (
                            <tr
                                key={`table_EDITABLE_BODY_${index}`}
                                className={`table__editable__tbody__tr ${
                                    highlight !== null &&
                                    item[highlight?.field] === 1 &&
                                    highlight.show
                                        ? highlight.addClass
                                        : ''
                                }`}
                            >
                                {headers.map((header, idx) => {
                                    const nameCol =
                                        header.dataField === null
                                            ? 'id'
                                            : header.dataField;
                                    let addedclases = '';

                                    switch (header.fieldAutofill) {
                                        case 'truck_autofill':
                                            if (item.truck_autofill === 1) {
                                                addedclases = '';
                                                addedclases = ' auto_ses_ant';
                                            } else if (
                                                item[nameCol] !== null &&
                                                !item.isEdit
                                            ) {
                                                addedclases = '';
                                                addedclases = ' man_ses_ant';
                                            }
                                            break;
                                        case 'fdu_autofill':
                                            if (item.fdu_autofill === 1) {
                                                addedclases = '';
                                                addedclases = ' auto_ses_ant';
                                            } else if (
                                                item[nameCol] !== null &&
                                                !item.isEdit
                                            ) {
                                                addedclases = '';
                                                addedclases = ' man_ses_ant';
                                            }
                                            break;
                                        case 'management_autofill':
                                            if (
                                                item.management_autofill === 1
                                            ) {
                                                addedclases = '';
                                                addedclases = ' auto_ses_ant';
                                            } else if (
                                                item[nameCol] !== null &&
                                                !item.isEdit
                                            ) {
                                                addedclases = '';
                                                addedclases = ' man_ses_ant';
                                            }
                                            break;
                                        case 'treatment_autofill':
                                            if (item.treatment_autofill === 1) {
                                                addedclases = '';
                                                addedclases = ' auto_ses_ant';
                                            } else if (
                                                item[nameCol] !== null &&
                                                !item.isEdit
                                            ) {
                                                addedclases = '';
                                                addedclases = ' man_ses_ant';
                                            }
                                            break;
                                    }

                                    const exist = editedData.find(
                                        elm => elm.id === item.id
                                    );
                                    if (
                                        exist &&
                                        header.relatedDatafield &&
                                        item[header.relatedDatafield] &&
                                        item[
                                            `edited_${header.relatedDatafield}`
                                        ]
                                    ) {
                                        addedclases = '';
                                        addedclases = ' man_ses_act';
                                    }

                                    if (
                                        header.fieldAutofill &&
                                        header.dataFieldIsAutoFill
                                    ) {
                                        switch (header.fieldAutofill) {
                                            case 'truck_autofill':
                                                if (
                                                    item.truck_autofill &&
                                                    item.rowEdited
                                                ) {
                                                    addedclases = '';
                                                    addedclases =
                                                        ' auto_ses_act';
                                                }
                                                break;
                                            case 'fdu_autofill':
                                                if (
                                                    item.fdu_autofill &&
                                                    item.rowEdited
                                                ) {
                                                    addedclases = '';
                                                    addedclases =
                                                        ' auto_ses_act';
                                                }
                                                break;
                                            case 'management_autofill':
                                                if (
                                                    item.management_autofill &&
                                                    item.rowEdited
                                                ) {
                                                    addedclases = '';
                                                    addedclases =
                                                        ' auto_ses_act';
                                                }
                                                break;
                                            case 'treatment_autofill':
                                                if (
                                                    item.treatment_autofill &&
                                                    item.rowEdited
                                                ) {
                                                    addedclases = '';
                                                    addedclases =
                                                        ' auto_ses_act';
                                                }
                                                break;
                                        }
                                    }

                                    return (
                                        <td
                                            key={`table_EDITABLE_BODY_${nameCol}_${idx}`}
                                            className={
                                                header.isEditable
                                                    ? `table__editable__tbody__tr__td ${addedclases}`
                                                    : `table__editable__tbody__tr__td`
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: 'flex'
                                                }}
                                            >
                                                {item.isEdit ? (
                                                    !header.isEditable ? (
                                                        <Tooltip
                                                            title={
                                                                <span className='main_menu__grid-container__tooltip'>
                                                                    {
                                                                        item[
                                                                            nameCol
                                                                        ]
                                                                    }
                                                                </span>
                                                            }
                                                            key={item.id}
                                                            color='#fff'
                                                        >
                                                            <span
                                                                className={
                                                                    highlight?.show &&
                                                                    header.isEditable &&
                                                                    item[
                                                                        highlight
                                                                            .field
                                                                    ] === 1
                                                                        ? 'table__editable__tbody__tr__td__span isEditable subtitle'
                                                                        : 'table__editable__tbody__tr__td__span subtitle'
                                                                }
                                                                style={{
                                                                    width: '100%',
                                                                    display:
                                                                        'block'
                                                                }}
                                                            >
                                                                {' '}
                                                                {item[nameCol]}
                                                            </span>
                                                        </Tooltip>
                                                    ) : header.editableType ===
                                                      EditableTypesValues.text ? (
                                                        <input
                                                            className='table__editable__tbody__tr__td__input'
                                                            type='text'
                                                            onChange={event => {
                                                                OnEditChangeValue(
                                                                    event,
                                                                    item,
                                                                    index,
                                                                    header
                                                                );
                                                            }}
                                                            value={
                                                                item[nameCol]
                                                            }
                                                        />
                                                    ) : (
                                                        <select
                                                            className='table__editable__tbody__tr__td__lst'
                                                            onChange={event => {
                                                                OnEditChangeValue(
                                                                    event,
                                                                    item,
                                                                    index,
                                                                    header,
                                                                    idx
                                                                );
                                                            }}
                                                            defaultValue={
                                                                header.relatedDatafield
                                                                    ? item[
                                                                          header
                                                                              .relatedDatafield
                                                                      ]
                                                                    : ''
                                                            }
                                                        >
                                                            <option value=''>
                                                                Seleccionar
                                                            </option>
                                                            {header.editableListData?.map(
                                                                (
                                                                    itemData: any
                                                                ) => {
                                                                    const textoLista =
                                                                        header.editableListDataKey
                                                                            ? itemData[
                                                                                  header
                                                                                      .editableListDataKey
                                                                              ]
                                                                            : itemData.name;
                                                                    return (
                                                                        <option
                                                                            key={`opt_${itemData.id}`}
                                                                            value={
                                                                                itemData.id
                                                                            }
                                                                        >
                                                                            {
                                                                                textoLista
                                                                            }
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                        </select>
                                                    )
                                                ) : item[nameCol]?.length >
                                                  10 ? (
                                                    <Tooltip
                                                        title={
                                                            <span className='main_menu__grid-container__tooltip'>
                                                                {/* {nameCol ===
                                                                'date'
                                                                    ? DateToString(
                                                                          item[
                                                                              nameCol
                                                                          ]
                                                                      )
                                                                    : item[
                                                                          nameCol
                                                                      ]} */}
                                                                {item[nameCol]}
                                                            </span>
                                                        }
                                                        key={item.id}
                                                        color='#fff'
                                                    >
                                                        <span
                                                            className={
                                                                highlight?.show &&
                                                                header.isEditable &&
                                                                item[
                                                                    highlight
                                                                        .field
                                                                ] === 1
                                                                    ? 'table__editable__tbody__tr__td__span isEditable  subtitle'
                                                                    : 'table__editable__tbody__tr__td__span  subtitle'
                                                            }
                                                            style={{
                                                                width: '100%',
                                                                display: 'block'
                                                            }}
                                                        >
                                                            {/* {nameCol === 'date'
                                                                ? DateToString(
                                                                      item[
                                                                          nameCol
                                                                      ]
                                                                  )
                                                                : item[nameCol]} */}
                                                            {item[nameCol]}
                                                        </span>
                                                    </Tooltip>
                                                ) : (
                                                    <span
                                                        className={
                                                            highlight?.show &&
                                                            header.isEditable &&
                                                            item[
                                                                highlight.field
                                                            ] === 1
                                                                ? 'table__editable__tbody__tr__td__span isEditable  subtitle'
                                                                : 'table__editable__tbody__tr__td__span  subtitle'
                                                        }
                                                        style={{
                                                            width: '100%',
                                                            display: 'block'
                                                        }}
                                                    >
                                                        {item[nameCol]}
                                                    </span>
                                                )}
                                            </div>
                                        </td>
                                    );
                                })}
                                <td
                                    className='table__editable__tbody__tr__td'
                                    style={{
                                        maxWidth: '100px',
                                        borderBottom: 'unset'
                                    }}
                                >
                                    {type === TableEditableTypesValues.inRow &&
                                        (editing.editing &&
                                        index === editing.element ? (
                                            <SaveIcon
                                                titleAccess='Grabar'
                                                className='table__editable__tbody__tr__td__icon'
                                                onClick={() => {
                                                    SaveEditRow(item, index);
                                                }}
                                            />
                                        ) : (
                                            <EditIcon
                                                titleAccess='Editar'
                                                className='table__editable__tbody__tr__td__icon'
                                                onClick={() => {
                                                    SetEditRow(item, index);
                                                }}
                                            />
                                        ))}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default TableEditable;
