import { useState } from 'react';
import { Globals } from './Constants';

interface FilterDetailInterface {
    keys: string[];
    valuesProperty: string[];
    valuesPropertyUnits?: string[];
    valuesDate?: string[];
}

export interface FilterInterface {
    tableName: string;
    filters: FilterDetailInterface;
    dateFilterRange?: string[];
    dbName: string;
}

const indexedDB =
    window.indexedDB ||
    window.mozIndexedDB ||
    window.webkitIndexedDB ||
    window.msIndexedDB;

export const DeleteDB = async ({ dbName }) => {
    indexedDB.deleteDatabase(dbName);
};

export function IDB(Dbs) {
    const [status, setStatus] = useState({
        LoadingData: false,
        ObtainedData: []
    });

    const DeleteCollection = DeleteDB;

    const CreateCollection = ({ id, tableName, indexes, dbName:db }) => {
        if (indexedDB) {
            Dbs.forEach(dbName => {
                const request = indexedDB.open(dbName, 2);
                request.onupgradeneeded = () => {
                    const db = request.result;
                    if (!db.objectStoreNames.contains(tableName)) {
                        const objectStore = db.createObjectStore(tableName, {
                            keyPath: id
                        });
                        const keyStructured = indexes;
    
                        for (const element of keyStructured) {
                            const kpth = element.split(',');
                            if (kpth.length <= 1) {
                                objectStore.createIndex(element, element, {
                                    unique: false
                                });
                            } else {
                                objectStore.createIndex(element, kpth, {
                                    unique: false
                                });
                            }
                        }
                    }
                }; 
            }); 
        }
    };

    const AddDataToDb = async ({ data, tableName }, callback, dbName) => {
        const dbPromise = indexedDB.open(dbName, 2);
        dbPromise.onsuccess = () => {
            const db = dbPromise.result;
            const objectStore = db
                .transaction(tableName, 'readonly')
                .objectStore(tableName);
            const existingDataRequest = objectStore.getAll();

            existingDataRequest.onsuccess = () => {
                interface OBSinterface extends IDBObjectStore {
                    onsuccess?: () => void;
                }
                const customerObjectStore: OBSinterface = db
                    .transaction(tableName, 'readwrite')
                    .objectStore(tableName);

                data.forEach(result => {
                    result.total = result.total / 1000;
                    customerObjectStore.add(result);
                });
                customerObjectStore.onsuccess = () => {
                    db.close();
                };
                callback(dbName);
            };
        };
    };

    const GetAllDataDb = async ({
        tableName,
        dateFilterRange,
        dbName
    }: FilterInterface) => {
        // Dbs.forEach(dbName => {
            setStatus({ ...status, LoadingData: true });
            const dbPromise = indexedDB.open(dbName, 2);
            dbPromise.onsuccess = () => {
                const db = dbPromise.result;
                const customerObjectStore = db
                    .transaction([tableName], 'readonly')
                    .objectStore(tableName);
                const registers = customerObjectStore.getAll();
                registers.onsuccess = () => {
                    const finalRes = FilterByDate(
                        registers.result,
                        Globals.IDBDefaultDateFilterKey,
                        dateFilterRange
                    );
                    setStatus({
                        LoadingData: false,
                        ObtainedData: finalRes
                    });
                };
            };
        // });
    };

    function GetDataDb({
        tableName,
        filters: { keys, valuesProperty, valuesPropertyUnits, valuesDate },
        dateFilterRange
    }: FilterInterface) {
        setStatus({ ...status, LoadingData: true });
        Dbs.forEach(dbName => {
            const dbPromise = indexedDB.open(dbName, 2);
            let dates;
            if (!valuesDate) {
                dates = [];
            } else {
                dates = valuesDate;
            }
            dbPromise.onsuccess = () => {
                const db = dbPromise.result;
                const customerObjectStore = db
                    .transaction([tableName], 'readonly')
                    .objectStore(tableName);
                let newValues;
                let newValuesUnits;
                if (keys.length >= 2) {
                    newValues = [...valuesProperty, ...dates];
                    if (valuesPropertyUnits) {
                        newValuesUnits = [
                            ...valuesProperty,
                            ...valuesPropertyUnits,
                            ...dates
                        ];
                    }
                } else {
                    newValues = valuesProperty;
                    newValuesUnits = valuesPropertyUnits;
                }
                if (keys && keys.length === 0) {
                    return;
                }
                const registers = customerObjectStore.getAll();
                registers.onsuccess = () => {
                    const filteredArrayProp = registers.result.filter(function (
                        elem
                    ) {
                        return newValues.indexOf(elem.propertyId) > -1;
                    });
                    const filteredArrayUnits = registers.result.filter(function (
                        elem
                    ) {
                        return newValuesUnits.indexOf(elem.propertyUnit) > -1;
                    });
                    const finalRes = FilterByDate(
                        filteredArrayProp.concat(filteredArrayUnits),
                        Globals.IDBDefaultDateFilterKey,
                        dateFilterRange
                    );
                    setStatus({
                        LoadingData: false,
                        ObtainedData: finalRes.length > 0 ? finalRes : []
                    });
                };
            }; 
        });
    }

    return {
        DeleteCollection,
        CreateCollection,
        AddDataToDb,
        GetAllDataDb,
        GetDataDb,
        ...status
    };
}

const FilterByDate = (data, key, values) => {
    let dataFilter;
    if (values && values.length >= 2) {
        dataFilter = values;
    } else {
        dataFilter = Globals.IDBDefaultDateFilterRange;
    }
    const filter = data.filter(item => {
        return (
            parseInt(item[key]) >= parseInt(dataFilter[0]) &&
            parseInt(item[key]) <= parseInt(dataFilter[1])
        );
    });
    return filter;
};
