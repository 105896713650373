import { FormBuilderFields } from '../../../../../components/formBuilder/form-builder.interface';

export const FormCreateFont: FormBuilderFields[] = [
    {
        label: 'Nombre',
        fieldNameId: 'name',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el nombre',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 200,
                state: true,
                message: 'Máximo 200 caracteres'
            }
        }
    },
    {
        label: 'Tipo',
        fieldNameId: 'type',
        fieldType: 'select',
        elements: [
            { id: 'Form', name: 'Form' },
            { id: 'Intell', name: 'Intell' },
            { id: 'Gsheet', name: 'Gsheet' }
        ],
        fieldPlaceholder: 'Ingrese el tipo',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Identificador',
        fieldNameId: 'identifier',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el identificador',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 200,
                state: true,
                message: 'Máximo 200 caracteres'
            }
        }
    },
    {
        label: 'Columnas',
        fieldNameId: 'columns',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese las columnas',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: false,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Tipología',
        fieldNameId: 'dataTypology',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione la tipología',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Inmueble',
        fieldNameId: 'property',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione un inmueble',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: false,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Unidad',
        fieldNameId: 'propertyUnit',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione una unidad',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: false,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Material',
        fieldNameId: 'material',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione un material',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: false,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Gestor',
        fieldNameId: 'gestor',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione un gestor',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: false,
                message: 'Este campo es requerido'
            }
        }
    }
];
