import './notificationsCard.scss';
import EventsCard from './eventsCard/eventsCard';
import { lang } from '../../langs';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../store/hooks';
import { Tooltip } from 'antd';

const NotificationsCard = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            controlPanelComponents: {
                notificationsCard: {
                    title,
                    lateEventsTitle,
                    todayEventsTitle,
                    futureEventsTitle
                }
            }
        }
    } = lang;
    return (
        <div className='notifications'>
            <Tooltip
                title={
                    <span className='main_menu__grid-container__tooltip'>
                        PRÓXIMAMENTE
                    </span>
                }
                color='#fff'
            >
                <div className='notifications__titulo-card disabled'>
                    {title}
                </div>
                <div className='notifications__grid-container'>
                    <div className='notifications__grid-container__item'>
                        <EventsCard
                            name={lateEventsTitle}
                            icon='/svg-icons/NotificationsAlertIcon.svg'
                        />
                    </div>
                    <div className='notifications__grid-container__item'>
                        <EventsCard name={todayEventsTitle} icon='' />
                    </div>
                    <div className='notifications__grid-container__item'>
                        <EventsCard name={futureEventsTitle} icon='' />
                    </div>
                </div>
            </Tooltip>
        </div>
    );
};

export default NotificationsCard;
