import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
//  <--HOOKS--> //
import { useToast } from '../../../../config/hooks/useToast';
import { useAlert } from '../../../../config/hooks/useAlert';
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
//  <--COMPONENTS--> //
import TableComponent from '../../../../components/table';
import { PropertyUnitTable } from '../../../../components/table/data/columns_table';
/* import ButtonBody from '../../../../components/button/button'; */
//  <--SERVICES--> //
import { ApiPropertyId } from '../../../../config/service';
//  <--INTERFACE--> //
import { PropertyUnitInterface } from '../../../../interface';
import { constantsTable } from './constants';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';

interface Props {
    refresh?: boolean;
}

const PropertyUnitTableComponent = ({ refresh }: Props) => {
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { id } = useParams();
    const defaultLang: string = preferences.lang;
    const { AlertElement, alertManagerRef } = useAlert({ defaultLang });
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const { keysFilter } = constantsTable;
    const [dataList, setDataList] = useState<PropertyUnitInterface[]>([]);

    /**
     * @description
     * @function CallData is a function that returns a promise with the Property list
     */
    const CallData = async () => {
        if (id) {
            const response = await GetData(ApiPropertyId(id), HTTP_METHODS.GET);
            const {
                data: { propertyUnits },
                status,
                message
            } = response;
            setDataList(
                [...propertyUnits].sort((a, b) => a.name.localeCompare(b.name))
            );
            if (!status) {
                ConfigToast.text = message;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
                setConfigToast(ConfigToast);
            }
        }
    };

    /**
     * @description
     * Function that is executed when the component is mounted, calls the function that returns the Property list
     */
    useEffect(() => {
        CallData();
    }, [refresh]);

    return (
        <>
            <ToastElement ref={toastManagerRef} />
            <AlertElement ref={alertManagerRef} />
            <TableComponent
                data={dataList}
                headers={PropertyUnitTable}
                showFilters={true}
                keysFilter={keysFilter}
                showPagination={true}
            />
            {LoadingData && <LoaderElement />}
        </>
    );
};

export default PropertyUnitTableComponent;
