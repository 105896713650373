//  <--COMPONENTS--> //
import { useEffect, useState } from 'react';
import EcoequivalencesCard from '../../components/dataCenterComponents/ecoequivalencesCard/ecoequivalencesCard';
import MonthlyClosureCard from '../../components/dataCenterComponents/monthlyClosureCard/monthlyclosurecard';
import BZeroIndexCard from '../../components/dataCenterComponents/bzeroIndexCard/bzeroindexcard';
import RankingCard from '../../components/dataCenterComponents/rankingCard/rankingCard';
import MonthlyEvolutionCard from '../../components/dataCenterComponents/monthlyEvolutionCard/monthlyEvolutionCard';
import SubNavBar from '../../components/layout/main/subnavbar/subnavbar';
//  <--REDUX--> //
import { useAppSelector } from '../../store/hooks';
import { PropertyUnitStoreInterface } from '../../store/slices/property-unit/property-unit.interface';
import { MaterialInterface } from '../../interface';
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import { ClientStoreInterface } from '../../store/slices/client/client.interface';
import './data-center.scss';
import useWindowDimensions from '../../config/hooks/useWindowDimentions';
import { HTTP_METHODS } from '../../config/hooks/useCallApi/constants';
import { useCallApi } from '../../config/hooks/useCallApi';
import { ApiChartsData } from '../../config/service';
import { FormatLabels, FormatData } from '../../config/utils/IdbFormatData';
import { indexesPosibilities, initValues, bzeroIndex } from './data';
import { IDB, FilterInterface } from '../../config/utils/IndexedDb';
import { lang } from '../langs';
import { PropertyStoreInterface } from '../../store/slices/property/property.interface';
import { GetStorage, SaveStorage } from '../../config/utils/Storage';
import LoaderCharts from '../../components/loading/loading-charts';

const dbName = process.env.REACT_APP_PUBLIC_INDEXEDDB_DC ?? 'prymadb-A';
const dbNameReplic = process.env.REACT_APP_PUBLIC_INDEXEDDB_DCCOPY ?? 'prymadb-B';
const tblName = 'data-center';

const HomePage = () => {
    const requested = GetStorage(process.env.REACT_APP_PUBLIC_INITIAL_DCREQUEST);
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const { GetData: CallApiBackgroud } = useCallApi();
    const {
        CreateCollection,
        AddDataToDb,
        GetAllDataDb, // this function triggers an effect that updates the data, the parameter that brings the data is ObtainedData
        GetDataDb, // this function triggers an effect that updates the data, the parameter that brings the data is ObtainedData
        ObtainedData
    } = IDB([dbName,dbNameReplic]);

    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { clientData }: ClientStoreInterface = useAppSelector(
        state => state.client
    );
    const { propertyData, selected }: PropertyStoreInterface = useAppSelector(
        state => state.property
    );
    const { selectedUnits }: PropertyUnitStoreInterface = useAppSelector(
        state => state.propertyUnit
    );
    const defaultLang: string = preferences.lang;
    const {
        [defaultLang as keyof typeof lang]: {
            pageDataCenter: { months }
        }
    } = lang;

    const [filterKinds, setFilterKinds] = useState<MaterialInterface[]>([]);
    const [ecoFilterKinds, setEcoFilterKinds] = useState<MaterialInterface[]>(
        []
    );
    const [dataIdb, setDataIdb] = useState<any>([]);
    const [labels, setLabels] = useState<string[]>([]);
    const [ecoLabels, setEcoLabels] = useState<string[]>([]);
    const [colorsDB, setColorsDB] = useState({});
    const [dataApiledChart, setDataApiledChart] = useState<any>(initValues);
    const [filter, setFilter] = useState<FilterInterface>({
        tableName: tblName,
        filters: {
            keys: [],
            valuesProperty: []
        },
        dbName
    });
    const [formattedLabels, setFormattedLabels] = useState<string[]>([]);
    const { width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const sessions = localStorage.getItem('sessions');
    const [img, setImg] = useState<string>('zero');
    const [isLoadingCharts, setIsLoadingCharts] = useState<boolean>(false);
    const [dataInCacheStorage, setDataInCacheStorage] = useState<any>({});
    const [defaultDb, setDefaultDb] = useState<string>(dbName);

    const formatLabelsShow = labelsArray => {
        const formatted = labelsArray.map(label => {
            const month = label.substring(4, 6);
            return `${months[parseInt(month) - 1].slice(0, 1)}`;
        });
        setFormattedLabels(formatted);
    };

    const CallBackADB = async (Db) => {
        setDefaultDb(Db);
        await GetAllDataDb({ tableName: tblName, filters: filter.filters, dbName: Db  });
        setTimeout(()=>{
            setIsLoadingCharts(false);
        },3000)
    };

    /* function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    } */

    const CallData = async (dataInCache=false) => {
        // CALL DATA FROM SERVICE
        if (
            !localStorage.getItem('labels') &&
            !localStorage.getItem('filters')
        ) {
            setIsLoadingCharts(true);
            /* const rolesIds = userData.roles.map(item => {
                return item.id;
            }); */
            /* const uniqueRoles = rolesIds.filter(onlyUnique); */
            const values = {
                properties: propertyData.map(property => ({
                    id: property.id,
                    propertyUnits: property.propertyUnits.map((item => {return {id:item.id}})) || [] // Cambia el nombre del atributo a 'units'
                })),
                units: clientData.flatMap(client => client.units.map((item => item.id)))
            };
            const {
                data: { charts, filters }
            } = !dataInCache ? await GetData(
                ApiChartsData,
                HTTP_METHODS.GET,
                {},
                values
                /* uniqueRoles */
            ) : dataInCacheStorage;

            await ConfigureIndexedDb(filters, charts, dbName);

            if(dataInCache){
                const {data}:any = await CallApiBackgroud(
                    ApiChartsData,
                    HTTP_METHODS.GET,
                    {},
                    values
                    /* uniqueRoles */
                )
                // const { charts, filters } = data;
                // setIsLoadingCharts(true)
                // await DeleteDB({dbName})
                CreateIdbTable(dbNameReplic)
                const { charts, filters } = data;
                await ConfigureIndexedDb(filters, charts, dbNameReplic);
            }
        } else {
            const ecoFilters = localStorage.getItem('ecoFilters');
            setEcoFilterKinds(ecoFilters ? JSON.parse(ecoFilters) : []);
            const filters = localStorage.getItem('filters');
            setFilterKinds(filters ? JSON.parse(filters) : []);
            const ecoLabels = localStorage.getItem('ecoLabels');
            setEcoLabels(ecoLabels ? JSON.parse(ecoLabels) : []);
            const colors = localStorage.getItem('colors');
            setColorsDB(colors ? JSON.parse(colors) : []);
            const labels = localStorage.getItem('labels');
            const labelsFormated = FormatLabels(
                labels ? JSON.parse(labels) : [],
                'date'
            );
            setLabels(labelsFormated);
            GetAllDataDb({ tableName: tblName, filters: filter.filters, dbName });
        }
    };

    const ConfigureIndexedDb = async(filters,charts, Db) => {
        // GET FILTERS
        const { materialKind, material /* , properties */ } = filters;
        const combinedArray = materialKind.concat(material);
        localStorage.setItem('ecoFilters', JSON.stringify(combinedArray));
        localStorage.setItem('filters', JSON.stringify(material));
        setEcoFilterKinds(combinedArray);
        setFilterKinds(material);
        const colorsResult = {};
        material.forEach(item => {
            colorsResult[item.name] = item.color;
        });
        localStorage.setItem('colors', JSON.stringify(colorsResult));
        setColorsDB(colorsResult);
        // GET CHARTS DATA
        const { results, labels } = charts;
        // Elimina último mes por no tener datos completos
        // Si dia > 13, entonces 1 pop, sino 2 pops
        // FORMAT ECO LABELS DATA
        const ecoArrayLabels = FormatLabels(labels, 'date', true);
        localStorage.setItem('ecoLabels', JSON.stringify(ecoArrayLabels));
        setEcoLabels(ecoArrayLabels);

        // FORMAT LABELS DATA
        const arrayLabels = FormatLabels(labels, 'date');
        localStorage.setItem('labels', JSON.stringify(labels));
        setLabels(arrayLabels);

        // SAVE DATA INTO INDEXEDDB
        await AddDataToDb(
            { data: results, tableName: tblName },
            CallBackADB,
            Db
        );
    }

    const FormatDataToCharts = data => {
        formatLabelsShow(labels);
        setDataIdb(data);
        const dataFormatedApiled = FormatData({
            data,
            labels,
            chart: 'apiled',
            milestones: 'date',
            dataSetList: filterKinds,
            filter,
            apiledLine: true,
            apiledLineData: bzeroIndex,
            apiledLineOnFilter: ['propertyId'],
            colorsDB
        });
        const sumOfLastValues = dataFormatedApiled.datasets
            .map(dataset => {
                const lastValue = parseFloat(
                    dataset.data[dataset.data.length - 1]
                );
                return isNaN(lastValue) ? 0 : lastValue;
            })
            .reduce((sum, value) => parseFloat(sum) + parseFloat(value), 0);

        // Si la suma es cero, elimina el último elemento de 'labels'
        if (sumOfLastValues === 0) {
            dataFormatedApiled.datasets.forEach(dataset => {
                dataFormatedApiled.labels.pop();
            });
        }
        // Indice BZero y Basura como primer elemento siempre
        dataFormatedApiled.datasets.sort((a, b) => {
            if (a.label === 'Índice BZero') {
                return -1;
            } else if (b.label === 'Índice BZero') {
                return 1;
            } else if (a.label === 'Basura') {
                return -1;
            } else if (b.label === 'Basura') {
                return 1;
            } else {
                return 0;
            }
        });
        const otherDatasets = dataFormatedApiled.datasets.filter(
            dataset =>
                dataset.label !== 'Índice BZero' && dataset.label !== 'Basura'
        );

        const groupedDatasets = {};
        otherDatasets.forEach(dataset => {
            if (!groupedDatasets[dataset.kind]) {
                groupedDatasets[dataset.kind] = [];
            }
            groupedDatasets[dataset.kind].push(dataset);
        });

        const sortedOtherDatasets: any = [];
        Object.keys(groupedDatasets).forEach(kind => {
            sortedOtherDatasets.push(...groupedDatasets[kind]);
        });

        // Actualizar orden de los datasets en dataFormatedApiled
        dataFormatedApiled.datasets = [
            ...dataFormatedApiled.datasets.filter(
                dataset => dataset.label === 'Índice BZero'
            ),
            ...dataFormatedApiled.datasets.filter(
                dataset => dataset.label === 'Basura'
            ),
            ...sortedOtherDatasets
        ];
        
        if(dataFormatedApiled.labels.length > 0){
            localStorage.removeItem(dbName);
            localStorage.removeItem(dbNameReplic);
            localStorage.setItem(defaultDb,JSON.stringify(dataFormatedApiled));
        }
        GetBZeroIndex(dataFormatedApiled);
    };

    const GetBZeroIndex = dataFormatedApiled => {
        // Calcular Índice BZero para gráfico
        const dataBasura = dataFormatedApiled.datasets.filter(
            item => item.label === 'Basura'
        );
        const dataFinal = dataFormatedApiled.datasets.filter(
            item => item.label !== 'Índice BZero'
        );
        if (dataBasura.length > 0) {
            const indexesArray = new Array(dataBasura[0].data.length).fill(0);
            dataFinal.forEach(obj => {
                obj.data.forEach((value, index) => {
                    indexesArray[index] =
                        parseFloat(indexesArray[index]) + parseFloat(value);
                });
            });
            const sum = Array.from(
                { length: dataBasura[0].data.length },
                () => 0
            );
            dataBasura.forEach(item => {
                item.data.forEach((value, index) => {
                    sum[index] += parseFloat(value);
                });
            });
            sum.forEach((item, index) => {
                indexesArray[index] =
                    (item / parseFloat(indexesArray[index])) * 100;
            });
            const indexBZero = dataFormatedApiled.datasets.findIndex(
                item => item.label === 'Índice BZero'
            );
            // Asignar el valor de indexesArray al atributo data del elemento 'Índice BZero'
            if (indexBZero !== -1) {
                dataFormatedApiled.datasets[indexBZero].data = indexesArray;
            }
        }
        setDataApiledChart(dataFormatedApiled);
    };

    const handleButtonClick = () => {
        localStorage.setItem('sessions', '1');
        setImg('end');
    };

    useEffect(() => {
        setIsMobile(width <= 768);
    }, [width]);

    useEffect(() => {
        const dbA = localStorage.getItem(dbName);
        const dbB = localStorage.getItem(dbNameReplic);
        let dataFormatedApiled;
        if(dbA !== null){
            dataFormatedApiled = dbA;
        }
        if(dbB !== null){
            dataFormatedApiled = dbB;
        }
        if(dataFormatedApiled){
            GetBZeroIndex(JSON.parse(dataFormatedApiled));
        }
        CreateIdbTable(dbName);
        VerifyInCacheStorage();
    }, []);

    const CreateIdbTable = (db) => {
        CreateCollection({
            id: 'id',
            tableName: tblName,
            indexes: indexesPosibilities, 
            dbName:db
        });
    }

    const VerifyInCacheStorage = () => {
        caches.keys().then((keyList) => {
            if(keyList.length > 0){
                keyList.forEach(async(item) => {
                    const cacheStorage = await caches.open(item);
                    const cachedResponse = await cacheStorage.match(`${process.env.REACT_APP_PUBLIC_SERVICE_URL}/data-center/charts-data`);
                    if (cachedResponse) {
                        cachedResponse.json().then((item) => {
                            const {status, data} = item;
                            if(status){
                                setDataInCacheStorage({data});
                            }
                        });
                    }else{
                        setDataInCacheStorage({});
                    }
                })
            }
        })
        .catch(()=> {
            setDataInCacheStorage({});
        })
    }

    useEffect(() => {
        // console.log(dataInCacheStorage)
        if(dataInCacheStorage?.data){
            if(dataInCacheStorage?.data){
                CallData(true)
            }
        }else if(!requested){
            CallData()
            SaveStorage(true,process.env.REACT_APP_PUBLIC_INITIAL_DCREQUEST);
        }
    },[dataInCacheStorage])

    useEffect(() => {
        const ids = selected.map(item => {
            return item.id;
        });
        const idsUnits = selectedUnits.map(item => {
            return item.id;
        });
        const finalFilter: FilterInterface = {
            tableName: tblName,
            filters: {
                keys: ['propertyId'],
                valuesProperty: ids,
                valuesPropertyUnits: idsUnits
            }, dbName
        };

        setFilter(finalFilter);
        if (labels.length > 0) {
            if (selectedUnits.length + selected.length >= 1) {
                GetDataDb(finalFilter);
            } else {
                GetAllDataDb({ tableName: tblName, filters: filter.filters, dbName });
            }
        }
    }, [labels, selected]);

    useEffect(() => {
        FormatDataToCharts(ObtainedData);
    }, [ObtainedData]);

    return (
        <>
            <SubNavBar></SubNavBar>
            <div className='body'>
                {isLoadingCharts ? (
                    ecoFilterKinds.length > 0 && <LoaderCharts />
                ) : !isMobile ? (
                    <>
                        <div className='body__column-left'>
                            <MonthlyEvolutionCard
                                data={dataApiledChart}
                                formattedLabels={formattedLabels}
                            />
                            <EcoequivalencesCard
                                data={dataIdb}
                                filterKinds={ecoFilterKinds}
                                filter={filter}
                                labels={ecoLabels}
                                colorsDB={colorsDB}
                            />
                        </div>
                        <div className='body__column-right'>
                            <RankingCard {...dataIdb} />
                            <BZeroIndexCard data={dataIdb} />
                            <MonthlyClosureCard
                                data={dataApiledChart}
                                formattedLabels={formattedLabels}
                                dataIdb={dataIdb}
                                filterKinds={ecoFilterKinds}
                                filter={filter}
                                labels={ecoLabels}
                                colorsDB={colorsDB}
                            />
                            {LoadingData && <LoaderElement/>}
                        </div>
                        {LoadingData && <LoaderElement/>}
                    </>
                ) : (
                    <>
                        <MonthlyEvolutionCard
                            data={dataApiledChart}
                            formattedLabels={formattedLabels}
                        />
                        <EcoequivalencesCard
                            data={dataIdb}
                            filterKinds={ecoFilterKinds}
                            filter={filter}
                            labels={ecoLabels}
                            colorsDB={colorsDB}
                        />
                        <RankingCard {...dataIdb} />
                        <BZeroIndexCard data={dataIdb} />
                        <MonthlyClosureCard
                            data={dataApiledChart}
                            formattedLabels={formattedLabels}
                            dataIdb={dataIdb}
                            filterKinds={ecoFilterKinds}
                            filter={filter}
                            labels={ecoLabels}
                            colorsDB={colorsDB}
                        />
                    </>
                )}
                {LoadingData && <LoaderElement/>}
            </div>
            {sessions === '0' && img !== 'end' && (
                <div className='onboarding'>
                    {img === 'zero' && (
                        <div className={`onboarding__main ${img}`}>
                            <div className={`onboarding__main__btns`}>
                                <button
                                    className='onboarding__main__btns__omit subtitle'
                                    onClick={() => handleButtonClick()}
                                >
                                    Omitir
                                </button>
                                <button
                                    className='button button-body--onboarding'
                                    onClick={() => setImg('first')}
                                >
                                    <div className='button-body--onboarding__content'>
                                        <div className='button-body--onboarding__content__text'>
                                            Guía rápida de uso
                                        </div>
                                        <img
                                            className='button-body--onboarding__content__img'
                                            src='/svg-icons/Arrows.svg'
                                        />
                                    </div>
                                </button>
                            </div>
                        </div>
                    )}
                    {img === 'first' && (
                        <div className={`onboarding__main ${img}`}>
                            <div className={`onboarding__main__btns`}>
                                <button
                                    className='button button-body--onboardingPrev'
                                    onClick={() => setImg('zero')}
                                >
                                    <div className='button-body--onboardingPrev__content'>
                                        <img
                                            className='button-body--onboardingPrev__content__img'
                                            src='/svg-icons/Arrows.svg'
                                        />
                                    </div>
                                </button>
                                <button
                                    className='button button-body--onboardingNext'
                                    onClick={() => setImg('second')}
                                >
                                    <div className='button-body--onboardingNext__content'>
                                        <img
                                            className='button-body--onboardingNext__content__img'
                                            src='/svg-icons/Arrows.svg'
                                        />
                                    </div>
                                </button>
                            </div>
                        </div>
                    )}
                    {img === 'second' && (
                        <div className={`onboarding__main ${!isMobile && img}`}>
                            {isMobile ? (
                                <div
                                    className={`onboarding__main__content ${
                                        isMobile && img
                                    }`}
                                >
                                    <div
                                        className={`onboarding__main__${
                                            isMobile && 'content__'
                                        }btns`}
                                    >
                                        <button
                                            className={`button button-body--onboardingPrev`}
                                            onClick={() => setImg('first')}
                                        >
                                            <div className='button-body--onboardingPrev__content'>
                                                <img
                                                    className='button-body--onboardingPrev__content__img'
                                                    src='/svg-icons/Arrows.svg'
                                                />
                                            </div>
                                        </button>
                                        <button
                                            className='button button-body--onboardingNext'
                                            onClick={() => setImg('third')}
                                        >
                                            <div className='button-body--onboardingNext__content'>
                                                <img
                                                    className='button-body--onboardingNext__content__img'
                                                    src='/svg-icons/Arrows.svg'
                                                />
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className={`onboarding__main__btns`}>
                                    <button
                                        className='button button-body--onboardingPrev'
                                        onClick={() => setImg('first')}
                                    >
                                        <div className='button-body--onboardingPrev__content'>
                                            <img
                                                className='button-body--onboardingPrev__content__img'
                                                src='/svg-icons/Arrows.svg'
                                            />
                                        </div>
                                    </button>
                                    <button
                                        className='button button-body--onboardingNext'
                                        onClick={() => setImg('third')}
                                    >
                                        <div className='button-body--onboardingNext__content'>
                                            <img
                                                className='button-body--onboardingNext__content__img'
                                                src='/svg-icons/Arrows.svg'
                                            />
                                        </div>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                    {img === 'third' && (
                        <div className={`onboarding__main ${img}`}>
                            <div className={`onboarding__main__btns`}>
                                {!isMobile && (
                                    <button
                                        className='button button-body--onboardingPrev'
                                        onClick={() => setImg('second')}
                                    >
                                        <div className='button-body--onboardingPrev__content'>
                                            <img
                                                className='button-body--onboardingPrev__content__img'
                                                src='/svg-icons/Arrows.svg'
                                            />
                                        </div>
                                    </button>
                                )}
                                <button
                                    className='button button-body--onboarding'
                                    onClick={() => handleButtonClick()}
                                >
                                    <div className='button-body--onboarding__content'>
                                        <div className='button-body--onboarding__content__text'>
                                            Ir a PRYMA
                                        </div>
                                        <img
                                            className='button-body--onboarding__content__img'
                                            src='/svg-icons/Arrows.svg'
                                        />
                                    </div>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default HomePage;
