import { configureStore } from '@reduxjs/toolkit';
import users from './slices/users';
import property from './slices/property';
import toast from './slices/toast';
import propertyUnit from './slices/property-unit';
import client from './slices/client';
import application from './slices/application';
import unit from './slices/unit';
import dataCenter from './slices/data-center';

export const store = configureStore({
    reducer: {
        application,
        users,
        property,
        toast,
        propertyUnit,
        client,
        unit,
        dataCenter
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
