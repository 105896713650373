import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
//  <--COMPONENTS--> //
import { Wrapper, Container } from '../../../components/styles';
import PageTitle from '../../../components/pagetitle/pageTitle';
import FormBuilder from '../../../components/formBuilder/formBuilder';
//  <--HOOKS--> //
import { useToast } from '../../../config/hooks/useToast';
import { useCallApi } from '../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../config/hooks/useCallApi/constants';
//  <--INTERFACE--> //
import {
    ProgramFormCreateInterface,
    ProgramFormValues
} from '../../../interface';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../store/hooks';
//  <--SERVICES--> //
import { ApiProgram, ApiProgramId } from '../../../config/service';
//  <--OTHERS--> //
import { lang } from '../../langs';
import { FormCreateProgram } from './form';
import '../../../scss/global/global.scss';

const ProgramCreate = () => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const fieldRef = useRef(null);
    const { id } = useParams();
    const {
        [defaultLang as keyof typeof lang]: {
            pageProgram: { createTitle, editTitle }
        }
    } = lang;
    const [entityId, setEntityId] = useState<string>('');
    const [entity, setEntity] = useState<ProgramFormCreateInterface>();
    const [readyForm, setReadyForm] = useState<boolean>(false);

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        if (id) {
            setEntityId(id);
        } else {
            setReadyForm(true);
        }
    }, [id]);

    /**
     * @description CallDataEdit function, call the api to get the data of the entity and set the data in state
     */
    const CallDataEdit = async () => {
        const response = await GetData(
            ApiProgramId(entityId),
            HTTP_METHODS.GET
        );
        const { data, status } = response;
        setEntity(data);
        setReadyForm(true);
        if (!status) {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description this effect check that entityId is not empty and if it changes, if not empty, call the function to CallDataEdit
     */
    useEffect(() => {
        if (entityId !== '') {
            CallDataEdit();
        }
    }, [entityId]);

    /**
     * @description
     * @function OnSubmit is a function that is called when the form is submitted
     * @param values is a object that contains the data of the form
     */
    const OnSubmit = async (values: ProgramFormCreateInterface) => {
        const response = id
            ? await GetData(ApiProgramId(entityId), HTTP_METHODS.PATCH, values)
            : await GetData(ApiProgram, HTTP_METHODS.POST, values);
        const { message, data, status } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * @function FocusOnInput is a function that is called when the form's cancel button is clicked
     * @param ref is a reference to the form
     */
    const FocusOnInput = (ref: any) => {
        ref.current.focus();
    };

    /**
     * @description
     * @constant FormButtons is a object that contains the buttons of the form
     */
    const FormButtons = {
        ok: {
            controller: OnSubmit
        },
        cancel: {
            controller: FocusOnInput
        }
    };

    return (
        <Container>
            <ToastElement ref={toastManagerRef} />
            <PageTitle
                title={entity?.description ? editTitle : createTitle}
                icon='settings/propertiesB.svg'
            />
            <Wrapper>
                {readyForm && (
                    <FormBuilder
                        initialValues={
                            entity?.description ? entity : ProgramFormValues
                        }
                        buttons={FormButtons}
                        lang={defaultLang}
                        formStructure={FormCreateProgram}
                        focusOn={fieldRef}
                        config={
                            entity?.description
                                ? { noClearButton: true }
                                : { noClearButton: false }
                        }
                    />
                )}
            </Wrapper>
            {LoadingData && <LoaderElement />}
        </Container>
    );
};

export default ProgramCreate;
