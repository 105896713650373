export interface InvitationsInterface {
    id: string;
    email: string;
    role: string;
    state: number;
    createdAt: string;
    updatedAt: string;
}

export interface InvitationsFormCreateInterface {
    email: string;
    role: string;
    relatedId: string;
}

export const InvitationsFormValues: InvitationsFormCreateInterface = {
    email: '',
    role: '',
    relatedId: ''
};
