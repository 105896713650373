import '../styles/edit-profile.scss';
import { useEffect, useRef, useState } from 'react';
import { Switch, ConfigProvider, Tooltip } from 'antd';
//  <--COMPONENTS--> //
import FormBuilder from '../../../components/formBuilder/formBuilder';
import ProfileSummary from '../my-profile';
import ButtonBody from '../../../components/button/button';
//  <--REDUX--> //
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { FormProfile } from '../form';
import { lang } from '../../langs';
import { useCallApi } from '../../../config/hooks/useCallApi';
import { useToast } from '../../../config/hooks/useToast';
import { HTTP_METHODS } from '../../../config/hooks/useCallApi/constants';
//  <--SERVICES--> //
import { ApiUsersId } from '../../../config/service';
import { useToastHook } from '../../../store/slices/toast/toast.hook';
import { setUserData } from '../../../store/slices/users';
import { SaveStorage } from '../../../config/utils/Storage';

const EditProfile = () => {
    const dispatch = useAppDispatch();
    const { ToastConfig } = useToastHook();
    const { GetData /* , LoadingData, LoaderElement */ } = useCallApi();
    const { Colors, goPreviousPage } = useToast();
    const { userData, preferences } = useAppSelector(state => state.users);
    const defaultLang: string = preferences.lang;
    const key = defaultLang;

    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, []);

    const {
        [key as keyof typeof lang]: {
            pageMyProfile: {
                editProfile: {
                    title,
                    lblImage,
                    imageButonText,
                    lblNotification,
                    mailNotification,
                    smsNotification,
                    lblAlarms,
                    soundAlarm,
                    calendarSync,
                    errorMessage,
                    successMessage
                }
            }
        }
    } = lang;
    const fieldRef = useRef(null);
    const [user] = useState(userData);
    const [successCleanForm, setSuccessCleanForm] = useState<boolean>(true);

    /*  *
     * @description
     * @function OnSubmit is a function that is called when the form is submitted
     * @param values is a object that contains the data of the form */
    const handleChange = (checked: boolean) => {
        // console.log(`switch to ${checked}`);
    };

    const OnSubmit = async values => {
        const dataSend = {
            name: values.name,
            lastName: values.lastName,
            rut: values.rut,
            phone: values.phone
        };
        try {
            setSuccessCleanForm(false);
            values.user = userData.id;
            const response = await GetData(
                ApiUsersId(userData.id),
                HTTP_METHODS.PATCH,
                dataSend
            );
            const { data, status } = response;
            if (status) {
                setSuccessCleanForm(true);
                ToastConfig({
                    message: successMessage,
                    color: Colors.Success
                });
                const newUserData = { ...userData };
                Object.assign(newUserData, {
                    name: values.name,
                    lastName: values.lastName,
                    rut: values.rut,
                    phone: values.phone
                });
                dispatch(setUserData(newUserData));
                SaveStorage(
                    newUserData,
                    process.env.REACT_APP_PUBLIC_USER_DATA_COOKIE
                );
                goPreviousPage();
            } else {
                ToastConfig({ message: data.message, color: Colors.Error });
            }
        } catch (err) {
            ToastConfig({ message: errorMessage, color: Colors.Error });
        }
    };

    /* *
     * @description
     * @function FocusOnInput is a function that is called when the form's cancel button is clicked
     * @param ref is a reference to the form */

    const FocusOnInput = (ref: any) => {
        ref.current.focus();
    };

    /* *
     * @description
     * @constant FormButtons is a object that contains the buttons of the form */

    const FormButtons = {
        ok: {
            controller: OnSubmit
        },
        cancel: {
            controller: FocusOnInput
        }
    };

    /* useEffect(() => {
        // Obtener el elemento de destino al que deseas desplazarte
        const targetElement = document.querySelector('#edit-grid-container');
    
        if (targetElement) {
          // Obtener la posición del elemento en relación con la parte superior de la página
          const { top } = targetElement.getBoundingClientRect();
    
          // Desplazarse a la posición del elemento de manera suave
          window.scrollTo({ top, behavior: 'smooth' });
        }
      }, []);
 */
    return (
        <>
            <div className='profile-body'>
                <ProfileSummary />
                <div
                    id='edit-grid-container'
                    className='profile-body__edit-grid-container'
                >
                    <div className='profile-body__edit-grid-container__item'>
                        <img
                            className='profile-body__edit-grid-container__item__img'
                            src='/svg-icons/SettingsIconG.svg'
                        />
                        <div className='profile-body__edit-grid-container__item__title subtitle'>
                            {title}
                        </div>
                        <Tooltip
                            title={
                                <span className='subnavbar__content__tooltip'>
                                    PRÓXIMAMENTE
                                </span>
                            }
                            color='#fff'
                        >
                            <div className='profile-body__edit-grid-container__item__edit-img-container'>
                                <img
                                    className='profile-body__edit-grid-container__item__edit-img-container__img disabled'
                                    src='/svg-icons/ProfileIcon.svg'
                                />
                                <div className='profile-body__edit-grid-container__item__edit-img-container__text subtitle disabled'>
                                    {lblImage}
                                </div>
                                <ButtonBody
                                    able='true'
                                    kind='button button-body--upload disabled'
                                    text={imageButonText}
                                ></ButtonBody>
                            </div>
                        </Tooltip>
                        <div
                            id='miDiv'
                            className='profile-body__edit-grid-container__item__edit-info-container'
                        >
                            <FormBuilder
                                initialValues={user}
                                buttons={FormButtons}
                                lang={defaultLang}
                                formStructure={FormProfile}
                                focusOn={fieldRef}
                                onSuccessCleanForm={successCleanForm}
                            />
                        </div>
                    </div>
                    <Tooltip
                        title={
                            <span className='subnavbar__content__tooltip'>
                                PRÓXIMAMENTE
                            </span>
                        }
                        color='#fff'
                    >
                        <div className='profile-body__edit-grid-container__item'>
                            <img
                                className='profile-body__edit-grid-container__item__img   disabled'
                                src='/svg-icons/AlertIconG.svg'
                            />
                            <div className='profile-body__edit-grid-container__item__title  subtitle disabled'>
                                {lblNotification}
                            </div>
                            <div className='profile-body__edit-grid-container__item__edit-info-container'>
                                <div className='profile-body__edit-grid-container__item__edit-info-container__not   disabled'>
                                    <div className='profile-body__edit-grid-container__item__edit-info-container__not__option'>
                                        <div className='profile-body__edit-grid-container__item__edit-info-container__not__option__text subtitle'>
                                            {mailNotification}
                                        </div>
                                        <ConfigProvider
                                            theme={{
                                                components: {
                                                    Switch: {
                                                        colorPrimary: '#2994e6',
                                                        colorBorder: '#2994e6',
                                                        colorTextTertiary:
                                                            '#a2a2a3'
                                                    }
                                                }
                                            }}
                                        >
                                            <Switch
                                                className='profile-body__edit-grid-container__item__edit-info-container__not__option__toggle'
                                                size='small'
                                                defaultChecked
                                                onChange={handleChange}
                                            />
                                        </ConfigProvider>
                                    </div>
                                    <div className='profile-body__edit-grid-container__item__edit-info-container__not__option'>
                                        <div className='profile-body__edit-grid-container__item__edit-info-container__not__option__text subtitle'>
                                            {smsNotification}
                                        </div>
                                        <ConfigProvider
                                            theme={{
                                                components: {
                                                    Switch: {
                                                        colorPrimary: '#2994e6',
                                                        colorBorder: '#2994e6',
                                                        colorTextTertiary:
                                                            '#a2a2a3'
                                                    }
                                                }
                                            }}
                                        >
                                            <Switch
                                                className='profile-body__edit-grid-container__item__edit-info-container__not__option__toggle'
                                                size='small'
                                                defaultChecked
                                                onChange={handleChange}
                                            />
                                        </ConfigProvider>
                                    </div>
                                </div>
                                <div className='profile-body__edit-grid-container__item__edit-info-container__not disabled'>
                                    <p className='profile-body__edit-grid-container__item__edit-info-container__not__title subtitle'>
                                        {lblAlarms}
                                    </p>
                                    <div className='profile-body__edit-grid-container__item__edit-info-container__not__option '>
                                        <div className='profile-body__edit-grid-container__item__edit-info-container__not__option__text subtitle'>
                                            {soundAlarm}
                                        </div>
                                        <ConfigProvider
                                            theme={{
                                                components: {
                                                    Switch: {
                                                        colorPrimary: '#2994e6',
                                                        colorBorder: '#2994e6',
                                                        colorTextTertiary:
                                                            '#a2a2a3'
                                                    }
                                                }
                                            }}
                                        >
                                            <Switch
                                                className='profile-body__edit-grid-container__item__edit-info-container__not__option__toggle'
                                                size='small'
                                                defaultChecked
                                                onChange={handleChange}
                                            />
                                        </ConfigProvider>
                                    </div>
                                    <div className='profile-body__edit-grid-container__item__edit-info-container__not__option'>
                                        <div className='profile-body__edit-grid-container__item__edit-info-container__not__option__text subtitle'>
                                            {calendarSync}
                                        </div>
                                        <ConfigProvider
                                            theme={{
                                                components: {
                                                    Switch: {
                                                        colorPrimary: '#2994e6',
                                                        colorBorder: '#2994e6',
                                                        colorTextTertiary:
                                                            '#a2a2a3'
                                                    }
                                                }
                                            }}
                                        >
                                            <Switch
                                                className='profile-body__edit-grid-container__item__edit-info-container__not__option__toggle'
                                                size='small'
                                                defaultChecked
                                                onChange={handleChange}
                                            />
                                        </ConfigProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tooltip>
                </div>
            </div>
        </>
    );
};

export default EditProfile;
