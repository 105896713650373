import { GestorInterface } from './gestor.interface';

export interface TruckInterface {
    id: string;
    brand: string;
    patent: string;
    capacity: string;
    description: number;
    createdAt: string;
    updatedAt: string;
    gestor: GestorInterface;
}

export interface TruckFormCreateInterface {
    brand: string;
    patent: string;
    capacity: string;
    description: string;
    gestor: string;
}

export const TruckFormValues: TruckFormCreateInterface = {
    brand: '',
    patent: '',
    capacity: '',
    description: '',
    gestor: ''
};
