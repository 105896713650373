import {
    EditableTypesValues,
    HeadersInterface
} from '../interface/table.interface';
export const tableData = {
    regXpage: 1000,
    listCountPages: [100, 200, 300, 400, 500],
    emptyData: 'No data to display',
    filesPageTxt: 'Records per page',
    filesPageTxtConnector: 'Of',
    regsPageTxt: 'Records'
};
/**
 * This is simple object passed to render data in table component
 * and render table headers
 */
export const ArrayTbl: HeadersInterface[] = [
    {
        dataField: 'date',
        dataFieldIsAutoFill: 'date',
        text: 'Fecha'
    },
    {
        dataField: 'propertyName',
        dataFieldIsAutoFill: 'propertyName',
        text: 'Inmueble'
    },
    {
        dataField: 'gestorName',
        dataFieldIsAutoFill: 'gestorName',
        text: 'Gestor'
    },
    {
        dataField: 'truckPatent',
        dataFieldIsAutoFill: 'dtm_truckPatent',
        fieldAutofill: 'truck_autofill',
        relatedDatafield: 'truckId',
        text: 'Patente',
        isEditable: true,
        editableType: EditableTypesValues.list,
        editableListDataKey: 'patent',
        editableListData: []
    },
    {
        dataField: 'material',
        dataFieldIsAutoFill: 'material',
        text: 'Material'
    },
    {
        dataField: 'finalDUName',
        dataFieldIsAutoFill: 'mdt_finalDUName',
        fieldAutofill: 'fdu_autofill',
        relatedDatafield: 'finalDUId',
        text: 'Establecimiento',
        isEditable: true,
        editableType: EditableTypesValues.list,
        editableListData: []
    },
    {
        dataField: 'managementName',
        dataFieldIsAutoFill: 'mdt_managementName',
        fieldAutofill: 'management_autofill',
        relatedDatafield: 'managementId',
        text: 'Gestión',
        isEditable: true,
        editableType: EditableTypesValues.list,
        editableListData: [],
        dependsThisListDataKey: 'treatments',
        dependsThisListDataIndexColumn: 7
    },
    {
        dataField: 'treatmentName',
        dataFieldIsAutoFill: 'mdt_treatmentName',
        fieldAutofill: 'treatment_autofill',
        relatedDatafield: 'treatmentId',
        text: 'Tratamiento',
        isEditable: true,
        editableType: EditableTypesValues.list,
        editableListData: []
    },
    {
        dataField: 'weight',
        dataFieldIsAutoFill: 'weight',
        text: 'Peso [kg]'
    }
];
