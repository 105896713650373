export const lang = {
    es: {
        layout: {
            main: {
                leftsection: {
                    welcomeMsg: '¡Hola!',
                    listTitle: 'Mis inmuebles',
                    warningMessage: 'Recuerda que ya tienes seleccionado ',
                    andTxt: 'y ',
                    propertiesTxt: 'inmuebles '
                },
                rightsection: {
                    remindersTitle: 'Notificaciones',
                    months: [
                        'Enero',
                        'Febrero',
                        'Marzo',
                        'Abril',
                        'Mayo',
                        'Junio',
                        'Julio',
                        'Agosto',
                        'Septiembre',
                        'Octubre',
                        'Noviembre',
                        'Diciembre'
                    ],
                    days: [
                        'Domingo',
                        'Lunes',
                        'Martes',
                        'Miércoles',
                        'Jueves',
                        'Viernes',
                        'Sábado'
                    ]
                }
            }
        },
        controlPanelComponents: {
            garbageControl: {
                title: 'Control de Basura',
                downloadButton: 'Descargar resumen',
                automateButton: 'Automatizar solicitud',
                bagsText: 'Bolsas disponibles',
                bagCard: {
                    stockText: 'del stock total',
                    repositionButton: 'Solicitar reposición'
                }
            },
            notificationsCard: {
                title: 'Notificaciones',
                lateEventsTitle: 'Eventos con atraso',
                todayEventsTitle: 'Eventos al día',
                futureEventsTitle: 'Eventos próximos'
            }
        },
        dataCenterComponents: {
            bzeroIndexCard: {
                title: 'Índice BZero',
                subtitle: 'Periodo: último trimestre',
                noContentMessage: 'No tiene registros de basura',
                detailMessage:
                    'El índice BZero corresponde al porcentaje de residuos que van a parar a relleno sanitario. La misión es llevar este valor a cero.'
            },
            ecoequivalencesCard: {
                title: 'Eco equivalencias',
                subtitle: 'Periodo: histórico',
                ecoequivalenceItem: {
                    equivalentTitle: 'Equivalente a '
                },
                detailMessage:
                    'Las ecoequivalencias de agua evitada y CO2 son acumulables, sin embargo las otras son representaciones del CO2 no emitido'
            },
            monthlyClosureCard: {
                title: 'Cierre mensual',
                subtitle: 'Periodo',
                sinader: 'Planilla',
                detail: 'Última actualización',
                pastMonths: 'Ver meses anteriores',
                certificates: 'Certificados y reportes descargables',
                billing: 'Facturación',
                downloadSinaderButton: 'Descargar planilla SINADER',
                downloadButton: 'Seleccionar certificados',
                billingButton: 'Ver facturación',
                months: [
                    'Enero',
                    'Febrero',
                    'Marzo',
                    'Abril',
                    'Mayo',
                    'Junio',
                    'Julio',
                    'Agosto',
                    'Septiembre',
                    'Octubre',
                    'Noviembre',
                    'Diciembre'
                ],
                certificatesTxts: {
                    pickupTitle:
                        'Los materiales son retirados por las siguientes empresas:',
                    finalDisposalTitle:
                        'Los materiales son entregados a las siguientes plantasmde disposición final:',
                    rut: 'RUT: 76.847.885-6',
                    address: 'Alonso de Córdova 2860, of 304, Vitacura.',
                    web: 'www.bzero.cl',
                    buildingTxt: ' / ',
                    footerTxt:
                        '¡Sigamos avanzando juntos hacia el camino basura cero! Recuerda que toda la información de este certificado está disponible en PRYMA. Si quieres saber más, escríbenos a contactopryma@bzero.cl',
                    finalDisposal: {
                        title: 'CERTIFICADO DE DESTINO FINAL',
                        text1a: 'Por medio de este documento, BZero acredita que en el mes de ',
                        text1b: ' los siguientes materiales fueron retirados y dispuestos de forma sustentable para su posterior reciclaje y/o valorización.',
                        tableTitle:
                            'Resumen total de reciclables y basura mensual'
                    },
                    balance: {
                        title: 'CERTIFICADO MENSUAL DE GESTIÓN DE RESIDUOS',
                        text1a: 'Con el programa Camino a Basura Cero tenemos como propósito desviar la mayor cantidad de residuos del relleno sanitario. Mediante este certificado, BZero acredita que en el mes de ',
                        text1b: ', los siguientes materiales fueron retirados y dispuestos de forma sustentable para su posterior reciclaje y/o valorización.',
                        chartTitle: 'Evolución Integrada Mensual',
                        ecoeqTitle: 'Ecoequivalencias histórico'
                    }
                },
                balanceTitle: 'Certificado mensual de gestión de residuos'
            },
            monthlyEvolutionCard: {
                monthlyIntegratedEvolution: 'Evolución Integrada Mensual',
                evolutionButton: 'Ver detalle',
                subtitle: 'Periodo: últimos 12 meses',
                months: [
                    'Enero',
                    'Febrero',
                    'Marzo',
                    'Abril',
                    'Mayo',
                    'Junio',
                    'Julio',
                    'Agosto',
                    'Septiembre',
                    'Octubre',
                    'Noviembre',
                    'Diciembre'
                ],
                detailMessage:
                    'Los datos del último mes podrían estar incompletos'
            },
            rankingCard: {
                title: 'inmuebles',
                subtitle: 'Periodo: último trimestre',
                detailTitle: 'Ver detalle'
            },
            recolectionCard: {
                title: 'Reciclaje total por material',
                subtitle: 'Periodo',
                wasteCard: {
                    pastMonth: 'mes anterior',
                    totalTxt: 'del total'
                },
                months: [
                    'Enero',
                    'Febrero',
                    'Marzo',
                    'Abril',
                    'Mayo',
                    'Junio',
                    'Julio',
                    'Agosto',
                    'Septiembre',
                    'Octubre',
                    'Noviembre',
                    'Diciembre'
                ],
                lastMonth: 'Último mes',
                lastQuarter: 'Último trimestre',
                historic: 'Histórico',
                selectedRange: 'Rango seleccionado'
            }
        },
        settingsBar: {
            titleSettingsBar: 'Configuraciones'
        },
        header: {
            goBack: 'Volver'
        }
    },
    en: {
        layout: {
            main: {
                leftsection: {
                    welcomeMsg: 'Hello!',
                    listTitle: 'My properties',
                    warningMessage:
                        'Remember that you already have selected properties',
                    andTxt: 'and ',
                    propertiesTxt: 'properties '
                },
                rightsection: {
                    remindersTitle: 'Notifications',
                    months: [
                        'January',
                        'February',
                        'March',
                        'April',
                        'Mao',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December'
                    ],
                    days: [
                        'Sunday',
                        'Monday',
                        'Tuesday',
                        'Wednesday',
                        'Thursday',
                        'Friday',
                        'Saturday'
                    ]
                }
            }
        },
        controlPanelComponents: {
            garbageControl: {
                title: 'Garbage Control',
                downloadButton: 'Download summary',
                automateButton: 'Automate request',
                bagsText: 'Available bags',
                bagCard: {
                    stockText: 'from total stock',
                    repositionButton: 'Request reposition'
                }
            },
            notificationsCard: {
                title: 'Notifications',
                lateEventsTitle: 'Late Events',
                todayEventsTitle: 'Today Events',
                futureEventsTitle: 'Future Events'
            }
        },
        dataCenterComponents: {
            bzeroIndexCard: {
                title: 'BZero Index',
                subtitle: 'Period: last quarter',
                noContentMessage: 'Does not have trash records',
                detailMessage:
                    'The BZero index corresponds to the percentage of waste that ends up in landfills. The mission is to bring this value to zero.'
            },
            ecoequivalencesCard: {
                title: 'Eco equivalents',
                subtitle: 'Period: historical',
                ecoequivalenceItem: {
                    equivalentTitle: 'Equivalent to '
                },
                detailMessage:
                    'The water avoided and CO2 eco-equivalences are cumulative; however, the others are representations of the non-emitted CO2'
            },
            monthlyClosureCard: {
                title: 'Monthly closure',
                subtitle: 'Period',
                sinader: 'Spreadsheet',
                detail: 'Last Update',
                pastMonths: 'See previous months',
                certificates: 'Certificates and reports',
                billing: 'Billing',
                downloadSinaderButton: 'Download SINADER spreadsheet',
                downloadButton: 'Select certificates',
                billingButton: 'View billing',
                months: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'Mao',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ],
                certificatesTxts: {
                    pickupTitle:
                        'Materials are removed by the following companies:',
                    finalDisposalTitle:
                        'Materials are delivered to the following final disposal plants:',
                    rut: 'RUT: 76.847.885-6',
                    address: 'Alonso de Córdova 2860, of 304, Vitacura.',
                    web: 'www.bzero.cl',
                    buildingTxt: ' /',
                    footerTxt:
                        "Let's keep moving forward together on the zero waste journey! Remember that all the information for this certificate is available on PRYMA. If you want to know more, write to us at contactopryma@bzero.cl",
                    finalDisposal: {
                        title: 'FINAL DISPOSAL CERTIFICATE',
                        text1a: 'Through this document, BZero certifies that in ',
                        text1b: ', the following materials were removed and disposed of in a sustainable manner for subsequent recycling and/or recovery.',
                        tableTitle:
                            'Monthly Total Recyclables and Garbage Summary'
                    },
                    balance: {
                        title: 'MONTHLY WASTE MANAGEMENT CERTIFICATE',
                        text1a: 'With the Camino a Basura Cero program we aim to divert the greatest amount of waste from the landfill. Through this certificate, BZero certifies that in month ',
                        text1b: ', the following materials were removed and sustainably disposed of for subsequent recycling and/or valorization.',
                        chartTitle: 'Monthly Integrated Evolution',
                        ecoeqTitle: 'Ecoequivallences historical'
                    }
                },
                balanceTitle: 'Monthly waste management certificate'
            },
            monthlyEvolutionCard: {
                monthlyIntegratedEvolution: 'Monthly Integrated Evolution',
                evolutionButton: 'View details',
                subtitle: 'Period: last 12 months',
                months: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'Mao',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ],
                detailMessage: "Last month's data may be incomplete"
            },
            rankingCard: {
                title: 'properties',
                subtitle: 'Period: last quarter',
                detailTitle: 'See more'
            },
            recolectionCard: {
                title: 'Total recycling by material',
                subtitle: 'Period',
                wasteCard: {
                    pastMonth: 'last month',
                    totalTxt: 'from total'
                },
                months: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'Mao',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ],
                lastMonth: 'Last month',
                lastQuarter: 'Last quarter',
                historic: 'Historical',
                selectedRange: 'Selected range'
            }
        },
        settingsBar: {
            titleSettingsBar: 'Settings'
        },
        header: {
            goBack: 'Go Back'
        }
    }
};
