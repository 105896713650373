import '../../../settings.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
//  <--COMPONENTS--> //
import SubNavBar from '../../../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../../../components/header/header';
import GestorUsersTableComponent from '../gestor-users-table';
import FormBuilder from '../../../../../components/formBuilder/formBuilder';
import ButtonBody from '../../../../../components/button/button';
//  <--HOOKS--> //
import { useToast } from '../../../../../config/hooks/useToast';
import { useAlert } from '../../../../../config/hooks/useAlert';
import { useCallApi } from '../../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../../config/hooks/useCallApi/constants';
//  <--INTERFACE--> //
import {
    GestorUserFormCreateInterface,
    GestorUserFormValues,
    GestorInterface,
    GestorFormCreateInterface,
    GestorFormValues
} from '../../../../../interface';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../../store/hooks';
//  <--SERVICES--> //
import {
    ApiGestorUser,
    ApiGestorUserId,
    ApiUsersId,
    ApiGestor,
    ApiGestorId
} from '../../../../../config/service';
//  <--OTHERS--> //
import { lang } from '../../../../langs';
import { FormCreateGestorUser } from './form';
import '../../../../../scss/global/global.scss';

const GestorUserCreate = () => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        AlertElement,
        alertManagerRef,
        ConfigAlert,
        setConfigAlert,
        Defaults
    } = useAlert({ defaultLang });
    const fieldRef = useRef(null);
    const { id, gestorUserId } = useParams();
    const {
        [defaultLang as keyof typeof lang]: {
            pageGestor: { title },
            pageGestorUser: {
                gestorUsersTitle,
                createTitle,
                editTitle,
                detailTitle,
                adminTitle,
                deleteMessage,
                deleteTitle,
                newTitle
            }
        }
    } = lang;
    const [entityId, setEntityId] = useState<string>('');
    const [entity, setEntity] = useState<GestorUserFormCreateInterface>();
    const [entityGestorId, setEntityGestorId] = useState<string>('');
    const [entityGestor, setEntityGestor] =
        useState<GestorFormCreateInterface>();
    const [readyForm, setReadyForm] = useState<boolean>(false);
    const [detailPage, setDetailPage] = useState<boolean>(true);
    const navigate = useNavigate();
    const [updates, setUpdates] = useState<boolean>(false);
    const [dataList, setDataList] = useState<GestorInterface[]>([]);

    /**
     * @description CallDataFontfunction, call the api to get the data of the font and set the data in state to show title
     */
    const CallDataGestor = async () => {
        const response = await GetData(
            ApiGestorId(entityGestorId),
            HTTP_METHODS.GET
        );
        const { data } = response;
        setEntityGestor(data);
        /* RequirementFormValues.dataFont = data.id;
        RequirementFormValues.property = data.property?.id;
        RequirementFormValues.material = data.material?.id;
        RequirementFormValues.gestor = data.gestor?.id; */
    };

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state
     */
    useEffect(() => {
        if (id) {
            setEntityGestorId(id);
        } else {
            setEntityGestor(GestorFormValues);
        }
    }, [id]);

    /**
     * @description this effect check that entityFontId is not empty and if it changes, if not empty, call the function to CallDataFont
     */
    useEffect(() => {
        if (entityGestorId !== '') {
            CallDataGestor();
        }
    }, [entityGestorId]);

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        setReadyForm(false);
        if (gestorUserId) {
            setEntityId(gestorUserId);
        } else {
            setEntity(GestorUserFormValues);
            setReadyForm(false);
            setTimeout(() => {
                setReadyForm(true);
            }, 50);
        }
    }, [gestorUserId]);

    /**
     * @description
     * @function CallData is a function that returns a promise with the Company list
     */
    const CallData = async () => {
        const response = await GetData(ApiGestor, HTTP_METHODS.GET);
        const { data } = response;
        setDataList(data);
    };

    /**
     * @description
     * Function that is executed when the component is mounted, calls the function that returns the Company and UserRole list
     */
    useEffect(() => {
        CallData();
    }, []);

    useEffect(() => {
        if (dataList && dataList?.length > 0) {
            FormCreateGestorUser[6].elements = dataList;
        }
    }, [dataList]);

    /**
     * @description CallDataEdit function, call the api to get the data of the entity and set the data in state
     */
    const CallDataEdit = async () => {
        const response = await GetData(ApiUsersId(entityId), HTTP_METHODS.GET);
        const { data, status } = response;
        setEntity(data);
        setReadyForm(true);
        if (!status) {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description this effect check that entityId is not empty and if it changes, if not empty, call the function to CallDataEdit
     */
    useEffect(() => {
        if (entityId !== '') {
            CallDataEdit();
            setDetailPage(true);
        }
    }, [entityId]);

    /**
     * @description
     * @function OnSubmit is a function that is called when the form is submitted
     * @param values is a object that contains the data of the form
     */
    const OnSubmit = async (values: GestorUserFormCreateInterface) => {
        const response = gestorUserId
            ? await GetData(
                  ApiGestorUserId(entityId),
                  HTTP_METHODS.PATCH,
                  values
              )
            : await GetData(ApiGestorUser, HTTP_METHODS.POST, values);
        const { message, data, status } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
            setReadyForm(false);
            CallDataEdit();
            setUpdates(true);
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * @function FocusOnInput is a function that is called when the form's cancel button is clicked
     * @param ref is a reference to the form
     */
    const FocusOnInput = (ref: any) => {
        ref.current.focus();
    };

    /**
     * @description
     * @constant FormButtons is a object that contains the buttons of the form
     */
    const FormButtons = {
        ok: {
            controller: OnSubmit
        },
        cancel: {
            controller: FocusOnInput
        }
    };

    /**
     * @description function that is executed when the delete button is pressed
     * @function Delete is a function that launches an alert to confirm the deletion of a COMPANY
     * @param id is a string that contains the id of the COMPANY to be deleted
     */
    const Delete = async () => {
        ConfigAlert.title = Defaults.titles.Delete;
        ConfigAlert.message = deleteMessage;
        ConfigAlert.okButtonText = Defaults.buttons.Ok.Accept;
        ConfigAlert.okButtonController = {
            func: ConfirmDelete,
            param: gestorUserId
        };
        ConfigAlert.cancelButtonText = Defaults.buttons.Cancel.Cancel;
        setConfigAlert(ConfigAlert);
    };

    /**
     * @description function that is executed when the okDelete button is pressed
     * @function Delete is a function that deletes a COMPANY
     * @param id is a string that contains the id of the COMPANY to be deleted
     */
    const ConfirmDelete = async (id: string) => {
        if (gestorUserId) {
            const response = await GetData(
                ApiGestorUserId(gestorUserId),
                HTTP_METHODS.DELETE
            );
            const { data, status, message } = response;
            if (status) {
                ConfigToast.text = message;
                ConfigToast.backColor = Colors.Success;
                setConfigToast(ConfigToast);
                navigate('/panel/company');
            } else {
                ConfigToast.text = data.message;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
                setConfigToast(ConfigToast);
            }
        }
    };

    useEffect(() => {
        setUpdates(false);
    }, [entity]);

    return (
        <>
            <AlertElement ref={alertManagerRef} />
            <ToastElement ref={toastManagerRef} />
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/GestorsIcon.svg'
                kind='settings'
                option='Gestors'
            ></SubNavBar>
            <div className='settings'>
                <Header
                    title={{ name: title, link: '/panel/company' }}
                    icon='GestorsIconB.svg'
                    subtitle={{
                        name: entityGestor?.name ?? '',
                        link: `/panel/company/edit/${id}`
                    }}
                    subsubtitle={{
                        name: gestorUsersTitle,
                        link: `/panel/company/${id}/gestor-users`
                    }}
                    subsubsubtitle={{ name: entity?.name ?? '' }}
                />
                <div className='settings__grid-container'>
                    <div className='settings__grid-container__item'>
                        <div className='settings__grid-container__item__title title'>
                            {adminTitle}
                        </div>
                        <GestorUsersTableComponent refresh={updates} />
                        <div className='settings__grid-container__item__content'>
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={newTitle}
                                path={`/panel/company/${id}/gestor-users/create`}
                            ></ButtonBody>
                        </div>
                    </div>
                    <div className='settings__grid-container__item_right'>
                        <div className='settings__grid-container__item_right__title title'>
                            {gestorUserId ? (
                                <div>
                                    {detailPage ? detailTitle : editTitle}
                                </div>
                            ) : (
                                <div>{createTitle}</div>
                            )}
                        </div>
                        <div className='settings__grid-container__item_right__content'>
                            <>
                                {readyForm && (
                                    <FormBuilder
                                        initialValues={
                                            entity?.name
                                                ? entity
                                                : GestorUserFormValues
                                        }
                                        buttons={FormButtons}
                                        lang={defaultLang}
                                        formStructure={FormCreateGestorUser}
                                        focusOn={fieldRef}
                                        config={{
                                            noClearButton: !!entity?.name,
                                            editButton:
                                                !!entity?.name && detailPage
                                        }}
                                    />
                                )}
                            </>
                            {gestorUserId && detailPage && (
                                <div className='settings__grid-container__item_right__content__btn'>
                                    <button
                                        className='settings__grid-container__item_right__content__btn__delete-button item-btn subtitle'
                                        onClick={Delete}
                                    >
                                        {deleteTitle}
                                    </button>
                                </div>
                            )}
                        </div>
                        {LoadingData && <LoaderElement />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default GestorUserCreate;
