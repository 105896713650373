import './monthlyclosurecard.scss';
import { useRef, useState } from 'react';
import { useToast } from '../../../config/hooks/useToast';
import { HTTP_METHODS } from '../../../config/hooks/useCallApi/constants';
import { useCallApi } from '../../../config/hooks/useCallApi';
import ButtonBody from '../../button/button';
import { lang } from '../../langs';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { PropertyStoreInterface } from '../../../store/slices/property/property.interface';
import { PropertyUnitStoreInterface } from '../../../store/slices/property-unit/property-unit.interface';
import { useAppSelector } from '../../../store/hooks';
import { Radio, ConfigProvider, DatePicker } from 'antd';
import type { RadioChangeEvent } from 'antd';
import CloseIcon from '@material-ui/icons/Close';
import { PDFExport } from '@progress/kendo-react-pdf';
import Certificate from './certificates/certificate';
import { ApiSinaderData } from '../../../config/service';

interface Props {
    data: any;
    formattedLabels: any;
    dataIdb: any;
    filterKinds: any;
    filter: any;
    labels: any;
    colorsDB: any;
}

const MonthlyClosureCard = ({
    data,
    formattedLabels,
    dataIdb,
    filterKinds,
    filter,
    labels,
    colorsDB
}: Props) => {
    const { GetData } = useCallApi();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { selected }: PropertyStoreInterface = useAppSelector(
        state => state.property
    );
    const { selectedUnits }: PropertyUnitStoreInterface = useAppSelector(
        state => state.propertyUnit
    );
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            dataCenterComponents: {
                monthlyClosureCard: {
                    title,
                    subtitle,
                    sinader,
                    detail,
                    certificates,
                    billing,
                    downloadSinaderButton,
                    downloadButton,
                    billingButton,
                    months,
                    balanceTitle
                }
            }
        }
    } = lang;
    const [show, setShow] = useState(false);
    const [value, setValue] = useState(1);
    const pdfExportComponent = useRef<PDFExport>(null);
    const [month, setMonth] = useState<string>();
    const [monthNum, setMonthNum] = useState<number>();
    const [year, setYear] = useState<number>(2023);

    const onChange = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    };

    const ChangeDate = date => {
        const formattedMonthNum = date.format('M');
        const formattedMonth = date.format('MM');
        const year = date.$y;
        setMonth(months[formattedMonthNum - 1]);
        setMonthNum(parseInt(formattedMonth));
        setYear(year);
    };

    const DownloadCertificate = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save();
            setShow(false);
        }
    };

    const obtainMonth = () => {
        const actualDate = new Date();
        const actualDay = actualDate.getDate();

        if (actualDay >= 13) {
            actualDate.setMonth(actualDate.getMonth() - 1);
        } else {
            actualDate.setMonth(actualDate.getMonth() - 2);
        }
        const numberMonth = actualDate.getMonth() + 1;
        const numberFormat = numberMonth.toString().padStart(2, '0');
        const monthResult = months[numberMonth - 1];
        const yearResult = actualDate.getFullYear();

        return { month: monthResult, year: yearResult, monthNum: numberFormat };
    };

    const handleButton = (number: number) => {
        if (selected.length + selectedUnits.length === 0) {
            const toastMessage =
                'Debes tener al menos 1 propiedad/unidad seleccionado';
            ConfigToast.text = toastMessage;
            ConfigToast.backColor = Colors.Warning;
            setConfigToast(ConfigToast);
        } else {
            if (number === 1) {
                DownloadSinader();
            } else {
                setShow(true);
            }
        }
    };

    const DownloadSinader = async () => {
        const actualDate = new Date();
        const actualDay = actualDate.getDate();
        const dateCard = new Date(actualDate);
        if (actualDay > 13) {
            dateCard.setMonth(actualDate.getMonth() - 1);
        } else {
            dateCard.setMonth(actualDate.getMonth() - 2);
        }
        dateCard.setDate(1);
        const dateini = dateCard.toISOString().slice(0, 10);
        dateCard.setMonth(dateCard.getMonth() + 1);
        dateCard.setDate(0);
        const datefin = dateCard.toISOString().slice(0, 10);
        const data = {
            dateini,
            datefin,
            gestorname: null,
            propertyId: selected[0]?.id ?? null,
            unitId: selectedUnits[0]?.id ?? null,
            isForm: true
        };

        const response = await GetData(ApiSinaderData, HTTP_METHODS.POST, data);
        const {
            data: { filepathdownload }
        } = response;
        window.open(filepathdownload, '_blank');
    };

    return (
        <>
            <div className={`monthly-card`}>
                <ToastElement ref={toastManagerRef} />
                <div className='monthly-card__titulo-card title'>{title}</div>
                <div className='monthly-card__subtitulo-card subtitle'>
                    {subtitle}: {obtainMonth().month} {obtainMonth().year}
                </div>
                <div className='monthly-card__content'>
                    <div className='monthly-card__content__sub-section '>
                        <p className='monthly-card__content__sub-section__title subtitle'>
                            {sinader} SINADER
                        </p>
                        <p className='monthly-card__content__sub-section__detalle subtitle'>
                            {detail}
                        </p>
                        <button
                            className='button button-body--sinader disabled'
                            onClick={() => handleButton(1)}
                        >
                            {downloadSinaderButton}
                        </button>
                    </div>
                    <div className='monthly-card__content__sub-section'>
                        <p className='monthly-card__content__sub-section__title subtitle'>
                            {certificates}
                        </p>
                        <button
                            className='button button-body--sinader'
                            onClick={() => handleButton(2)}
                        >
                            {downloadButton}
                        </button>
                        {/* <ButtonBody
                        able='true'
                        kind='button button-body--sinader'
                        text={downloadButton}
                    ></ButtonBody> */}
                    </div>
                    <div className='monthly-card__content__sub-section  disabled'>
                        <p className='monthly-card__content__sub-section__title subtitle'>
                            {billing}
                        </p>
                        <ButtonBody
                            able='true'
                            kind='button button-body--sinader'
                            text={billingButton}
                        ></ButtonBody>
                    </div>
                </div>
            </div>
            {show && (
                <div className='popUp'>
                    <div className='popUp__main'>
                        <div className='popUp__main__container'>
                            <div className='popUp__main__title title'>
                                <span> {title} </span>
                                <CloseIcon
                                    className='popUp__main__title__close'
                                    onClick={() => {
                                        setShow(false);
                                    }}
                                />
                                <hr />
                            </div>
                            <div className='popUp__main__text subtitle'>
                                <div className='popUp__main__text__date-picker'>
                                    <div className='popUp__main__text__date-picker__title'>
                                        Debe seleccionar 1 mes a continuación:
                                    </div>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                DatePicker: {
                                                    colorLink: '#a2a2a3',
                                                    colorLinkActive: '#a2a2a3',
                                                    colorPrimary: '#a2a2a3',
                                                    colorPrimaryBorder:
                                                        '#a2a2a3',
                                                    colorPrimaryHover:
                                                        '#a2a2a3',
                                                    colorBgContainer: '#f3f2f5',
                                                    colorBorder: '#fff',
                                                    fontFamily: 'Roboto',
                                                    colorIcon: '#a2a2a3',
                                                    colorText: '#C4C3C5',
                                                    colorTextHeading: '#a2a2a3',
                                                    colorTextPlaceholder:
                                                        '#a2a2a3'
                                                }
                                            }
                                        }}
                                    >
                                        <DatePicker
                                            onChange={ChangeDate}
                                            picker='month'
                                        />
                                    </ConfigProvider>
                                </div>
                                <div className='popUp__main__text__radio-group'>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Radio: {
                                                    colorPrimary: '#A2A2A3',
                                                    colorBorder: '#A2A2A3',
                                                    colorPrimaryHover: '#A2A2A3'
                                                }
                                            }
                                        }}
                                    >
                                        <Radio.Group
                                            onChange={onChange}
                                            value={value}
                                        >
                                            {/* <Radio value={1}>
                                                <label className='popUp__main__text__label subtitle'>
                                                    Certificado destino final
                                                </label>
                                            </Radio> */}
                                            <Radio value={1}>
                                                <label className='popUp__main__text__label subtitle'>
                                                    {balanceTitle}
                                                </label>
                                            </Radio>
                                        </Radio.Group>
                                    </ConfigProvider>
                                </div>
                            </div>
                        </div>
                        <div className='popUp__main__buttons app-d-flex__center childs_uniform'>
                            <button
                                className={`button button-body--sinader ${
                                    !month && 'disabled'
                                }`}
                                onClick={DownloadCertificate}
                            >
                                Descargar certificado
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {selected.length + selectedUnits.length > 0 && month && (
                <Certificate
                    data={data}
                    formattedLabels={formattedLabels}
                    dataIdb={dataIdb}
                    ecoFilterKinds={filterKinds}
                    filter={filter}
                    labels={labels}
                    colorsDB={colorsDB}
                    pdfExportRef={pdfExportComponent}
                    name={[...selected, ...selectedUnits].map(
                        item => item.name
                    )}
                    propertyId={selected.map(property => property.id)}
                    propertyUnitId={selectedUnits.map(unit => unit.id)}
                    month={`${month}`}
                    monthNumber={`${monthNum}`}
                    kind={value}
                    year={year}
                />
            )}
        </>
    );
};

export default MonthlyClosureCard;
