import { createSlice } from '@reduxjs/toolkit';
import { ApplicationDefault } from './initialvalues';

export const applicationSlice = createSlice({
    name: 'application',
    initialState: ApplicationDefault,
    reducers: {
        setIDBDCDefaultDatabase: (state, action) => {
            const data = action.payload;
            state.idbDCDefaulDatabase = data;
        },
        setIDBDCBackgroundDatabase: (state, action) => {
            const data = action.payload;
            state.idbDCBackgroudDatabase = data;
        }
    }
});

export const {
    setIDBDCDefaultDatabase,
    setIDBDCBackgroundDatabase
} = applicationSlice.actions;

export default applicationSlice.reducer;