import { FormBuilderFields } from '../../components/formBuilder/form-builder.interface';

export const FormProfile: FormBuilderFields[] = [
    {
        label: 'Nombre',
        fieldNameId: 'name',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el nombre',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Apellido',
        fieldNameId: 'lastName',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el apellido',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Rut (sin puntos y sin guión)',
        fieldNameId: 'rut',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el rut',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Teléfono',
        fieldNameId: 'phone',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el teléfono',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 20,
                state: true,
                message: 'Máximo 20 caracteres'
            }
        }
    }
];

export const FormChangePass: FormBuilderFields[] = [
    {
        label: 'Contraseña anterior',
        fieldNameId: 'lastPassword',
        fieldType: 'password',
        fieldPlaceholder: 'Ingrese su contraseña anterior',
        isFocusInClean: true,
        autoComplete: 'off',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Contraseña nueva',
        fieldNameId: 'newPassword',
        fieldType: 'password',
        fieldPlaceholder: 'Ingrese su contraseña nueva',
        isFocusInClean: true,
        autoComplete: 'off',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    },
    {
        label: 'Contraseña nueva',
        fieldNameId: 'newPasswordConfirm',
        fieldType: 'password',
        fieldPlaceholder: 'Repita su contraseña nueva',
        isFocusInClean: true,
        autoComplete: 'off',
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 40,
                state: true,
                message: 'Máximo 40 caracteres'
            }
        }
    }
];
