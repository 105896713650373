import { ReactNode } from 'react';
import './index.scss';

interface Props {
    children: ReactNode;
}

const OauthWrapper = ({ children }: Props) => {
    return <div className='oauth-wrapper'>{children}</div>;
};

export default OauthWrapper;
