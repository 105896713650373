import '../components/components.scss';
import { useEffect, useState } from 'react';
import ChartFormated from '../../../monthlyEvolutionCard/components/chartFormated';
import EcoequivalencesCard from '../../../ecoequivalencesCard/ecoequivalencesCard';
import { lang } from '../../../../langs';
import { UserStoreInterface } from '../../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../../store/hooks';
import TableSummary from '../components/summary-table';
import { useCallApi } from '../../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../../config/hooks/useCallApi/constants';
import { ApiRequirementCertificates } from '../../../../../config/service';

interface TableItem {
    finalDisposal: string;
    gestorId: string;
    gestorName: string;
    materialId: string;
    materialName: string;
    total: string;
}

interface Props {
    data: any;
    dataIdb: any;
    name: string[];
    propertyId?: string[];
    propertyUnitId?: string[];
    month: string;
    monthNumber?: string;
    ecoFilterKinds: any;
    filter: any;
    colorsDB: any;
    year: number;
}

const Balance = ({
    data,
    dataIdb,
    ecoFilterKinds,
    filter,
    colorsDB,
    name,
    propertyId,
    propertyUnitId,
    month,
    monthNumber,
    year
}: Props) => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            dataCenterComponents: {
                monthlyClosureCard: {
                    months,
                    certificatesTxts: {
                        rut,
                        address,
                        web,
                        footerTxt,
                        finalDisposal: { tableTitle },
                        balance: {
                            title,
                            text1a,
                            text1b,
                            chartTitle,
                            ecoeqTitle
                        }
                    }
                }
            }
        }
    } = lang;
    const { GetData } = useCallApi();
    const [tableData, setTableData] = useState<TableItem[]>([]);
    const [historicLabels, setHistoricLabels] = useState<string[]>([]);
    const [formattedLabels, setFormattedLabels] = useState<string[]>([]);
    const [periodData, setPeriodData] = useState<any>();

    const GetMaterials = async () => {
        const values = {
            propertyId: propertyId ?? null,
            propertyUnitId: propertyUnitId ?? null,
            dateYear: year,
            dateMont: monthNumber /* '01' */
        };
        console.log(values);
        const response = await GetData(
            ApiRequirementCertificates,
            HTTP_METHODS.POST,
            values
        );
        response.data.sort((a, b) =>
            a.materialColor.localeCompare(b.materialColor)
        );
        setTableData(response.data);
    };

    useEffect(() => {
        GetMaterials();
    }, [monthNumber, year, propertyId, propertyUnitId]);

    const fechaActual = new Date();
    const fechaActualFormateada = fechaActual.toLocaleString();

    useEffect(() => {
        const month =
            monthNumber && parseInt(monthNumber) < 10
                ? `0${monthNumber}`
                : `${monthNumber}`;
        const targetLabel = `${year}${month}`;
        const targetIndex = parseInt(data.labels.indexOf(targetLabel));
        let last12Labels;
        let datasetsLast12;
        if (targetIndex === -1 || targetIndex < 11) {
            last12Labels = data.labels.slice(0, targetIndex);
            datasetsLast12 = data.datasets.map(dataset => ({
                ...dataset,
                data: dataset.data.slice(0, targetIndex)
            }));
        } else {
            last12Labels = data.labels.slice(targetIndex - 11, targetIndex + 1);
            datasetsLast12 = data.datasets.map(dataset => ({
                ...dataset,
                data: dataset.data.slice(targetIndex - 11, targetIndex + 1)
            }));
        }
        setHistoricLabels(data.labels.slice(0, targetIndex + 1));
        formatLabelsShow(last12Labels);
        const selectedData = {
            labels: last12Labels,
            datasets: datasetsLast12
        };
        setPeriodData(selectedData);
    }, [monthNumber, year]);

    const formatLabelsShow = labelsArray => {
        const formatted = labelsArray.map(label => {
            const month = label.substring(4, 6);
            return `${months[parseInt(month) - 1].slice(0, 1)}`;
        });
        setFormattedLabels(formatted);
    };

    return (
        <div className='certificate'>
            <div className='certificate__header-balance'>
                <div className='certificate__header-balance__info'>
                    <div className='certificate__header-balance__info__text'>
                        <h2>
                            {' '}
                            {rut} | {address} | {web}{' '}
                        </h2>
                        <h2> Descargado el: {fechaActualFormateada} </h2>
                    </div>
                    <div className='certificate__header-balance__info__logo'>
                        <img
                            className='certificate__header-balance__info__logo__img'
                            src='/certificates/pryma.png'
                        />
                    </div>
                </div>
                <div className='certificate__header-balance__title'>
                    <div className='certificate__header-balance__title__cert-name'>
                        {title} | {month} {year}
                    </div>
                    <div className='certificate__header-balance__title__property-name'>
                        {name.map(item => (
                            <div
                                className='certificate__header-balance__title__property-name__item'
                                key={item}
                            >
                                {item}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='certificate__section'>
                <p>
                    {' '}
                    {text1a} {month} {year} {text1b}{' '}
                </p>
            </div>
            <div className='certificate__section'>
                <h1 className='certificate__section__title'> {tableTitle} </h1>
                <div className='certificate__section__table'>
                    <TableSummary data={tableData} />
                </div>
            </div>
            <div className='page-break'></div>
            <div className='certificate__section'>
                <h1 className='certificate__section__title'> {chartTitle} </h1>
                <div className='certificate__section__chart'>
                    {periodData && (
                        <ChartFormated
                            data={periodData}
                            formattedLabels={formattedLabels}
                        />
                    )}
                </div>
            </div>
            <div className='certificate__section'>
                <h1 className='certificate__section__title'> {ecoeqTitle} </h1>
                <div className='certificate__section__ecoeq'>
                    <EcoequivalencesCard
                        data={dataIdb}
                        filterKinds={ecoFilterKinds}
                        filter={filter}
                        labels={historicLabels}
                        colorsDB={colorsDB}
                        certificate={true}
                    />
                </div>
            </div>
            <div className='certificate__section'>
                <p className='certificate__section__text'>{footerTxt}</p>
            </div>
            <div className='certificate__footer'>
                <img
                    className='certificate__footer-img'
                    src='/certificates/footer.jpg'
                />
            </div>
        </div>
    );
};

export default Balance;
