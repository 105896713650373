import './certificate.scss';
import { PDFExport } from '@progress/kendo-react-pdf';
import Balance from './kind/balance';
import FinalDisposal from './kind/finalDisposal';

interface Props {
    data: any;
    formattedLabels: any;
    dataIdb: any;
    pdfExportRef: any;
    name: string[];
    propertyId?: string[];
    propertyUnitId?: string[];
    month: string;
    monthNumber?: string;
    kind: number;
    ecoFilterKinds: any;
    filter: any;
    labels: any;
    colorsDB: any;
    year: number;
}

const Certificate = ({
    data,
    formattedLabels,
    dataIdb,
    pdfExportRef,
    name,
    propertyId,
    propertyUnitId,
    month,
    monthNumber,
    kind,
    ecoFilterKinds,
    filter,
    labels,
    colorsDB,
    year
}: Props) => {
    return (
        <div className='pdf-container'>
            <PDFExport
                margin={{ top: '0.8cm', left: 0, right: 0, bottom: 0 }}
                forcePageBreak={undefined}
                ref={pdfExportRef}
                fileName={
                    kind === 2 ? 'cert_destino_final.pdf' : 'cert_balance'
                }
            >
                {kind === 2 ? (
                    <FinalDisposal
                        data={data}
                        formattedLabels={formattedLabels}
                        name={name}
                        month={month}
                    />
                ) : (
                    <Balance
                        data={data}
                        dataIdb={dataIdb}
                        name={name}
                        propertyId={propertyId}
                        propertyUnitId={propertyUnitId}
                        month={month}
                        monthNumber={monthNumber}
                        ecoFilterKinds={ecoFilterKinds}
                        filter={filter}
                        colorsDB={colorsDB}
                        year={year}
                    />
                )}
            </PDFExport>
        </div>
    );
};

export default Certificate;
