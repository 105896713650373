export const lang = {
    es: {
        title: 'Clases de Materiales',
        buttonNew: 'Crear clase de material',
        detailsTitle: 'Materiales asociados',
        createTitle: 'Crear clase de material',
        editTitle: 'Editar clase de material',
        deleteMessage:
            'Esta acción eliminará los materiales asociados, desea eliminar este registro?'
    },
    en: {
        title: 'Material Kind',
        buttonNew: 'Create Material Kind',
        detailsTitle: 'Associated Materials',
        createTitle: 'Create Material Kind',
        editTitle: 'Edit Material Kind',
        deleteMessage:
            'This action will delete the associated materials, do you want to delete this record?'
    }
};

export const constantsTable = {
    keysFilter: ['name', 'color', 'codeLer']
};
