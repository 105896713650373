export const lang = {
    es: {
        logout: {
            title: 'Cerrar sesión',
            message: 'Desea cerrar la sesión?',
            okButton: 'Aceptar',
            cancelButton: 'Cancelar'
        },
        menuPage: {
            notificationsTitle: 'Notificaciones',
            helpTitle: 'Ayuda',
            homeTitle: 'Inicio'
        }
    },
    en: {
        logout: {
            title: 'Close sesion',
            message: 'Do you want to close the session?',
            okButton: 'Accept',
            cancelButton: 'Cancel'
        },
        menuPage: {
            notificationsTitle: 'Notifications',
            helpTitle: 'Help',
            homeTitle: 'Home'
        }
    }
};
