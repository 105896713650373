import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './config/routes';
import { Provider } from 'react-redux';
import 'antd/dist/reset.css';
import { store } from './store';
import { Globals } from '../src/config/utils/Constants';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    process.env.REACT_APP_PUBLIC_ENVIRONMENT !== Globals.desaEnv ||
        process.env.REACT_APP_PUBLIC_ENVIRONMENT !== Globals.prodEnv ? (
        <React.Suspense>
            <Provider store={store}>
                <App />
            </Provider>
        </React.Suspense>
    ) : (
        <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </React.StrictMode>
    )
);

if ('serviceWorker' in navigator) {
    if (navigator.serviceWorker) {
        navigator.serviceWorker.register('/service-worker.js')
        // console.log('llamar a service worker');
    }
}
