/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Button, Form, Cascader, ConfigProvider } from 'antd';
import type { SelectProps } from 'antd';
import { Wrapper } from '../styles';
import { UserFormikInterface } from '../../interface';
import {
    deffaulltButtons,
    FormBuilderFields,
    FormPropsInterface
} from './form-builder.interface';
//  <--FORMS--> //
import { Formik } from 'formik';
import './form.scss';
//  eslint-disable-next-line @typescript-eslint/no-var-requires
const yup = require('yup');

const defaults = {
    autocomplete: 'off',
    required: true,
    buttons: deffaulltButtons
};
let schema = yup.object();
let CleanForm;

const FormBuilder = ({
    initialValues,
    formStructure,
    focusOn,
    buttons,
    styles,
    config,
    onSuccessCleanForm = true,
    onSelect
}: FormPropsInterface) => {
    const [ready, setReady] = useState<boolean>(false);
    const [sendForm, setSendForm] = useState<boolean>(false);
    const [form, setForm] = useState<UserFormikInterface>({});
    const [newFormStructure, setNewFormStructure] = useState<
        FormBuilderFields[]
    >([]);
    const [removed, setRemoved] = useState<FormBuilderFields[]>([]);
    const [checkboxes, setCheckboxes] = useState<number[]>([]);

    useEffect(() => {
        setForm(initialValues);
    }, [initialValues]);

    /**
     * @description
     * This effect clean the schema to create a new one
     */
    useEffect(() => {
        schema = yup.object({});
    }, []);

    /**
     * @description
     * This effect create the schema to validate the form, based on the formStructure
     */
    useEffect(() => {
        for (let i = 0; i < formStructure.length; i++) {
            const item = formStructure[i];
            if (item.depends) {
                //  const extract = formStructure.find(x => x.depends !== undefined);
                const save = formStructure.filter(function (item) {
                    return item.depends === undefined;
                });
                const remove = formStructure.filter(function (item) {
                    return item.depends !== undefined;
                });
                setRemoved(remove);
                setNewFormStructure(save);
            } else {
                setNewFormStructure(formStructure);
            }
        }
    }, [formStructure]);

    useEffect(() => {
        if (newFormStructure.length > 0) {
            for (let i = 0; i < newFormStructure.length; i++) {
                const item = newFormStructure[i];
                const {
                    validations: { type, required, min, max, email }
                } = item;
                const obj = {};
                if (required.state) {
                    if (type === 'string') {
                        obj[item.fieldNameId] = yup
                            .string()
                            .required(required.message);
                        min &&
                            (obj[item.fieldNameId] = obj[item.fieldNameId].min(
                                min.length,
                                min.message
                            ));
                        max &&
                            (obj[item.fieldNameId] = obj[item.fieldNameId].max(
                                max.length,
                                max.message
                            ));
                        email &&
                            (obj[item.fieldNameId] = obj[
                                item.fieldNameId
                            ].email(email.message));
                    } else {
                        obj[item.fieldNameId] = yup.number().nullable();
                    }
                }
                schema = yup.object({ ...schema.fields, ...obj });
            }
            setReady(true);
        }
    }, [newFormStructure]);

    /**
     * @description
     * This function send the form data to the parent component
     * @function Submit
     * @param dataSend
     */
    const Submit = (dataSend: any) => {
        buttons?.ok.controller(dataSend);
        CleanForm();
        setSendForm(true);
    };

    useEffect(() => {
        if (sendForm && onSuccessCleanForm) {
            CleanForm();
        }
    }, [sendForm, onSuccessCleanForm]);

    /**
     * @description
     * This function send the cancel function to the parent component to set focus in element and clear form
     * @function Cancel
     * @param OnCancel
     */
    const Cancel = (OnCancel: any) => {
        OnCancel();
        buttons?.cancel && buttons?.cancel.controller(focusOn);
        CleanForm();
    };

    const onHandleChange = (item, event) => {
        const { elements } = item;
        const defValues = item.deffaultValues;
        let selected;
        if (defValues === undefined) {
            selected = elements.find(
                sel => sel.id === event.target.value
            )?.name;
        } else {
            const isTrueSet = event.target.value === 'true';
            selected = elements.find(sel => sel.id === isTrueSet)?.id;
        }
        const extract = removed;
        extract.forEach(extractItem => {
            if (!item.depends) {
                const dependsValues = extractItem.dependsValues;
                const exist =
                    dependsValues?.length && dependsValues.indexOf(selected);
                const restructured: FormBuilderFields[] = newFormStructure;
                const extractIndex = formStructure.findIndex(
                    x => x.fieldNameId === extractItem.fieldNameId
                );
                if (exist ? exist >= 0 : exist === 0) {
                    restructured[extractIndex] = extractItem;
                    setNewFormStructure(restructured);
                } else {
                    //  eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                    delete restructured[extractIndex];
                    setNewFormStructure(restructured);
                }
            }
        });
        if (item.fieldNameId === 'property' && event.target.value !== '') {
            const units = elements.find(
                sel => sel.id === event.target.value
            )?.propertyUnits;
            if (onSelect) {
                onSelect(units);
            }
        }
    };

    const checkboxChange = event => {
        const checkboxValue = parseInt(event.target.value, 10);
        const updatedCheckboxes = [...checkboxes];
        if (event.target.checked) {
            updatedCheckboxes.push(checkboxValue);
        } else {
            const index = updatedCheckboxes.indexOf(checkboxValue);
            if (index !== -1) {
                updatedCheckboxes.splice(index, 1);
            }
        }
        setCheckboxes(updatedCheckboxes);
    };

    const RenderControls = (item, handleChange, values, errors) => {
        let options: SelectProps['options'];
        switch (item.fieldType) {
            case 'select multiple':
                options = [];
                for (const element of item.elements) {
                    options.push({
                        value: element.id,
                        label: element.name
                    });
                }
                return (
                    <ConfigProvider
                        theme={{
                            components: {
                                Cascader: {
                                    colorBgContainer: '#f3f2f5',
                                    colorBgContainerDisabled: '#f3f2f5',
                                    controlItemBgHover: '#f3f2f5',
                                    colorBgElevated: '#f3f2f5'
                                }
                            }
                        }}
                    >
                        <Cascader
                            className='subtitle grey'
                            size='large'
                            style={{ width: '100%' }}
                            options={options}
                            onChange={selectedValues => {
                                handleChange({
                                    target: {
                                        name: item.fieldNameId,
                                        value: selectedValues.flat()
                                    }
                                });
                            }}
                            multiple
                            maxTagCount='responsive'
                            placeholder={item.fieldPlaceholder}
                        />
                    </ConfigProvider>
                );
            case 'select':
                return (
                    <select
                        required={
                            item.required ? item.required : defaults.required
                        }
                        onChange={e => {
                            onHandleChange(item, e);
                            handleChange(e);
                        }}
                        name={item.fieldNameId}
                        value={values[item.fieldNameId]}
                        className={
                            errors[item.fieldNameId]
                                ? 'form-control invalid subtitle'
                                : config?.editButton
                                ? 'form-control preview subtitle'
                                : item.editable === false
                                ? 'form-control preview subtitle'
                                : 'form-control subtitle'
                        }
                        ref={item.isFocusInClean ? focusOn : null}
                        disabled={item.disabled || config?.editButton}
                    >
                        <option key='0' value=''>
                            Seleccione una opción
                        </option>
                        {item.elements.map((element: any) => {
                            return (
                                <option key={element.id} value={element.id}>
                                    {element.name
                                        ? element.name
                                        : element.description}
                                </option>
                            );
                        })}
                    </select>
                );
            case 'boolean':
                return (
                    <div className='form-radio-buttons'>
                        {item.elements.map((element: any) => (
                            <label
                                key={element.id}
                                className='form-radio-buttons__item subtitle'
                            >
                                {config?.editButton ? (
                                    <input
                                        className='form-radio-buttons__item__btn'
                                        type='radio'
                                        required={
                                            item.required
                                                ? item.required
                                                : defaults.required
                                        }
                                        onChange={handleChange}
                                        name={item.fieldNameId}
                                        ref={
                                            item.isFocusInClean ? focusOn : null
                                        }
                                        disabled={
                                            item.disabled || config?.editButton
                                        }
                                        checked={
                                            values[item.fieldNameId] ===
                                            element.id
                                        }
                                    />
                                ) : (
                                    <input
                                        className='form-radio-buttons__item__btn'
                                        type='radio'
                                        required={
                                            item.required
                                                ? item.required
                                                : defaults.required
                                        }
                                        onChange={handleChange}
                                        name={item.fieldNameId}
                                        value={element.name}
                                        ref={
                                            item.isFocusInClean ? focusOn : null
                                        }
                                        disabled={
                                            item.disabled || config?.editButton
                                        }
                                    />
                                )}
                                <div className='form-radio-buttons__item__name'>
                                    {element.name}
                                </div>
                            </label>
                        ))}
                    </div>
                );
            case 'checkbox':
                return (
                    <div className='form-checkboxes'>
                        {item.elements.map((element: any) => (
                            <label
                                key={element.id}
                                className='form-radio-buttons__item subtitle'
                            >
                                {config?.editButton ? (
                                    <input
                                        className='form-radio-buttons__item__btn'
                                        type='checkbox'
                                        required={
                                            item.required
                                                ? item.required
                                                : defaults.required
                                        }
                                        name={item.fieldNameId}
                                        ref={
                                            item.isFocusInClean ? focusOn : null
                                        }
                                        disabled={
                                            item.disabled || config?.editButton
                                        }
                                        checked={values[
                                            item.fieldNameId
                                        ].includes(element.id)}
                                    />
                                ) : (
                                    <input
                                        className='form-radio-buttons__item__btn'
                                        type='checkbox'
                                        required={
                                            item.required
                                                ? item.required
                                                : defaults.required
                                        }
                                        onChange={e => {
                                            checkboxChange(e);
                                            handleChange(e);
                                        }}
                                        name={item.fieldNameId}
                                        value={element.id}
                                        ref={
                                            item.isFocusInClean ? focusOn : null
                                        }
                                        disabled={
                                            item.disabled || config?.editButton
                                        }
                                    />
                                )}
                                <div className='form-radio-buttons__item__name'>
                                    {element.name}
                                </div>
                            </label>
                        ))}
                    </div>
                );
            default:
                return (
                    <input
                        required={
                            item.required ? item.required : defaults.required
                        }
                        onChange={handleChange}
                        autoComplete={
                            item.autoComplete
                                ? item.autoComplete
                                : defaults.autocomplete
                        }
                        placeholder={
                            item.fieldPlaceholder
                                ? item.fieldPlaceholder
                                : item.label
                        }
                        type={item.fieldType ? item.fieldType : 'text'}
                        name={item.fieldNameId}
                        value={values[item.fieldNameId]}
                        className={
                            errors[item.fieldNameId]
                                ? 'form-control invalid subtitle'
                                : config?.editButton
                                ? 'form-control preview subtitle'
                                : 'form-control subtitle'
                        }
                        ref={item.isFocusInClean ? focusOn : null}
                        disabled={item.disabled || config?.editButton}
                    />
                );
        }
    };

    return ready ? (
        <div className='formbuilder subsubtitle' style={styles}>
            <Formik
                enableReinitialize
                validationSchema={schema}
                onSubmit={(dataForm: any) => {
                    Submit(dataForm);
                }}
                initialValues={form}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    resetForm,
                    isSubmitting
                }: UserFormikInterface) => (
                    //  eslint-disable-next-line no-sequences
                    (CleanForm = resetForm),
                    (
                        <Form
                            className='formbuilder__form subtitle'
                            noValidate
                            onFinish={dataForm => {
                                handleSubmit(dataForm);
                                //  setTimeout(()=>{
                                //      resetForm()
                                //  },500)
                            }}
                        >
                            {newFormStructure.map(
                                (item: FormBuilderFields, index: number) => (
                                    //  item.depends ? item.dependsShow &&
                                    <div
                                        key={`form_item-${index}`}
                                        className={
                                            item.show !== undefined &&
                                            !item.show &&
                                            item.disabled
                                                ? 'noshowelement disabled mb-3 subtitle'
                                                : 'enabled mb-3 subtitle'
                                        }
                                    >
                                        {!config?.noLabels && (
                                            <label
                                                className={
                                                    item.disabled
                                                        ? 'disabled subtitle'
                                                        : 'enabled subtitle'
                                                }
                                                htmlFor={item.fieldNameId}
                                            >
                                                {item.label}
                                            </label>
                                        )}
                                        {RenderControls(
                                            item,
                                            handleChange,
                                            values,
                                            errors
                                        )}
                                        <div className='invalid-feedback'>
                                            {errors[item.fieldNameId]}
                                        </div>
                                    </div>
                                )
                            )}
                            <Wrapper
                                className={
                                    buttons.buttonsWrapperClass
                                        ? buttons.buttonsWrapperClass
                                        : defaults.buttons.buttonsWrapperClass
                                }
                            >
                                {!config?.noClearButton && (
                                    <Button
                                        className={`${
                                            buttons?.cancel?.class ??
                                            defaults.buttons.cancel?.class
                                        } subtitle`}
                                        onClick={() => Cancel(resetForm)}
                                    >
                                        {buttons?.cancel && buttons?.cancel.text
                                            ? buttons?.cancel.text
                                            : defaults.buttons.cancel?.text}
                                    </Button>
                                )}
                                {!config?.editButton && (
                                    <Button
                                        className={`${
                                            buttons?.ok?.class ??
                                            defaults.buttons.ok?.class
                                        } subtitle`}
                                        htmlType='submit'
                                    >
                                        {buttons?.ok.text
                                            ? buttons?.ok.text
                                            : defaults.buttons.ok.text}
                                    </Button>
                                )}
                            </Wrapper>
                        </Form>
                    )
                )}
            </Formik>
        </div>
    ) : (
        <>
            <span>rdy formik {ready.toString()} </span>
        </>
    );
};

export default FormBuilder;
