import { GetStorage } from '../../../config/utils/Storage';
import { PropertyStoreInterface } from './property.interface';

const CCData = GetStorage(process.env.REACT_APP_PUBLIC_USER_PROPERTIES);
const dataIni = [];

export const PropertyDefault: PropertyStoreInterface = {
    propertyData: CCData || dataIni,
    selected: []
};
