import { useEffect, useState } from 'react';
import { StyledSlider } from './styled.slider';
import {
    MarksInterface,
    PropsInterface,
    ResultsInterface
} from './rangeDateFilter.interface';
import './style.scss';

const step = 1;

const RangeDateFilter = ({
    marks: defaultMarks,
    min,
    max,
    deffaultValues,
    results
}: PropsInterface) => {
    const [value, setValue] = useState<number[]>([]);
    const [marks, setMarks] = useState<MarksInterface[]>([]);
    const [ready, setReady] = useState<boolean>(false);

    useEffect(() => {
        if (defaultMarks) {
            setMarks(defaultMarks);
            if (min && max) {
                setValue([min, max]);
            } else {
                const defaultValues = [
                    defaultMarks[0].value,
                    defaultMarks[defaultMarks.length - 1].value
                ];
                setValue(defaultValues);
            }
            setReady(true);
        }
    }, [defaultMarks, min, max]);

    useEffect(() => {
        if (!Array.isArray(value)) {
            return;
        }
        if (marks.length > 0) {
            const indexIni = value[0];
            const indexFin = value[1];
            results({
                start: marks[indexIni].label,
                end: marks[indexFin].label
            });
        }
    }, [value]);

    const handleChange = (
        event: Event,
        newValue: number | number[],
        activeThumb: number
    ) => {
        if (!Array.isArray(newValue)) {
            return;
        }
        if (activeThumb === 0) {
            const res = [Math.min(newValue[0], value[1] - step), value[1]];
            if (res.toString() !== value.toString()) {
                setValue(res);
            }
        } else {
            const res = [value[0], Math.max(newValue[1], value[0] + step)];
            if (res.toString() !== value.toString()) {
                setValue(res);
            }
        }
    };

    const valueLabelFormat = (value: number) => {
        const text = marks[value].label;
        return text;
    };

    return (
        <div>
            {/* <p> {JSON.stringify(marks)} </p>
            <p> {value} </p>
            <p> {value[0]} - {value[1]} </p> */}
            {ready ? (
                <>
                    <StyledSlider
                        onChange={handleChange}
                        // color="secondary"
                        marks={marks}
                        value={value}
                        disableSwap
                        min={marks[0].value}
                        max={marks.length - 1}
                        valueLabelDisplay='auto'
                        getAriaLabel={() => 'Minimum distance'}
                        getAriaValueText={valueLabelFormat}
                        valueLabelFormat={valueLabelFormat}
                        aria-labelledby='discrete-slider-always'
                    />
                </>
            ) : (
                <p>Cargando componente...</p>
            )}
        </div>
    );
};

export { RangeDateFilter };
export type { MarksInterface, ResultsInterface };
