import '../../settings.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate, Link } from 'react-router-dom';
//  <--COMPONENTS--> //
import SubNavBar from '../../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../../components/header/header';
import ClientsTableComponent from '../components/clients-table';
import FormBuilder from '../../../../components/formBuilder/formBuilder';
import ButtonBody from '../../../../components/button/button';
//  <--HOOKS--> //
import { useToast } from '../../../../config/hooks/useToast';
import { useAlert } from '../../../../config/hooks/useAlert';
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
//  <--INTERFACE--> //
import {
    ClientFormCreateInterface,
    ClientFormValues
} from '../../../../interface';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';
//  <--SERVICES--> //
import {
    ApiClient,
    ApiClientId,
    ApiClientLogo
} from '../../../../config/service';
//  <--OTHERS--> //
import { lang } from '../../../langs';
import { FormCreateClient } from './form';
import '../../../../scss/global/global.scss';

const ClientCreate = () => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        AlertElement,
        alertManagerRef,
        ConfigAlert,
        setConfigAlert,
        Defaults
    } = useAlert({ defaultLang });
    const fieldRef = useRef(null);
    const { id } = useParams();
    const {
        [defaultLang as keyof typeof lang]: {
            pageClient: {
                title,
                adminTitle,
                createTitle,
                editTitle,
                detailTitle,
                deleteMessage,
                deleteTitle,
                usersBtn,
                propertiesBtn,
                uploadBtn
            }
        }
    } = lang;
    const [entityId, setEntityId] = useState<string>('');
    const [entity, setEntity] = useState<ClientFormCreateInterface>();
    const [readyForm, setReadyForm] = useState<boolean>(false);
    const [detailPage, setDetailPage] = useState<boolean>(true);
    const navigate = useNavigate();
    const [updates, setUpdates] = useState<boolean>(false);

    /**
     * @description this effect verify if exist id in url params, if exist, set the id in state, else set readyForm to true
     */
    useEffect(() => {
        setReadyForm(false);
        if (id) {
            setEntityId(id);
        } else {
            setEntity(ClientFormValues);
            setReadyForm(false);
            setTimeout(() => {
                setReadyForm(true);
            }, 50);
        }
    }, [id]);

    /**
     * @description CallDataEdit function, call the api to get the data of the entity and set the data in state
     */
    const CallDataEdit = async () => {
        const response = await GetData(ApiClientId(entityId), HTTP_METHODS.GET);
        const { data, status } = response;
        setEntity(data);
        setReadyForm(true);
        if (!status) {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description this effect check that entityId is not empty and if it changes, if not empty, call the function to CallDataEdit
     */
    useEffect(() => {
        if (entityId !== '') {
            CallDataEdit();
            setDetailPage(true);
        }
    }, [entityId]);

    /**
     * @description
     * @function OnSubmit is a function that is called when the form is submitted
     * @param values is a object that contains the data of the form
     */
    const OnSubmit = async (values: ClientFormCreateInterface) => {
        const response = id
            ? await GetData(ApiClientId(entityId), HTTP_METHODS.PATCH, values)
            : await GetData(ApiClient, HTTP_METHODS.POST, values);
        const { message, status } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
            setReadyForm(false);
            CallDataEdit();
            setUpdates(true);
        } else {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    /**
     * @description
     * @function FocusOnInput is a function that is called when the form's cancel button is clicked
     * @param ref is a reference to the form
     */
    const FocusOnInput = (ref: any) => {
        ref.current.focus();
    };

    /**
     * @description
     * @constant FormButtons is a object that contains the buttons of the form
     */
    const FormButtons = {
        ok: {
            controller: OnSubmit
        },
        cancel: {
            controller: FocusOnInput
        }
    };

    const Edit = () => {
        setDetailPage(false);
    };

    /**
     * @description function that is executed when the delete button is pressed
     * @function Delete is a function that launches an alert to confirm the deletion of a COMPANY
     * @param id is a string that contains the id of the COMPANY to be deleted
     */
    const Delete = async () => {
        ConfigAlert.title = Defaults.titles.Delete;
        ConfigAlert.message = deleteMessage;
        ConfigAlert.okButtonText = Defaults.buttons.Ok.Accept;
        ConfigAlert.okButtonController = { func: ConfirmDelete, param: id };
        ConfigAlert.cancelButtonText = Defaults.buttons.Cancel.Cancel;
        setConfigAlert(ConfigAlert);
    };

    /**
     * @description function that is executed when the okDelete button is pressed
     * @function Delete is a function that deletes a COMPANY
     * @param id is a string that contains the id of the COMPANY to be deleted
     */
    const ConfirmDelete = async (id: string) => {
        const response = await GetData(ApiClientId(id), HTTP_METHODS.DELETE);
        const { data, status, message } = response;
        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            setConfigToast(ConfigToast);
            navigate('/panel/clients');
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
        }
    };

    useEffect(() => {
        setUpdates(false);
    }, [entity]);

    /**
     * @description function that is executed when the detail button is pressed
     * @function ShowTrucks is a function that opens the detail modal and calls the function that returns the USER roles data
     * @param id is a string that contains the id of the USER to be consulted
     */
    /* const ShowUserRoles = async (id: string) => {
        setRoles([]);
        setShowRoles(true);
    }; */

    const SubmitImage = async event => {
        event.preventDefault();
        const formData = new FormData();
        const imagefile: any = document.querySelector('#file');
        formData.append(
            'client-logo',
            imagefile !== null ? imagefile.files[0] : ''
        );
        if (id) {
            await GetData(ApiClientLogo(id), HTTP_METHODS.POST, formData, true);
        }
    };

    return (
        <>
            <ToastElement ref={toastManagerRef} />
            <AlertElement ref={alertManagerRef} />
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/ClientIcon.svg'
                kind='settings'
                option='Client'
            ></SubNavBar>
            <div className='settings'>
                <Header
                    title={{ name: title, link: '/panel/clients' }}
                    icon='ClientsIconB.svg'
                    subtitle={{ name: id ? editTitle : createTitle }}
                />
                <div className='settings__grid-container'>
                    <div className='settings__grid-container__item'>
                        <div className='settings__grid-container__item__title title'>
                            {adminTitle}
                        </div>
                        <ClientsTableComponent refresh={updates} />
                        <div className='settings__grid-container__item__content'>
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={createTitle}
                                path='/panel/clients/create'
                            ></ButtonBody>
                        </div>
                    </div>
                    <div className='settings__grid-container__item_right'>
                        <div className='settings__grid-container__item_right__title title'>
                            {id ? (
                                <div>
                                    {detailPage ? detailTitle : editTitle}
                                </div>
                            ) : (
                                <div>{createTitle}</div>
                            )}
                        </div>
                        <div className='settings__grid-container__item_right__content'>
                            {readyForm && (
                                <FormBuilder
                                    initialValues={
                                        entity?.name ? entity : ClientFormValues
                                    }
                                    buttons={FormButtons}
                                    lang={defaultLang}
                                    formStructure={FormCreateClient}
                                    focusOn={fieldRef}
                                    config={{
                                        noClearButton: !!entity?.name,
                                        editButton: !!entity?.name && detailPage
                                    }}
                                />
                            )}
                            {id && detailPage && (
                                <>
                                    <div className='settings__grid-container__item_right__content__btn'>
                                        <Link
                                            className='settings__grid-container__item_right__content__btn__delete-button item-btn subtitle'
                                            to={'/panel/invitations/create'}
                                        >
                                            {usersBtn}
                                        </Link>
                                        <Link
                                            className='settings__grid-container__item_right__content__btn__delete-button item-btn subtitle'
                                            to={`/panel/clients/${id}/properties`}
                                        >
                                            {propertiesBtn}
                                        </Link>
                                    </div>
                                    <div className='settings__grid-container__item_right__content__btn'>
                                        <button
                                            className='settings__grid-container__item_right__content__btn__delete-button item-btn subtitle'
                                            onClick={Delete}
                                        >
                                            {deleteTitle}
                                        </button>
                                        <button
                                            className='settings__grid-container__item_right__content__btn__edit-button item-btn subttile'
                                            onClick={Edit}
                                        >
                                            {editTitle}
                                        </button>
                                    </div>
                                </>
                            )}
                            {LoadingData && <LoaderElement />}
                        </div>
                        {id && (
                            <div className='settings__grid-container__item_right__logo'>
                                <div className='settings__grid-container__item_right__logo__imgContainer '>
                                    <img
                                        className='settings__grid-container__item_right__logo__imgContainer__img '
                                        src={`${
                                            entity?.image
                                                ? entity.image
                                                : '/svg-icons/ProfileIcon.svg'
                                        }`}
                                    />
                                </div>
                                <div className='settings__grid-container__item_right__logo__text subtitle '>
                                    Logotipo
                                </div>
                                <form
                                    onSubmit={SubmitImage}
                                    className='settings__grid-container__item_right__logo__btns subtitle '
                                >
                                    <label
                                        htmlFor='file'
                                        className='settings__grid-container__item_right__logo__btns__select'
                                    >
                                        <span>Seleccionar archivo</span>
                                        <input
                                            type='file'
                                            id='file'
                                            name='file'
                                            style={{ display: 'none' }}
                                        />
                                    </label>
                                    <button
                                        className='button button-body--upload '
                                        type='submit'
                                    >
                                        {uploadBtn}
                                    </button>
                                </form>
                                {/* <label className='button button-body--upload '>
                                    <input
                                        type='file'
                                        accept='.png, .jpg'
                                        onChange={SubmitImage}
                                        style={{ display: 'none' }}
                                    />
                                    {uploadBtn}
                                </label> */}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClientCreate;
