import './ecoequivalenceItem.scss';
import { useState, useEffect } from 'react';
import { lang } from '../../../langs';
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../../store/hooks';
import useWindowDimensions from '../../../../config/hooks/useWindowDimentions';
import { Tooltip } from 'antd';

interface Props {
    logo?: string;
    name: string;
    kg: string;
    eqlabel: string;
    eq: string;
    unit: string;
    type: string;
    certificate?: boolean;
}

const EcoequivalenceItem = ({
    name,
    kg,
    eqlabel,
    eq,
    unit,
    type,
    certificate
}: Props) => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            dataCenterComponents: {
                ecoequivalencesCard: {
                    ecoequivalenceItem: { equivalentTitle }
                }
            }
        }
    } = lang;
    const { width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        setIsMobile(width <= 768);
    }, [width]);

    return (
        <div className={`ecoequivalenceitem ecocomponent ${type}`}>
            <div className='ecoequivalenceitem__value-container'>
                <div
                    className={`ecoequivalenceitem__value-container__value ${
                        !certificate && 'subtitle'
                    }`}
                >
                    {parseInt(kg).toLocaleString('es-CL', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1
                    })}{' '}
                    {unit === 'm' ? (
                        <>
                            {unit}
                            <sup>3</sup>
                        </>
                    ) : (
                        unit
                    )}
                </div>
            </div>
            <div className='ecoequivalenceitem__content'>
                <div
                    className={`ecoequivalenceitem__content__title ${
                        !certificate && 'subtitle'
                    }`}
                >
                    {name}
                </div>
                {isMobile ? (
                    <Tooltip
                        title={
                            <span className='subnavbar__content__tooltip'>
                                {equivalentTitle}
                                <span className='ecoequivalenceitem__content__detail__number'>
                                    {parseInt(eq).toLocaleString('es-CL', {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    })}
                                </span>
                                {eqlabel}
                            </span>
                        }
                        color='#fff'
                    >
                        <div
                            className={`ecoequivalenceitem__content__detail ${
                                !certificate && 'subsubtitle'
                            }`}
                        >
                            {equivalentTitle}
                            <span className='ecoequivalenceitem__content__detail__number'>
                                {parseInt(eq).toLocaleString('es-CL', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                })}
                            </span>
                            {eqlabel}
                        </div>
                    </Tooltip>
                ) : (
                    <div
                        className={`ecoequivalenceitem__content__detail ${
                            !certificate && 'subsubtitle'
                        }`}
                    >
                        {equivalentTitle}
                        <span className='ecoequivalenceitem__content__detail__number'>
                            {parseInt(eq).toLocaleString('es-CL', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            })}
                        </span>
                        {eqlabel}
                    </div>
                )}
            </div>
        </div>
    );
};

export default EcoequivalenceItem;
