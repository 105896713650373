import axios from 'axios';
import { ApiRequest } from './request.interface';

const defaultRes = {
    config: {},
    data: {},
    headers: {},
    request: {},
    status: 200,
    statusText: 'Ok',
}

export const api = async ({
    method = '',
    path = '',
    token = '',
    lang = '',
    data,
    dataHeaders = {}
}: ApiRequest) => {
    try{
        return await axios.request({
            method,
            baseURL: `${path}`,
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`,
                'Dataheaders': `${JSON.stringify(dataHeaders)}`,
                lang
            },
            data
        });
    }catch(err:any){
        if(err.response.data.statusCode===401){
            window.location.reload()
        }
        return defaultRes
    }
};

export const apiFiles = async ({
    method = '',
    path = '',
    token = '',
    lang = '',
    data
}: ApiRequest) => {
    try{
        return await axios.post(path, data, {
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
                lang
            }
        });
    }catch(err:any){
        console.log(err)
        if(err.response.data.statusCode===401){
            window.location.reload()
        }
        return defaultRes
    }
};

export default api;
