import { useEffect, useState } from 'react';
import {
    TableInterface,
    FontItem,
    RequirementItem
} from './interface/table.interface';
import { ConfigReportFormValues, MaterialInterface } from '../../interface';
import { ApiConfigReport, ApiConfigReportUpdate } from '../../config/service';
import './table.scss';
import { CheckOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../store/hooks';
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import { useToast } from '../../config/hooks/useToast';
import { useCallApi } from '../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../config/hooks/useCallApi/constants';

interface CellItem {
    state: number;
    requirements: RequirementItem[] | null;
}

const TableData = ({
    headersx,
    headersy,
    property,
    propertyUnit
}: TableInterface) => {
    const { GetData } = useCallApi();
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();

    const [matrix, setMatrix] = useState<CellItem[][]>([]);
    const [fonts, setFonts] = useState<FontItem[]>([]);
    const [materials, setMaterials] = useState<MaterialInterface[]>([]);
    const { userData }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const externalFonts = fonts.filter(font => font.destination === 'Gestor');

    useEffect(() => {
        setFonts(headersy);
        setMaterials(headersx);
    }, [headersx, headersy]);

    useEffect(() => {
        const cellMatrix = headersy.map(font => {
            return headersx.map(item => {
                const requirements = font.dataRequirements.filter(
                    dataReq => dataReq.material === item.id
                );
                let state = 0;
                if (requirements.length > 0) {
                    if (propertyUnit) {
                        const configReportDetails =
                            propertyUnit?.configReports[0]?.configReportDetails;
                        const matchingDetail = configReportDetails?.find(
                            detail =>
                                requirements.some(
                                    req => req.id === detail.dataRequirement?.id
                                )
                        );
                        state = matchingDetail?.active ? 2 : 1;
                    } else {
                        const configReportDetails =
                            property?.configReports[0]?.configReportDetails;
                        const matchingDetail = configReportDetails?.find(
                            detail =>
                                requirements.some(
                                    req => req.id === detail.dataRequirement?.id
                                )
                        );
                        state = matchingDetail?.active ? 2 : 1;
                    }
                }
                const cell = {
                    state,
                    requirements: requirements ?? null
                };
                return cell;
            });
        });
        setMatrix(cellMatrix);
    }, []);

    const handleCellClick = (rowIndex: number, colIndex: number) => {
        const newCellMatrix = [...matrix];
        if (newCellMatrix[rowIndex][colIndex].state === 1) {
            newCellMatrix[rowIndex][colIndex].state = 2;
        } else if (newCellMatrix[rowIndex][colIndex].state === 2) {
            newCellMatrix[rowIndex][colIndex].state = 1;
        }
        setMatrix(newCellMatrix);
    };

    const SaveData = async () => {
        const details: any = [];

        matrix.forEach(subArray => {
            subArray.forEach(item => {
                if (item.state !== 0) {
                    const active = item.state === 2;
                    item.requirements?.forEach(requirement => {
                        const dataRequirement = requirement.id;
                        details.push({ active, dataRequirement });
                    });
                }
            });
        });
        if (property) {
            if (property.configReports.length > 0) {
                const values = {
                    configs: [
                        {
                            configReport: property.configReports[0].id,
                            details
                        }
                    ]
                };
                const response = await GetData(
                    ApiConfigReportUpdate,
                    HTTP_METHODS.POST,
                    values
                );
                const { status, message } = response;
                if (status) {
                    ConfigToast.text = message;
                    ConfigToast.backColor = Colors.Success;
                    setConfigToast(ConfigToast);
                } else {
                    ConfigToast.text = message;
                    ConfigToast.backColor = Colors.Error;
                    ConfigToast.title = 'Error';
                    setConfigToast(ConfigToast);
                }
            } else {
                ConfigReportFormValues.property = property.id;
                ConfigReportFormValues.user = userData.id;
                ConfigReportFormValues.details = details;
                const response = await GetData(
                    ApiConfigReport,
                    HTTP_METHODS.POST,
                    ConfigReportFormValues
                );
                const { status, message } = response;
                if (status) {
                    ConfigToast.text = message;
                    ConfigToast.backColor = Colors.Success;
                    setConfigToast(ConfigToast);
                } else {
                    ConfigToast.text = message;
                    ConfigToast.backColor = Colors.Error;
                    ConfigToast.title = 'Error';
                    setConfigToast(ConfigToast);
                }
            }
        } else if (propertyUnit) {
            if (propertyUnit.configReports.length > 0) {
                const values = {
                    configs: [
                        {
                            configReport: propertyUnit.configReports[0].id,
                            details
                        }
                    ]
                };
                const response = await GetData(
                    ApiConfigReportUpdate,
                    HTTP_METHODS.POST,
                    values
                );
                const { status, message } = response;
                if (status) {
                    ConfigToast.text = message;
                    ConfigToast.backColor = Colors.Success;
                    setConfigToast(ConfigToast);
                } else {
                    ConfigToast.text = message;
                    ConfigToast.backColor = Colors.Error;
                    ConfigToast.title = 'Error';
                    setConfigToast(ConfigToast);
                }
            } else {
                ConfigReportFormValues.propertyUnit = propertyUnit.id;
                ConfigReportFormValues.user = userData.id;
                ConfigReportFormValues.details = details;
                const response = await GetData(
                    ApiConfigReport,
                    HTTP_METHODS.POST,
                    ConfigReportFormValues
                );
                const { status, message } = response;
                if (status) {
                    ConfigToast.text = message;
                    ConfigToast.backColor = Colors.Success;
                    setConfigToast(ConfigToast);
                } else {
                    ConfigToast.text = message;
                    ConfigToast.backColor = Colors.Error;
                    ConfigToast.title = 'Error';
                    setConfigToast(ConfigToast);
                }
            }
        }
    };

    return (
        <div className='table-container'>
            <ToastElement ref={toastManagerRef} />
            <table className='table-container__table'>
                <thead className='table-container__table__thead'>
                    <tr className='table-container__table__thead__tr'>
                        <th className='table-container__table__thead__tr__th big'>
                            {' '}
                            &nbsp;{' '}
                        </th>
                        {materials.map(item => (
                            <th
                                key={item.id}
                                className='table-container__table__thead__tr__th'
                                style={{ backgroundColor: item.color }}
                            >
                                <span className='table-container__table__thead__tr__th__text subsubtitle'>
                                    {item.name}
                                </span>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {fonts.map((font, rowIndex) => (
                        <tr
                            key={font.id}
                            className={`table-container__table__tbody__tr ${
                                rowIndex === externalFonts.length - 1 &&
                                'bottom-border'
                            }`}
                        >
                            <td className='table-container__table__tbody__tr__td big subsubtitle'>
                                {font.name} {rowIndex}
                            </td>
                            {matrix[rowIndex].map((cell, colIndex) => (
                                <td
                                    key={headersx[colIndex].id}
                                    className={`table-container__table__tbody__tr__td ${
                                        cell.state === 2
                                            ? 'clicked'
                                            : cell.state === 1
                                            ? 'editable'
                                            : ''
                                    }`}
                                    onClick={() =>
                                        handleCellClick(rowIndex, colIndex)
                                    }
                                >
                                    {cell.state === 2 && (
                                        <CheckOutlined
                                            style={{ color: '#fff' }}
                                            rev={''}
                                        />
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            <button
                className='button button-body--save-configReport'
                onClick={SaveData}
            >
                Guardar cambios
            </button>
        </div>
    );
};

export default TableData;
