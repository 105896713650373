import { Bar } from 'react-chartjs-2';
import { StackedProps } from './stackedBarCharInterface';
import { Config, AddTicks } from './config';
import { useEffect, useState } from 'react';
import {
    RangeDateFilter,
    MarksInterface
} from '../../range-date-filter/range-date-filter';

const StackedBarChartComponent = ({
    chartData,
    options,
    dateFilter,
    dateElements,
    refProp
}: StackedProps) => {
    const [labelsDate, setLabelsDate] = useState<MarksInterface[]>([]);
    const [defaultOptions, setDefaultOptions] = useState<object>({});
    const [ready, setReady] = useState<boolean>(false);

    useEffect(() => {
        if (dateElements && dateElements.length > 0) {
            const lblsDate = dateElements.map((item, index) => {
                return { value: index, label: item };
            });
            setLabelsDate(lblsDate);
        }
    }, [dateElements]);

    useEffect(() => {
        setDefaultOptions(options);
        setReady(true);
    }, [options]);

    return (
        <>
            {ready && (
                <div className='chart-container'>
                    <Bar
                        ref={refProp}
                        data={chartData}
                        options={defaultOptions}
                    />
                    {dateFilter && labelsDate.length > 0 && (
                        <RangeDateFilter
                            marks={labelsDate}
                            min={labelsDate[0].value}
                            max={labelsDate[labelsDate.length - 1].value}
                            results={() => {}}
                        />
                    )}
                </div>
            )}
        </>
    );
};

const StackedBarChart = { StackedBarChartComponent, Config, AddTicks };

export { StackedBarChart };
