import { DataCenterChartsInterface, MaterialInterface } from '../../interface';
import { Globals } from './Constants';

const labelsLength = Globals.ChartCountMilestones;
const flexFilters = ['kindId', 'materialId'];

interface Props {
    data: DataCenterChartsInterface[];
    labels: string[];
    chart: 'bar' | 'line' | 'apiled';
    milestones: string;
    dataSetList: MaterialInterface[];
    dataSetList2?: boolean;
    filter?: any;
    apiledLine?: boolean;
    apiledLineData?: any;
    apiledLineOnFilter?: string[];
    colorsDB?: any;
}

export const FormatData = ({
    data,
    labels,
    chart,
    dataSetList,
    dataSetList2,
    filter,
    milestones,
    apiledLine,
    apiledLineData,
    apiledLineOnFilter,
    colorsDB
}: Props) => {
    if (!data || data.length === 0) {
        // Devolver un objeto vacío o lo que consideres apropiado para tu caso
        return {
            labels: [],
            datasets: []
        };
    }

    if (chart === 'apiled') {
        return ApiledFormat({
            data,
            labels,
            chart,
            dataSetList,
            dataSetList2,
            filter,
            milestones,
            apiledLine,
            apiledLineData,
            apiledLineOnFilter,
            colorsDB
        });
    } else {
        return ApiledFormat({
            data,
            labels,
            chart,
            dataSetList,
            dataSetList2,
            filter,
            milestones,
            colorsDB
        });
    }
};

const ApiledFormat = ({
    data,
    labels,
    chart,
    dataSetList,
    dataSetList2,
    filter,
    milestones,
    apiledLine,
    apiledLineData,
    apiledLineOnFilter,
    colorsDB
}: Props) => {
    const {
        filters: { keys, valuesProperty }
    } = filter;
    /* ASIGNA LOS MATERIALES A LA VARIABLE UNIQUEDS */
    let uniqueDS = dataSetList;
    const existDs = flexFilters.filter(element => keys.includes(element));
    if (existDs.length > 0) {
        const indexValueFilter = keys.indexOf(existDs[0]);
        const idSelectFilter = valuesProperty[indexValueFilter];
        const filterDs = uniqueDS.filter(item => item.id === idSelectFilter);
        uniqueDS = filterDs;
    }
    let finalLabels: string[] = [];
    finalLabels = labels;

    let finalData;
    if (keys.length > 1) {
        const calculation: string[] = [];
        finalLabels.forEach((item, index) => {
            const selected = data
                .filter(obj => obj.date === item)
                .map(filteredObj => filteredObj.total);
            calculation.push(selected[0]);
        });

        let preConfig = {};

        if (chart === 'line') {
            preConfig = {
                pointStyle: 'circle',
                pointRadius: 2,
                pointHoverRadius: 2
            };
        }

        const dynamicData: any = [];
        uniqueDS.forEach((item, index) => {
            let Obj = {
                label: item.name,
                data: calculation,
                backgroundColor: colorsDB[item.name] || 'rgb(162 162 163)',
                borderWidth: 0
            };
            if (chart === 'line') {
                Obj = {
                    ...Obj,
                    ...preConfig
                };
            }
            dynamicData.push(Obj);
        });
        finalData = {
            labels: finalLabels,
            datasets: dynamicData
        };
    } else {
        const dynamicData: any = [];
        let preConfig = {};

        if (chart === 'line') {
            preConfig = {
                pointStyle: 'circle',
                pointRadius: 2,
                pointHoverRadius: 2
            };
        }
        /* RECORRE LOS TIPOS DE MATERIALES */
        // eslint-disable-next-line array-callback-return
        uniqueDS.forEach((e, i) => {
            // eslint-disable-next-line array-callback-return
            const arrDS: string[] = [];
            /* RECORRE EL LISTADO DE FECHAS */
            // eslint-disable-next-line array-callback-return
            finalLabels.forEach(obj => {
                /* OBTIENE Y FILTRA DATOS QUE COINCIDAN CON LAS FECHAS Y EL TIPO DE MATERIAL */
                const fres = data
                    .filter(
                        item => item.date === obj && item.materialId === e.id
                    )
                    .map(filteredObj => filteredObj.total);

                /* ASIGNA LOS DATOS AGRUPADOS POR FECHA Y TIPO DE MATERIAL, LO SUMA Y GUARDA EN UN ARRAY PARA SER MOSTRADO EN EL GRÁFICO */
                if (fres.length > 0) {
                    let sumArray = 0;
                    fres.forEach(number => {
                        sumArray += parseFloat(number);
                    });
                    arrDS.push(sumArray.toString());
                } else {
                    arrDS.push('0');
                }
                if (dataSetList2) {
                    const fresmaterials = data
                        .filter(
                            item => item.date === obj && item.kindId === e.id
                        )
                        .map(filteredObj => filteredObj.total);
                    if (fresmaterials.length > 0) {
                        let sumArray = 0;
                        fresmaterials.forEach(number => {
                            sumArray += parseFloat(number);
                        });
                        arrDS.push(sumArray.toString());
                    } else {
                        arrDS.push('0');
                    }
                }
            });
            /* CREAR UN OBJETO CON LOS DATOS DEL ARRAY PARA MOSTRAR LOS NÚMEROS, ADEMÁS ASIGNA PARÁMETROS PARA EL GRÁFICO */
            let Obj = {
                id: e.id,
                kind: e.kind?.id ?? '',
                label: e.name,
                data: arrDS,
                backgroundColor: colorsDB[e.name] || 'rgb(162 162 163)',
                borderColor: colorsDB[e.name] || 'rgb(162 162 163)',
                borderWidth: 0
            };
            if (chart === 'line') {
                Obj = {
                    ...Obj,
                    ...preConfig
                };
            }
            dynamicData.push(Obj);
        });
        /* AGREGA A LÍNEA DE LA DATA PARA EL ÍNDICE BZERO */
        if (apiledLine && chart === 'apiled') {
            if (apiledLineOnFilter) {
                const existApiledLineOnFilter = keys.filter(element =>
                    apiledLineOnFilter.includes(element)
                );
                if (existApiledLineOnFilter.length > 0) {
                    const addObjApiledLine = {
                        ...apiledLineData,
                        backgroundColor: 'rgb(3 218 197)',
                        borderColor: 'rgb(3 218 197)',
                        borderWidth: 1,
                        type: 'line',
                        yAxisID: 'y1',
                        id: 'bzeroindex',
                        kind: '0'
                    };
                    dynamicData.push(addObjApiledLine);
                }
            }
        }
        finalData = {
            labels: finalLabels,
            datasets: dynamicData
        };
    }
    return finalData;
};

export const FormatLabels = (
    data: object[],
    key: string,
    redefine: boolean = false
) => {
    const arrayLabels = data.map(function (item) {
        return item[key];
    });
    if (!redefine) {
        return arrayLabels.slice(
            arrayLabels.length - labelsLength,
            arrayLabels.length
        );
    } else {
        return arrayLabels;
    }
};

const obtainDates = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    let monthsToSubtract = 0;
    if (currentDay <= 13) {
        monthsToSubtract = 5;
    } else {
        monthsToSubtract = 4;
    }

    const startDate = new Date(currentYear, currentMonth - monthsToSubtract, 1);
    const dateArray: any = [];

    for (let i = 0; i < 3; i++) {
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, '0');
        const formattedDate = String(year) + month;
        dateArray.push(formattedDate);
        startDate.setMonth(startDate.getMonth() + 1);
    }

    return dateArray;
};

export const formatBZeroIndexData = data => {
    const datesFilter = obtainDates();
    const dataFilteredDate = data.filter(
        item =>
            item.date === datesFilter[0] ||
            item.date === datesFilter[1] ||
            item.date === datesFilter[2]
    );
    const groupedData = dataFilteredDate.reduce((result, item) => {
        const { propertyId, total, date, propertyUnit } = item;
        if (propertyId !== null) {
            const existingItem = result.find(
                obj => obj.propertyId === propertyId && obj.date === date
            );
            if (!existingItem) {
                result.push({
                    date,
                    propertyId,
                    total: 0,
                    totalTrash: 0,
                    bzeroindex: 0
                });
            }
            const currentItem = result.find(
                obj => obj.propertyId === propertyId && obj.date === date
            );
            currentItem.total =
                parseFloat(currentItem.total) + parseFloat(total);
            if (item.kindId === 'a4688f23-9e63-af00-4ef9-b71a757710c0') {
                currentItem.totalTrash =
                    parseFloat(currentItem.totalTrash) + parseFloat(total);
            }
            currentItem.bzeroindex = (
                (currentItem.totalTrash / currentItem.total) *
                100
            ).toFixed(1);
        } else if (propertyUnit !== null) {
            const existingItem = result.find(
                obj => obj.propertyId === propertyUnit && obj.date === date
            );
            if (!existingItem) {
                result.push({
                    date,
                    propertyId: propertyUnit,
                    total: 0,
                    totalTrash: 0,
                    bzeroindex: 0
                });
            }
            const currentItem = result.find(
                obj => obj.propertyId === propertyUnit && obj.date === date
            );
            currentItem.total =
                parseFloat(currentItem.total) + parseFloat(total);
            if (item.kindId === 'a4688f23-9e63-af00-4ef9-b71a757710c0') {
                currentItem.totalTrash =
                    parseFloat(currentItem.totalTrash) + parseFloat(total);
            }
            currentItem.bzeroindex = (
                (currentItem.totalTrash / currentItem.total) *
                100
            ).toFixed(1);
        }
        return result;
    }, []);

    const dataReal = groupedData.filter(item => item.totalTrash !== 0);

    const aggregatedData = dataReal.reduce((result, item) => {
        const { propertyId, propertyUnit, total, totalTrash } = item;
        const existingItemIndex = result.findIndex(
            obj => obj.propertyId === propertyId
        );
        if (existingItemIndex === -1) {
            // Si no existe un elemento con el mismo propertyId, lo agregamos al resultado
            result.push({
                propertyId,
                propertyUnit,
                total,
                totalTrash
            });
        } else {
            // Si ya existe un elemento con el mismo propertyId, sumamos los valores
            result[existingItemIndex].total =
                parseFloat(result[existingItemIndex].total) + parseFloat(total);
            result[existingItemIndex].totalTrash =
                parseFloat(result[existingItemIndex].totalTrash) +
                parseFloat(totalTrash);
        }
        return result;
    }, []);

    // Calculamos bzeroindex para cada elemento del nuevo arreglo
    aggregatedData.forEach(item => {
        item.bzeroindex = (item.totalTrash / item.total) * 100;
    });

    aggregatedData.sort((a, b) => a.bzeroindex - b.bzeroindex);
    return aggregatedData;
};
